var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isRegistrationRunning)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mlr5",attrs:{"ripple":"","icon":""}},Object.assign({}, tooltip)),[_c('font-awesome-icon',{attrs:{"icon":"cog","size":"lg","pull":"right","spin":""}})],1)]}}],null,false,2873626435)},[_c('span',[_vm._v(_vm._s(_vm.$t("registration")))])])]:_vm._e(),(_vm.arterialId)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mlr5",style:({ color: _vm.isSeriesActive }),attrs:{"id":"openArterial","ripple":"","icon":"","disabled":_vm.isEditorActive},on:{"click":function($event){return _vm.selectSerie(_vm.arterialId)}}},Object.assign({}, tooltip)),[_vm._v(" A "),_c('font-awesome-icon',{class:_vm.isSeriesActive(_vm.arterialId),attrs:{"icon":"image","size":"lg","pull":"right"}})],1)]}}],null,false,1452245513)},[_c('span',[_vm._v(_vm._s(_vm.$t("select_arterial")))])])]:_vm._e(),(_vm.venousId)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mlr5",style:({ color: _vm.isSeriesActive }),attrs:{"id":"openVenous","ripple":"","icon":"","disabled":_vm.isEditorActive},on:{"click":function($event){return _vm.selectSerie(_vm.venousId)}}},Object.assign({}, tooltip)),[_vm._v(" V "),_c('font-awesome-icon',{class:_vm.isSeriesActive(_vm.venousId),attrs:{"icon":"image","size":"lg","pull":"right"}})],1)]}}],null,false,3180599006)},[_c('span',[_vm._v(_vm._s(_vm.$t("select_venous")))])])]:_vm._e(),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mlr5",style:({ color: _vm.is3DActive }),attrs:{"id":"open3d","ripple":"","icon":"","disabled":_vm.isSegmentationsRunning || _vm.isEditorActive},on:{"click":_vm.open3D}},Object.assign({}, tooltip)),[_vm._v(" 3D "),_c('font-awesome-icon',{class:_vm.is3DActive,attrs:{"icon":"cubes","size":"lg","pull":"right"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("select_3D")))])]),_c('v-divider',{staticStyle:{"display":"inline","vertical-align":"middle"},attrs:{"vertical":"","color":"primary"}})],_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isToolActive('Wwwc'),attrs:{"id":"toggleContrast","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""},on:{"click":function($event){return _vm.toggleMouseHandler('Wwwc')}}},on),[_c('font-awesome-icon',{attrs:{"icon":"adjust","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("change_image_contrast")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isToolActive('Pan'),attrs:{"id":"togglePan","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""},on:{"click":function($event){return _vm.toggleMouseHandler('Pan')}}},on),[_c('font-awesome-icon',{attrs:{"icon":"expand-arrows-alt","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("pan")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isToolActive('Zoom'),attrs:{"id":"toggleZoom","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""},on:{"click":function($event){return _vm.toggleMouseHandler('Zoom')}}},on),[_c('font-awesome-icon',{attrs:{"icon":"search","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("zoom")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isToolActive('Rotate'),attrs:{"id":"toggleRotation","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""},on:{"click":function($event){return _vm.toggleMouseHandler('Rotate')}}},on),[_c('font-awesome-icon',{attrs:{"icon":"sync-alt","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("rotate")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isToolActive('DragProbe'),attrs:{"id":"toggleProbe","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""},on:{"click":function($event){return _vm.toggleMouseHandler('DragProbe')}}},on),[_c('font-awesome-icon',{attrs:{"icon":"eye-dropper","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("pixel_info")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isToolActive('Magnify'),attrs:{"id":"toggleMagnify","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""}},on),[_c('font-awesome-icon',{attrs:{"icon":"search-plus","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("magnify")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"toggleReset","disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""},on:{"click":_vm.resetSettings}},on),[_c('font-awesome-icon',{attrs:{"icon":"undo","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("reset_settings")))])]),_c('v-divider',{staticStyle:{"display":"inline","vertical-align":"middle"},attrs:{"vertical":"","color":"primary"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({class:_vm.isMeasurementsToolActive,attrs:{"disabled":_vm.activeViewport == 'r3D',"ripple":"","icon":""}},Object.assign({}, tooltip, menu)),[_c('font-awesome-icon',{attrs:{"icon":"pencil-ruler","size":"lg"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("measurement_tools")))])])]}}])},[_c('v-list',_vm._l((_vm.measurementTools),function(item,index){return _c('v-list-item',{key:index,attrs:{"id":item.name},on:{"click":function($event){return _vm.changeActiveTool(item)}}},[_c('v-list-item-title',[_c('v-icon',{class:_vm.isToolActive(item.value)},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)],1)}),1)],1),_c('v-divider',{staticStyle:{"display":"inline","vertical-align":"middle"},attrs:{"vertical":"","color":"primary"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"openNotes","ripple":"","icon":""},on:{"click":_vm.toggleNotes}},on),[_c('font-awesome-icon',{attrs:{"icon":"notes-medical","size":"lg"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("case_notes")))])]),_c('v-divider',{staticStyle:{"display":"inline","vertical-align":"middle"},attrs:{"vertical":"","color":"primary"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }