<template>
  <div class="mb5">
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          id="addManualSegmentation"
          color="primary"
          class="text-btn"
          block
          v-on="{ ...tooltip }"
          @click="modal = !modal"
          :disabled="
            isSegmentationsUploading || isSegmentationsRunning || isEditorActive
          "
        >
          <font-awesome-icon icon="plus" pull="left" size="2x" />
          {{ $t("manual") }}
        </v-btn>
      </template>
      <span>{{ $t("manual_long") }}</span>
    </v-tooltip>
    <v-dialog v-model="modal" persistent width="60%">
      <v-card>
        <v-card-title class="headline justify-center" primary-title>
          {{ $t("add_new_segmentation") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid class="h100">
            <v-row>
              <v-col cols="6">
                <v-form ref="tag_form" v-model="validTag" @submit.prevent>
                  <v-text-field
                    required
                    :rules="tagRules"
                    prepend-icon="mdi-tag"
                    v-model="tag"
                    name="tag"
                    label="segmentation name"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="6">
                <v-color-picker
                  dot-size="25"
                  hide-canvas
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="color"
                ></v-color-picker>
              </v-col>
            </v-row>
            <p class="uppercase">
              {{ $t("phase_manual_segmentation") }} {{ activePhase }}
              {{ $t("phase") }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="discardSegmentation"
                color="primary"
                class="mr5"
                large
                v-on="{ ...tooltip }"
                @click="exit"
              >
                <font-awesome-icon size="2x" icon="trash"></font-awesome-icon>
              </v-btn>
            </template>
            <span>{{ $t("discard_segmentation") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="saveSegmentation"
                color="primary"
                large
                v-on="{ ...tooltip }"
                @click="save"
                :disabled="!validTag"
              >
                <font-awesome-icon size="2x" icon="save"></font-awesome-icon>
              </v-btn>
            </template>
            <span>{{ $t("save_segmentation") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { has } from "lodash";

import { addNewMask, getMask } from "@/common/api.jobs";

import {
  setActiveLabelmap,
  enableBrushTool,
  forceRender
} from "@/common/api.segmentation";

export default {
  name: "NewSegmentation",
  data() {
    return {
      modal: false,
      tag: "",
      hex: "#55ff00",
      validTag: false,
      tagRules: [
        v => !!v || "Segmentation Name is required",
        v =>
          (v && v.length <= 12) ||
          "Segmentation Name must be less than 12 characters",
        v =>
          (v && !has(this.$store.state.segmentations, v.toLowerCase())) ||
          "Segmentation Name already exists"
      ]
    };
  },
  computed: {
    ...mapGetters([
      "isSegmentationsUploading",
      "isSegmentationsRunning",
      "isEditorActive"
    ]),
    ...mapState({
      activePhase(state) {
        return state.activePhase;
      }
    }),
    color: {
      get() {
        return this["hex"];
      },
      set(v) {
        this["hex"] = v;
      }
    }
  },
  methods: {
    exit() {
      this.modal = false;
      this.tag = "";
      this.hex = "#55ff00";
      this.$refs.tag_form.resetValidation();
    },
    save() {
      let self = this;
      // check if name already exists
      this.modal = false;
      this.$store.dispatch("segmentations/setIsLoading", true);
      addNewMask(this.tag.toLowerCase(), this.hex, function(resourceId) {
        let phase = self.$store.state.activePhase;
        let labelId =
          self.$store.state.segmentations.tags.venous.length +
          self.$store.state.segmentations.tags.arterial.length;
        self.$store.dispatch("segmentations/addMask", [
          phase,
          self.tag.toLowerCase()
        ]);
        self.$store.dispatch("segmentations/setMaskResourceId", [
          self.tag.toLowerCase(),
          resourceId
        ]);
        self.$store.dispatch("segmentations/setMaskLabelId", [
          self.tag.toLowerCase(),
          labelId
        ]);
        self.$store.dispatch("segmentations/setMaskColor", [
          self.tag.toLowerCase(),
          self.hex
        ]);
        self.$store.dispatch("segmentations/setMaskColorUI", [
          self.tag.toLowerCase(),
          self.hex
        ]);
        getMask(null, resourceId, function() {
          // OPEN THE EDITOR
          setActiveLabelmap(labelId, "axial");
          let currentLeftMouseHandler = self.$store.state.leftMouseHandler;
          self.$store.dispatch(
            "setLastLeftMouseHandler",
            currentLeftMouseHandler
          );
          self.$store.dispatch("setLeftMouseHandler", "Brush");
          let dimension =
            self.$store.state.segmentations[self.tag.toLowerCase()].dimension;
          let thresholds =
            self.$store.state.segmentations[self.tag.toLowerCase()].thresholds;
          enableBrushTool(dimension, thresholds);
          self.$store.dispatch("segmentations/setActiveMask", [
            self.tag.toLowerCase(),
            labelId
          ]);
          forceRender();
          self.$store.dispatch("segmentations/setIsLoading", false);
          self.tag = "";
          self.hex = "#FF0000";
          self.$refs.tag_form.resetValidation();
        });
      });
    }
  }
};
</script>

<style>
.br1 {
  border-right: 1px ridge;
}
.text-center {
  text-align: center;
}
.right-button {
  float: right;
  margin-top: 5px;
  margin-bottom: 10px;
}
.mb5 {
  margin-bottom: 5px;
}
.mr5 {
  margin-right: 5px;
}
.uppercase {
  text-transform: uppercase;
}
.text-btn {
  font-size: 0.8vw !important;
}
</style>
