// import cornerstoneTools from "cornerstone-tools";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import { each, throttle } from "lodash";
import { verify_and_refresh } from "@/common/api.users";
import request from "superagent";

// internal libraries
import {
  cornerstone,
  nrrdManager,
  resetNrrdLoader,
  readFiles,
  resetImageParsing,
  cacheAndSaveSerie,
  clearCornerstoneElements,
  initializeCSTools,
  addDefaultTools,
  updateStackToolState,
  initializeImageLoader,
  registerResliceLoader,
  registerNRRDImageLoader,
  dicomManager,
  populateDicomManager,
  populateNrrdManager,
  updateImage,
  loadImage,
  clearImageCache,
  importNRRDImage,
  resetDicomManager,
  saveAnnotations,
  loadAnnotations
} from "@/larvitar/index";

// const TAG_DICT = require("@/imaging/dataDictionary.json");

import store from "@/store/index";

// ====================
// Reset Image API ====
// ====================
function resetImageAPI() {
  cornerstone.imageCache.purgeCache();
  cornerstoneWADOImageLoader.webWorkerManager.terminate();
  resetNrrdLoader("axial");
  resetDicomManager();
  initializeImageLoader();
  registerResliceLoader();
  registerNRRDImageLoader();
  resetImageParsing();
}

// =========================================
// Import a DICOM Serie from a folder ======
// and return the seriesStack using ========
// dicom parser cornerstone library ========
// =========================================
function importDicomSerie(files, callback) {
  if (files.length === 0) {
    callback(null);
  } else {
    store.dispatch("setMsgLog", "parsing DICOM files...");
    console.time("...readFiles");
    readFiles(files, function(seriesStack, err) {
      //console.log(seriesStack);
      console.timeEnd("...readFiles");
      if (err) {
        store.dispatch("setMsgLog", "");
        store.dispatch("setErrorLog", err);
        callback(null, null, err);
      } else {
        let seriesId = Object.keys(seriesStack)[0];
        callback(seriesId, seriesStack);
      }
    });
  }
}

// =========================================
// Import a DICOM Study from a folder ======
// and return the seriesStack using ========
// dicom parser cornerstone library ========
// =========================================
function importDicomStudy(files, callback) {
  if (files.length === 0) {
    callback(null);
  } else {
    store.dispatch("setMsgLog", "parsing DICOM files...");
    console.time("...readFiles");
    readFiles(files, function(seriesStack, err) {
      //console.log(seriesStack);
      console.timeEnd("...readFiles");
      if (err) {
        store.dispatch("setMsgLog", "");
        store.dispatch("setErrorLog", err);
        callback(null, null, err);
      } else {
        let seriesIds = Object.keys(seriesStack);
        callback(seriesIds, seriesStack);
      }
    });
  }
}

// =============================================
// Save a dicom series into db as nrrd file ====
// =============================================
function saveImageInDB(caseId, phase, seriesId, seriesStack, callback) {
  if (store.state.nrrdSeries) {
    buildNRRDHeader(seriesId, phase, seriesStack, function(nrrdData) {
      writeImage(caseId, nrrdData, function(resp) {
        callback(resp);
      });
    });
  } else {
    convertDICOMtoNRRD(seriesId, phase, seriesStack, function(nrrdData) {
      writeImage(caseId, nrrdData, function(resp) {
        callback(resp);
      });
    });
  }
}

// ========================================
// Build a custom header for nrrd image ===
// ========================================
function buildNRRDHeader(seriesId, phase, seriesStack, callback) {
  let nrrdData = {};
  nrrdData.data = seriesStack[seriesId].volume.data;
  nrrdData.header = {};
  each(seriesStack[seriesId].instances, function(v, k) {
    nrrdData.header[k] = v;
  });
  nrrdData.header["volume"] = {};
  nrrdData.header.volume.cols = seriesStack[seriesId].header.volume.cols;
  nrrdData.header.volume.imageIds = seriesStack[seriesId].imageIds;
  let imageOrientation = [];
  imageOrientation.push(
    seriesStack[seriesId].volume.header["space directions"][0][0] /
      seriesStack[seriesId].header.volume.pixelSpacing[0]
  );
  imageOrientation.push(
    seriesStack[seriesId].volume.header["space directions"][0][1] /
      seriesStack[seriesId].header.volume.pixelSpacing[0]
  );
  imageOrientation.push(
    seriesStack[seriesId].volume.header["space directions"][0][2] /
      seriesStack[seriesId].header.volume.pixelSpacing[0]
  );
  imageOrientation.push(
    seriesStack[seriesId].volume.header["space directions"][1][0] /
      seriesStack[seriesId].header.volume.pixelSpacing[1]
  );
  imageOrientation.push(
    seriesStack[seriesId].volume.header["space directions"][1][1] /
      seriesStack[seriesId].header.volume.pixelSpacing[1]
  );
  imageOrientation.push(
    seriesStack[seriesId].volume.header["space directions"][1][2] /
      seriesStack[seriesId].header.volume.pixelSpacing[1]
  );
  nrrdData.header.volume.imageOrientation = imageOrientation;
  nrrdData.header.volume.imagePosition =
    seriesStack[seriesId].header.volume.imagePosition;
  nrrdData.header.volume.intercept =
    seriesStack[seriesId].instances[seriesStack[seriesId].imageIds[0]].metadata[
      "x00281052"
    ][0];
  nrrdData.header.volume.slope =
    seriesStack[seriesId].instances[seriesStack[seriesId].imageIds[0]].metadata[
      "x00281053"
    ][0];
  nrrdData.header.volume.numberOfSlices =
    seriesStack[seriesId].header.volume.numberOfSlices;
  nrrdData.header.volume.phase = phase;
  nrrdData.header.volume.pixelSpacing =
    seriesStack[seriesId].header.volume.pixelSpacing;
  nrrdData.header.volume.repr = seriesStack[seriesId].header.volume.repr;
  nrrdData.header.volume.rows = seriesStack[seriesId].header.volume.rows;
  nrrdData.header.volume.seriesId = seriesId;
  nrrdData.header.volume.sliceThickness =
    seriesStack[seriesId].header.volume.sliceThickness;
  callback(nrrdData);
}

// =========================================
// Convert a DICOM serie to a NRRD File ====
// =========================================
function convertDICOMtoNRRD(seriesId, phase, seriesStack, callback) {
  console.time("...cacheAndSaveSerie");
  cacheAndSaveSerie(seriesStack[seriesId]).then(function(nrrdData) {
    console.timeEnd("...cacheAndSaveSerie");
    nrrdData.header.volume.phase = phase;
    callback(nrrdData);
  });
}

// ===============================================================
// Write nrrd image and save case and image instances in the db ==
// ===============================================================
function writeImage(case_id, nrrdData, callback) {
  let slice = nrrdData.header[nrrdData.header.volume.imageIds[0]];
  let study_id = slice.studyUID ? slice.studyUID : "";
  let study_description = slice.studyDescription ? slice.studyDescription : "";
  let series_description = slice.seriesDescription
    ? slice.seriesDescription
    : "";
  let acquisition_date = slice.seriesDate ? slice.seriesDate : "1900-01-01";
  let phase = nrrdData.header.volume.phase;
  let intercept = nrrdData.header.volume.intercept
    ? parseFloat(nrrdData.header.volume.intercept)
    : 0.0;
  let slope = nrrdData.header.volume.slope
    ? parseFloat(nrrdData.header.volume.slope)
    : 1.0;
  nrrdData.header.volume.intercept = intercept;
  nrrdData.header.volume.slope = slope;
  store.dispatch("setMsgLog", "saving image in Database...");
  console.time("...writeNrrd");
  verify_and_refresh(function() {
    request
      .post("/api/images/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .attach(
        "data",
        new File([nrrdData.data.buffer], { type: "application/octet-stream" })
      )
      .field("header", JSON.stringify(nrrdData.header.volume))
      .field("series_id", nrrdData.header.volume.seriesId)
      .field("study_id", study_id)
      .field("study_description", study_description)
      .field("series_description", series_description)
      .field("acquisition_date", acquisition_date)
      .field("intercept", intercept)
      .field("slope", slope)
      .field("phase", phase)
      .field("registered", false)
      .field("case_id", case_id)
      .on("progress", function(event) {
        if (process.env.NODE_ENV == "development") {
          console.log(event.percent);
        }
        store.dispatch(
          "setMsgLog",
          "uploading: " + event.percent.toFixed(0) + "%"
        );
        if (event.percent.toFixed(0) == 100) {
          store.dispatch("setMsgLog", "saving image in Database...");
        }
      })
      .then(function(resp) {
        console.timeEnd("...writeNrrd");
        store.dispatch("setMsgLog", "");
        callback(resp);
      })
      .catch(error => {
        store.dispatch("setMsgLog", "Error while saving image!");
        callback(error.response);
      });
  });
}

// ==========================================
// Register arterial image on venous image ==
// ==========================================
function registerImages(case_id, fixed_id, moving_id, callback) {
  // fixed id has to be the image with smaller volume!
  let data = {
    case_id: case_id,
    fixed_id: fixed_id,
    moving_id: moving_id
  };

  console.time("...register images");
  verify_and_refresh(function() {
    request
      .post("/api/register_images/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function(resp) {
        console.timeEnd("...register images");
        callback(resp);
      })
      .catch(error => {
        console.timeEnd("...register images");
        callback(error.response);
      });
  });
}

// =========================================
// Change current displayed image slice ====
// =========================================
function changeImageSlice(seriesId, orientation, imageIndex) {
  let manager = store.state.manager;
  let series = null;
  if (manager == "dicomManager") {
    series = dicomManager[seriesId]
      ? dicomManager[seriesId][orientation]
      : store.state.series[seriesId];
  } else {
    series = nrrdManager[seriesId][orientation];
  }
  let element = document.getElementById(orientation);
  updateImage(series, element, imageIndex);
  updateStackToolState(element, imageIndex - 1);
}

// =========================================
// Resize all active cornestone elements ===
// =========================================
function resizeCanvas(elementId) {
  try {
    cornerstone.resize(document.getElementById(elementId));
  } catch (error) {
    console.warn(error);
  }
}

// ======================================
// Disable image from active element ====
// ======================================
function disableImage(elementId) {
  let element = document.getElementById(elementId);
  if (element) {
    cornerstone.disable(element);
  }
}

// ==================================
// Load and render a dicom Serie ====
// ==================================
function loadAndRenderSerie(serie, orientation, callback) {
  let seriesId = store.state.seriesId;
  // check dicom manager for the orientation
  // populate if needed
  // load and render image
  let defaultProps = {
    ww: 600.0,
    wc: 40.0,
    defaultWW: 600.0,
    defaultWC: 40.0
  };
  if (dicomManager[seriesId] && dicomManager[seriesId][orientation]) {
    store.dispatch("setLoadingOrientation", orientation);
    store.dispatch("quadview/setLoadingStatus", [orientation, false]);
    loadImage(dicomManager[seriesId][orientation], orientation, defaultProps);
    store.dispatch("setLoadingOrientation", null);
    if (callback) {
      callback();
    }
  } else {
    populateDicomManager(seriesId, serie, orientation, function() {
      store.dispatch("setLoadingOrientation", orientation);
      store.dispatch("quadview/setLoadingStatus", [orientation, false]);
      loadImage(dicomManager[seriesId][orientation], orientation, defaultProps);
      store.dispatch("setLoadingOrientation", null);
      if (callback) {
        callback();
      }
    });
  }
}

// ======================================================
// Import nrrd image from server and populate manager ===
// ======================================================
function importImage(imageId, log, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/image/" + imageId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .on("progress", function(event) {
        if (process.env.NODE_ENV == "development") {
          console.log(event.percent);
        }
        if (log) {
          store.dispatch("setMsgLog", log + event.percent.toFixed(0) + "%");
        }
      })
      .then(res => {
        let image = JSON.parse(res.text);
        let xmlreq = new XMLHttpRequest();
        xmlreq.open("GET", image.resource, true);
        xmlreq.responseType = "arraybuffer";
        xmlreq.onload = function() {
          if (log) {
            store.dispatch("setMsgLog", "Loading image...");
          }
          const volume = importNRRDImage(xmlreq.response);
          let series = loadNrrdImage(volume, "axial", image.series_id, image);
          store.dispatch("addSeries", series);
          if (callback) {
            callback(image.series_id, image.phase, image.id);
          }
        };
        xmlreq.send();
      });
  });
}

// ===================================
// Get annotation data from server ===
// ===================================
export function importAnnotationData(imageId, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/image/" + imageId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        let image = JSON.parse(res.text);
        if (image.annotations) {
          loadAnnotations(image.annotations);
          if (callback) {
            callback();
          }
        }
      });
  });
}

// ==============================
// Save annotation in DB
// ==============================
export function saveAnnotationData() {
  verify_and_refresh(() => {
    let activePhase = store.state.activePhase;
    let currentImageId = store.state.imageIds[activePhase];
    let annotationData = saveAnnotations(false);
    let data = {
      image_id: currentImageId,
      annotations: annotationData
    };

    request
      .put("/api/update_annotation/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(res => {
        if (res.status == 200) {
          // console.log("SAVED");
        } else {
          console.error("Could not save annotations");
        }
      });
  });
}

// ======================================
// Start listening to cs tools events ===
// ======================================
export function startListeningCSTools(elementId) {
  let element = document.getElementById(elementId);
  // on new annotation
  element.addEventListener(
    "cornerstonetoolsmeasurementcompleted",
    saveAnnotationData
  );
  // on modified annotation
  let throttledSave = throttle(saveAnnotationData, 500); // save data max once every 500 ms when dragging
  element.addEventListener(
    "cornerstonetoolsmeasurementmodified",
    throttledSave
  );
  // on deleted annotation
  element.addEventListener(
    "cornerstonetoolsmeasurementremoved",
    saveAnnotationData
  );
}

// =============================================
// Load a nrrd image volume into the manager ===
// =============================================
function loadNrrdImage(volume, orientation, seriesId, custom_header) {
  let header = {};
  header["volume"] = {};
  // need to extract header from nrrd file format
  // sizes, spaceDirections and spaceOrigin

  let spacing_x = Math.sqrt(
    volume.header["space directions"][0][0] *
      volume.header["space directions"][0][0] +
      volume.header["space directions"][0][1] *
        volume.header["space directions"][0][1] +
      volume.header["space directions"][0][2] *
        volume.header["space directions"][0][2]
  );
  let spacing_y = Math.sqrt(
    volume.header["space directions"][1][0] *
      volume.header["space directions"][1][0] +
      volume.header["space directions"][1][1] *
        volume.header["space directions"][1][1] +
      volume.header["space directions"][1][2] *
        volume.header["space directions"][1][2]
  );
  let spacing_z = Math.sqrt(
    volume.header["space directions"][2][0] *
      volume.header["space directions"][2][0] +
      volume.header["space directions"][2][1] *
        volume.header["space directions"][2][1] +
      volume.header["space directions"][2][2] *
        volume.header["space directions"][2][2]
  );
  header.volume.rows = volume.header.sizes[0];
  header.volume.cols = volume.header.sizes[1];
  header.volume.numberOfSlices = volume.header.sizes[2];
  header.volume.imagePosition = volume.header["space origin"];
  header.volume.pixelSpacing = [spacing_x, spacing_y];
  header.volume.sliceThickness = spacing_z;
  header.volume.repr =
    volume.header.type[0].toUpperCase() + volume.header.type.slice(1);
  header.volume.intercept = custom_header ? custom_header.intercept : null;
  header.volume.slope = custom_header ? custom_header.slope : null;
  header.volume.phase = custom_header ? custom_header.phase : null;
  header.volume.study_description = custom_header
    ? custom_header.study_description
    : "";
  header.volume.series_description = custom_header
    ? custom_header.series_description
    : "";
  header.volume.acquisition_date = custom_header
    ? custom_header.acquisition_date
    : "";

  populateNrrdManager(header, volume, seriesId, orientation);

  // build custom manager for metadata handling in UI
  nrrdManager[seriesId][orientation]["studyDescription"] =
    header.volume.study_description;
  nrrdManager[seriesId][orientation]["seriesDescription"] =
    header.volume.series_description;
  nrrdManager[seriesId][orientation]["acquisitionDate"] =
    header.volume.acquisition_date;
  return nrrdManager[seriesId][orientation];
}

// ==============================
// Render a nrrd image volume ===
// ==============================
function renderNrrdImage(orientation, defaultProps, cb) {
  let seriesId = store.state.seriesId;
  store.dispatch("setLoadingOrientation", orientation);
  store.dispatch("quadview/setLoadingStatus", [orientation, false]);
  setTimeout(function() {
    loadImage(nrrdManager[seriesId][orientation], orientation, defaultProps);
    if (cb) cb();
  }, 500);
  store.dispatch("setLoadingOrientation", null);
}

// ==================================
// Load and render a Nrrd Image =====
// ==================================
function loadAndRenderNrrdImage(volume, orientation, header, callback) {
  let seriesId = store.state.seriesId;
  // check nrrd manager for the orientation
  // populate if needed
  // load and render image
  if (nrrdManager[seriesId] && nrrdManager[seriesId][orientation]) {
    renderNrrdImage(orientation);
    if (callback) {
      callback();
    }
  } else {
    let series = loadNrrdImage(volume, orientation, seriesId, header);
    store.dispatch("addSeries", series);
    renderNrrdImage(orientation);
    if (callback) {
      callback();
    }
  }
}

// =========================================
// Change contrast from a preset setting ===
// =========================================
function changeContrast(contrast) {
  let viewportNames =
    store.state.quadview.activeViewport == "all"
      ? ["axial", "coronal", "sagittal"]
      : [store.state.quadview.activeViewport];
  let viewer = "quadview";
  each(viewportNames, function(viewportName) {
    let element = document.getElementById(viewportName);
    let viewport = cornerstone.getViewport(element);
    viewport.voi.windowWidth = contrast.ww;
    viewport.voi.windowCenter = contrast.wl;
    cornerstone.setViewport(element, viewport);
    // sync ww and wc values in store
    store.dispatch(viewer + "/setContrast", [
      viewportName,
      contrast.ww,
      contrast.wl
    ]);
  });
}

// =========================================
// Get metadata from active series Id ======
// =========================================
// function getMetadata() {
//   let seriesId = store.state.seriesId;
//   let series = dicomManager[seriesId]
//     ? dicomManager[seriesId]["axial"]
//     : store.state.series[seriesId];
//   let sliceId = store.state.quadview.axial.sliceId;
//   let imageMetadata = series.instances[series.imageIds[sliceId]].metadata;
//   let metadata = [];
//   each(keys(imageMetadata), function(dataKey) {
//     if (dataKey.startsWith("x")) {
//       let tag = "(" + dataKey.slice(1, 5) + "," + dataKey.slice(5, 9) + ")";
//       let name = TAG_DICT[tag].name;
//       let value = imageMetadata[dataKey];
//       metadata.push({
//         tag: tag,
//         name: name,
//         value: value
//       });
//     }
//   });
//   return metadata;
// }

export {
  changeImageSlice,
  changeContrast,
  // getMetadata,
  disableImage,
  resizeCanvas,
  importDicomSerie,
  importDicomStudy,
  convertDICOMtoNRRD,
  loadAndRenderSerie,
  loadAndRenderNrrdImage,
  loadNrrdImage,
  renderNrrdImage,
  writeImage,
  clearCornerstoneElements,
  resetNrrdLoader,
  initializeImageLoader,
  registerResliceLoader,
  registerNRRDImageLoader,
  resetImageAPI,
  initializeCSTools,
  addDefaultTools,
  clearImageCache,
  saveImageInDB,
  importNRRDImage,
  importImage,
  registerImages
};
