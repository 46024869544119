<template>
  <div class="h100">
    <div class="canvas2DContainer">
      <div :id="name" class="canvas2D" ref="canvas">
        <template v-if="!this.$store.state.preview">
          <div
            class="metadata-left"
            :style="{ left: leftPos, bottom: metadataBottomPos }"
          >
            <p>
              <template v-if="rows && cols && rows > 0 && cols > 0">
                [{{ rows }} x {{ cols }}]
                <br />
              </template>
              <template
                v-if="
                  spacing_x && spacing_y && spacing_x > 0.0 && spacing_y > 0.0
                "
              >
                [{{ spacing_x }}mm x {{ spacing_y }}mm]
                <br />
              </template>
              <template v-if="isVolume && thickness && thickness > 0.0">
                Thickness: {{ thickness }}mm
              </template>
            </p>
          </div>
        </template>

        <template v-if="!this.$store.state.preview">
          <div
            class="metadata-right"
            :style="{ right: rightPos, top: metadataTopPos }"
          >
            <p>
              <template v-if="caseName">
                Case: {{ caseName }}
                <br />
              </template>
              <template v-if="seriesDescription">
                Description: {{ seriesDescription }}
                <br />
              </template>
              <template v-if="acquisitionDate">
                Acquisition Date: {{ acquisitionDate }}
                <br />
              </template>
              <template v-if="patientName">
                Patient: {{ patientName }}
                <br />
              </template>
              <template v-if="imageModality">
                Modality: {{ imageModality }} - {{ bitsAllocated }} bit
              </template>
            </p>
          </div>
        </template>

        <template v-if="!this.$store.state.preview">
          <div
            class="metadata-right"
            :style="{ right: rightPos, bottom: metadataBottomPos }"
          >
            <p>
              <template v-if="ww && wc">
                WW/WL: {{ ww }}/{{ wc }}
                <br />
              </template>
              <template v-if="scale && scale > 0.0">
                Zoom: {{ scale }}
                <br />
              </template>
              <template v-if="isVolume && sliceId">
                Slice {{ sliceId }} / {{ maxSliceId }}
              </template>
            </p>
          </div>
        </template>
      </div>
    </div>
    <div>
      <v-slider
        v-if="showSlider"
        class="h40"
        v-model="sliceId"
        :min="minSliceId"
        :max="maxSliceId"
        :thumb-label="true"
        :thumb-size="24"
      ></v-slider>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { keys } from "lodash";
import { changeImageSlice, resizeCanvas } from "@/common/api.images";

export default {
  data() {
    return {
      resizeObserver: null
    };
  },
  props: ["name", "activeViewport", "viewer"],
  mounted() {
    this.resizeObserver = new ResizeObserver(() => this.onResize());
    this.resizeObserver.observe(this.$refs.canvas);
    this.$store.dispatch("greenScreen", false);
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.canvas);
  },
  computed: {
    dicomGuiClass: function() {
      return this.activeViewport == "all"
        ? "dicom-gui-" + this.name
        : "dicom-gui-full-" + this.name;
    },
    isVolume: function() {
      return this.$store.state[this.viewer][this.name].maxSliceId === 0
        ? false
        : true;
    },
    showSlider: function() {
      return this.$store.state[this.viewer][this.name].maxSliceId < 2
        ? false
        : true;
    },
    ...mapState({
      quadviewReady(state) {
        return state[this.viewer].ready;
      },
      minSliceId(state) {
        return state[this.viewer][this.name].minSliceId;
      },
      maxSliceId(state) {
        return state[this.viewer][this.name].maxSliceId;
      },
      scale(state) {
        return parseFloat(state[this.viewer][this.name].viewport.scale).toFixed(
          2
        );
      },
      rotation(state) {
        return parseFloat(
          state[this.viewer][this.name].viewport.rotation
        ).toFixed(2);
      },
      ww(state) {
        return parseInt(state[this.viewer][this.name].viewport.voi.windowWidth);
      },
      wc(state) {
        return parseInt(
          state[this.viewer][this.name].viewport.voi.windowCenter
        );
      },
      rows(state) {
        return state[this.viewer][this.name].rows;
      },
      cols(state) {
        return state[this.viewer][this.name].cols;
      },
      spacing_x(state) {
        return state[this.viewer][this.name].spacing_x
          ? parseFloat(state[this.viewer][this.name].spacing_x).toFixed(3)
          : null;
      },
      spacing_y(state) {
        return state[this.viewer][this.name].spacing_y
          ? parseFloat(state[this.viewer][this.name].spacing_y).toFixed(3)
          : null;
      },
      thickness(state) {
        return parseFloat(state[this.viewer][this.name].thickness).toFixed(2);
      },
      seriesDescription(state) {
        return state.seriesId && keys(state.series).length > 0
          ? state.series[state.seriesId].seriesDescription
          : null;
      },
      acquisitionDate(state) {
        if (state.seriesId && keys(state.series).length > 0) {
          let instanceId = state.series[state.seriesId].imageIds[0];
          return (
            state.series[state.seriesId].instances[instanceId].metadata
              .seriesDate || null
          );
        } else {
          return null;
        }
      },
      caseName(state) {
        return state.caseName;
      },
      patientName(state) {
        if (state.seriesId && keys(state.series).length > 0) {
          let instanceId = state.series[state.seriesId].imageIds[0];
          return (
            state.series[state.seriesId].instances[instanceId].metadata
              .patientName || null
          );
        } else {
          return null;
        }
      },
      imageModality(state) {
        if (state.seriesId && keys(state.series).length > 0) {
          let instanceId = state.series[state.seriesId].imageIds[0];
          return (
            state.series[state.seriesId].instances[instanceId].metadata
              .seriesModality || null
          );
        } else {
          return null;
        }
      },
      bitsAllocated(state) {
        if (state.seriesId && keys(state.series).length > 0) {
          let instanceId = state.series[state.seriesId].imageIds[0];
          return (
            state.series[state.seriesId].instances[instanceId].metadata
              .x00280100 || null
          );
        } else {
          return null;
        }
      }
    }),
    sliceId: {
      get() {
        return this.$store.state[this.viewer][this.name].sliceId;
      },
      set(value) {
        this.$store.commit([this.viewer] + "/setCurrentSliceNumber", [
          this.name,
          value
        ]);
      }
    },
    leftPos: function() {
      if (this.activeViewport == "all") {
        if (this.name == "sagittal") {
          return "50%";
        } else {
          return "0%";
        }
      } else {
        return "0%";
      }
    },
    rightPos: function() {
      if (this.activeViewport == "all") {
        if (this.name == "sagittal") {
          return "0";
        } else {
          return "50%";
        }
      } else {
        return "25%";
      }
    },
    topPos: function() {
      if (this.activeViewport == "all") {
        if (this.name == "coronal") {
          return "50%";
        } else {
          return "0%";
        }
      } else {
        return "0%";
      }
    },
    metadataBottomPos: function() {
      if (this.activeViewport == "all") {
        if (this.name == "coronal") {
          return "7%";
        } else {
          return "55%";
        }
      } else {
        return this.isVolume ? "7%" : "0%";
      }
    },
    metadataTopPos: function() {
      if (this.activeViewport == "all") {
        if (this.name == "coronal") {
          return "50%";
        } else {
          return "0%";
        }
      } else {
        return "0%";
      }
    },
    expandLabel: function() {
      return this.activeViewport == "all"
        ? "Expand Viewport"
        : "Collapse Viewport";
    },
    isExpandHidden: function() {
      return this.quadviewReady ? false : true;
    }
  },
  watch: {
    sliceId: function(imageIndex) {
      let element = document.getElementById(this.name);
      if (element) {
        changeImageSlice(this.$store.state.seriesId, this.name, imageIndex);
      }
    }
  },
  methods: {
    onResize() {
      resizeCanvas(this.name);
    }
  }
};
</script>

<style scoped>
.metadata-left {
  font-size: 1vmax;
  text-align: left;
  color: white;
  float: left;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  z-index: 5;
  -webkit-font-smoothing: auto;
}
.metadata-right {
  font-size: 1vmax;
  text-align: right;
  color: white;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  z-index: 5;
  -webkit-font-smoothing: auto;
}

.expand-viewport {
  float: left;
  position: absolute;
  display: flex;
  padding: 0.5rem 0.5rem;
  z-index: 5;
}

.dicom-gui-axial,
.dicom-gui-sagittal {
  bottom: 47%;
  width: 50%;
}
.dicom-gui-coronal {
  bottom: -3%;
  width: 50%;
}

.dicom-gui-full-axial,
.dicom-gui-full-sagittal,
.dicom-gui-full-coronal {
  bottom: 0%;
  width: 100%;
}

.h40 {
  height: 40px;
}

.canvas2DContainer {
  background-color: black;
  height: calc(100% - 41px);
  width: calc(100% - 1px);
}

.canvas2D {
  background-color: black;
  height: calc(100% - 40px);
  width: calc(100%);
}

/* .canvas2D >>> .cornerstone-canvas {
  height: 100% !important;
  width: 100% !important;
} */

.canvas2D > .dicom-gui-axial,
.canvas2D > .dicom-gui-sagittal,
.canvas2D > .dicom-gui-coronal,
.canvas2D > .dicom-gui-full-axial,
.canvas2D > .dicom-gui-full-sagittal,
.canvas2D > .dicom-gui-full-coronal {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  z-index: 5;
}

.ww-wc,
.slice-number {
  color: #f1f1f1;
  font-size: 0.75rem;
}
p {
  margin-bottom: 0px !important;
}
</style>
