// external libraries
import request from "superagent";
import { each } from "lodash";
import Vue from "vue";

// internal libraries
import store from "@/store/index";

// ================================
// Get current logged user info ===
// ================================
export function setAiConfigs() {
  request
    .get("/api/aiconfigs/")
    .then(function(resp) {
      Vue.set(
        store.state.segmentations,
        "available_segmentations",
        resp.body[0].segmentations
      );
      Vue.set(
        store.state.segmentations,
        "default_segmentations",
        resp.body[0].segmentations
      );
    })
    .catch(error => {
      console.error(error);
    });
}

// ================================
// Get current logged user info ===
// ================================
export function setAiTasks(standard_fields) {
  request
    .get("/api/aitasks/")
    .then(function(resp) {
      store.state.segmentations.tags = {};
      store.state.segmentations.tags["arterial"] = [];
      store.state.segmentations.tags["venous"] = [];
      each(resp.body, function(el) {
        let task = { ...el, ...standard_fields };
        task.id = task.label_tag;
        task.thresholds = [task.min_threshold, task.max_threshold];
        task.thresholdsLimit = [
          task.min_threshold_limit,
          task.max_threshold_limit
        ];
        delete task["label_tag"];
        delete task["min_threshold"];
        delete task["max_threshold"];
        delete task["min_threshold_limit"];
        delete task["max_threshold_limit"];
        Vue.set(store.state.segmentations, task.id, task);
        store.state.segmentations.tags[task.phase].push(task.id);
      });
    })
    .catch(error => {
      console.error(error);
    });
}
