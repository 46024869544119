<template>
  <div>
    <template v-if="isRegistrationRunning">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip }" ripple icon class="mlr5">
            <font-awesome-icon icon="cog" size="lg" pull="right" spin />
          </v-btn>
        </template>
        <span>{{ $t("registration") }}</span>
      </v-tooltip>
    </template>
    <template v-if="arterialId">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            id="openArterial"
            v-on="{ ...tooltip }"
            ripple
            icon
            @click="selectSerie(arterialId)"
            class="mlr5"
            :style="{ color: isSeriesActive }"
            :disabled="isEditorActive"
          >
            A
            <font-awesome-icon
              icon="image"
              size="lg"
              pull="right"
              :class="isSeriesActive(arterialId)"
            />
          </v-btn>
        </template>
        <span>{{ $t("select_arterial") }}</span>
      </v-tooltip>
    </template>
    <template v-if="venousId">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            id="openVenous"
            v-on="{ ...tooltip }"
            ripple
            icon
            @click="selectSerie(venousId)"
            class="mlr5"
            :style="{ color: isSeriesActive }"
            :disabled="isEditorActive"
          >
            V
            <font-awesome-icon
              icon="image"
              size="lg"
              pull="right"
              :class="isSeriesActive(venousId)"
            />
          </v-btn>
        </template>
        <span>{{ $t("select_venous") }}</span>
      </v-tooltip>
    </template>

    <template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            id="open3d"
            v-on="{ ...tooltip }"
            ripple
            icon
            @click="open3D"
            class="mlr5"
            :disabled="isSegmentationsRunning || isEditorActive"
            :style="{ color: is3DActive }"
          >
            3D
            <font-awesome-icon
              icon="cubes"
              size="lg"
              pull="right"
              :class="is3DActive"
            />
          </v-btn>
        </template>
        <span>{{ $t("select_3D") }}</span>
      </v-tooltip>

      <v-divider
        vertical
        color="primary"
        style="display: inline; vertical-align: middle;"
      ></v-divider>
    </template>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="toggleContrast"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          :class="isToolActive('Wwwc')"
          @click="toggleMouseHandler('Wwwc')"
        >
          <font-awesome-icon icon="adjust" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("change_image_contrast") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="togglePan"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          :class="isToolActive('Pan')"
          @click="toggleMouseHandler('Pan')"
        >
          <font-awesome-icon icon="expand-arrows-alt" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("pan") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="toggleZoom"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          :class="isToolActive('Zoom')"
          @click="toggleMouseHandler('Zoom')"
        >
          <font-awesome-icon icon="search" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("zoom") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="toggleRotation"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          :class="isToolActive('Rotate')"
          @click="toggleMouseHandler('Rotate')"
        >
          <font-awesome-icon icon="sync-alt" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("rotate") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="toggleProbe"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          :class="isToolActive('DragProbe')"
          @click="toggleMouseHandler('DragProbe')"
        >
          <font-awesome-icon icon="eye-dropper" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("pixel_info") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="toggleMagnify"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          :class="isToolActive('Magnify')"
        >
          <font-awesome-icon icon="search-plus" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("magnify") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          id="toggleReset"
          v-on="on"
          :disabled="activeViewport == 'r3D'"
          ripple
          icon
          @click="resetSettings"
        >
          <font-awesome-icon icon="undo" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("reset_settings") }}</span>
    </v-tooltip>

    <v-divider
      vertical
      color="primary"
      style="display: inline; vertical-align: middle;"
    ></v-divider>

    <v-menu offset-y>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              v-on="{ ...tooltip, ...menu }"
              :disabled="activeViewport == 'r3D'"
              ripple
              icon
              :class="isMeasurementsToolActive"
            >
              <font-awesome-icon icon="pencil-ruler" size="lg" />
            </v-btn>
          </template>
          <span>{{ $t("measurement_tools") }}</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in measurementTools"
          :key="index"
          :id="item.name"
          @click="changeActiveTool(item)"
        >
          <v-list-item-title
            ><v-icon :class="isToolActive(item.value)">{{ item.icon }}</v-icon>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider
      vertical
      color="primary"
      style="display: inline; vertical-align: middle;"
    ></v-divider>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn id="openNotes" v-on="on" ripple icon @click="toggleNotes">
          <font-awesome-icon icon="notes-medical" size="lg" />
        </v-btn>
      </template>
      <span>{{ $t("case_notes") }}</span>
    </v-tooltip>

    <v-divider
      vertical
      color="primary"
      style="display: inline; vertical-align: middle;"
    ></v-divider>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  setToolActive,
  setToolEnabled,
  resetViewports,
  clearMeasurements,
  clearImageCache
} from "@/larvitar/index";

import { renderNrrdImage, disableImage } from "@/common/api.images";
import { saveCaseNotes } from "@/common/api.cases";
import { importSegmentations } from "@/common/api.jobs";
import { buildAndLoadScene } from "@/common/api.r3d";

export default {
  name: "DicomToolbar",
  components: {},
  data() {
    return {
      measurementTools: [
        { name: " Eraser", icon: "mdi-eraser", value: "Eraser" },
        { name: " Length", icon: "mdi-ruler", value: "Length" },
        { name: " Angle", icon: "mdi-angle-acute", value: "Angle" },
        { name: " Diameter", icon: "mdi-diameter", value: "Bidirectional" },
        { name: " Roi", icon: "mdi-ellipse", value: "EllipticalRoi" },
        {
          name: " Bounding Box",
          icon: "mdi-checkbox-blank",
          value: "RectangleRoi"
        },
        { name: " Annotation", icon: "mdi-tag", value: "ArrowAnnotate" },
        { name: " Delete All", icon: "mdi-delete-sweep", value: "removeAll" }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "isSegmentationsRunning",
      "isRegistrationRunning",
      "isEditorActive"
    ]),
    ...mapState({
      leftMouseHandler(state) {
        return state.leftMouseHandler;
      },
      measurementsToolActive(state) {
        return state.measurementsToolActive;
      },
      isMeasurementsToolActive: function() {
        return this.measurementsToolActive ? "activeMouseHandler" : "";
      },
      arterialId(state) {
        return state.arterialId;
      },
      venousId(state) {
        return state.venousId;
      },
      activeViewport(state) {
        return state.quadview.activeViewport;
      }
    }),
    is3DActive: function() {
      return this.$store.state.quadview.activeViewport == "r3D"
        ? "activeSerie"
        : "";
    }
  },
  methods: {
    isSeriesActive: function(serieUID) {
      return this.$store.state.seriesId == serieUID ? "activeSerie" : "";
    },
    isToolActive: function(toolName) {
      return this.leftMouseHandler == toolName ? "activeMouseHandler" : "";
    },
    toggleMouseHandler: function(handler) {
      if (this.$store.state.measurementsToolActive) {
        setToolEnabled(this.$store.state.leftMouseHandler);
      }
      this.$store.dispatch("setLeftMouseHandler", handler);
      this.$store.dispatch("setMeasurementsToolActive", false);
      setToolActive(handler);
    },
    toggleNotes: function() {
      let showNotes = this.$store.state.showNotes;
      if (showNotes) {
        saveCaseNotes(function(resp) {
          console.log(resp);
        });
      }
      this.$store.dispatch("showNotes", !showNotes);
    },
    open3D: function() {
      if (this.$store.state.quadview.activeViewport == "r3D") {
        return;
      }
      this.$store.dispatch("quadview/setLoadingStatus", ["r3D", false]);
      this.$store.dispatch("setSeriesId", null);
      this.$store.dispatch("setActivePhase", "");
      clearImageCache();
      disableImage("axial");
      this.$store.dispatch("quadview/setActiveViewport", "r3D");
      setTimeout(function() {
        buildAndLoadScene("r3D");
      }, 500);
    },
    selectSerie: function(serieUID) {
      if (this.$store.state.seriesId == serieUID) {
        return;
      }
      this.$store.dispatch("quadview/setActiveViewport", "axial");
      let self = this;
      let sliceNumber = this.$store.state.quadview.axial.sliceId;
      let ww = this.$store.state.quadview.axial.viewport.voi.windowWidth;
      let wc = this.$store.state.quadview.axial.viewport.voi.windowCenter;
      let scale = this.$store.state.quadview.axial.viewport.scale;
      let tr_x = this.$store.state.quadview.axial.viewport.translation.x;
      let tr_y = this.$store.state.quadview.axial.viewport.translation.y;
      // render selected serie
      clearImageCache();
      this.$store.dispatch("setSeriesId", serieUID);
      if (serieUID == this.$store.state.arterialId) {
        this.$store.dispatch("setActivePhase", "arterial");
      } else {
        this.$store.dispatch("setActivePhase", "venous");
      }
      this.$store.dispatch("setToolsInitialized", false);
      disableImage("axial");
      let defaultProps = {
        sliceNumber: sliceNumber,
        ww: ww,
        wc: wc,
        scale: scale,
        tr_x: tr_x,
        tr_y: tr_y,
        defaultWW: 600.0,
        defaultWC: 40.0
      };
      let segmentationIds = self.$store.state.segmentationIds[
        self.$store.state.activePhase
      ].slice();
      renderNrrdImage("axial", defaultProps, () => {
        importSegmentations(segmentationIds, () => {
          self.$store.dispatch("setMsgLog", "");
        });
      });
    },
    resetSettings: function() {
      resetViewports([this.$store.state.quadview.activeViewport]);
    },
    changeActiveTool: function(tool) {
      switch (tool.value) {
        case "removeAll":
          clearMeasurements(() => {
            // manually dispatch deleted event
            let element = document.getElementById("axial");
            let event = new Event("cornerstonetoolsmeasurementremoved");
            element.dispatchEvent(event);
          });
          break;
        default:
          if (this.$store.state.measurementsToolActive) {
            setToolEnabled(this.$store.state.leftMouseHandler);
          }
          this.$store.dispatch("setLeftMouseHandler", tool.value);
          this.$store.dispatch("setMeasurementsToolActive", true);
          setToolActive(tool.value);
      }
    }
  }
};
</script>

<style scoped>
.activeMouseHandler,
.activeSerie,
.activeLayout {
  color: #007ec3 !important;
}
.mlr5 {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
