import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    home: "Home",
    contact: "Contact us",
    preview: "Preview for Phase Selection",
    vic: "VIC",
    dashboard: "Dashboard",
    login: "Login",
    logout: "Logout",
    signup: "Sign Up",
    login_to: "Login to ",
    reset_pw_instructions:
      "Enter your email, a password recovery link will be sent to your inbox (or spam folder)",
    undo: "Undo",
    reset_pw: "Reset Password",
    login_email: "Enter your email and password to login",
    create_account: "Create an account",
    register: "Register",
    cases_dashboard: "VIC CASES",
    new_case: "Create a New Case",
    load_files: "Upload the abdominal CT scan (DICOM folder or NRRD files)",
    load_dicom: "Load a DICOM Folder",
    load_nrrd: "Load NRRD Files",
    cancel: "Cancel",
    load: "Load",
    save: "Save & Proceed",
    ok: "Ok",
    go_back: "Cancel & Go Back",
    phase_selection_warning:
      "One phase has not been selected. Would you like to continue anyway?",
    not_chosen: "Not Chosen",
    arterial: "Arterial",
    venous: "Venous",
    none: "None",
    arterial_phase: "Arterial Phase",
    venous_phase: "Venous Phase",
    phase: "Phase",
    choose_series_msg:
      "Virtual Clone will work on arterial and venous phases; please identify them for us",
    series_description: "Series Description",
    rows: "Rows",
    cols: "Cols",
    image_spacing: "Image Spacing",
    n_of_images: "Number of Images",
    slice_thickness: "Slice Thickness",
    case_name: "Case Name",
    exit: "Exit",
    exit_long: "Return to Dashboard",
    select_series: "Select Series",
    select_arterial: "Display Arterial Phase",
    select_venous: "Display Venous Phase",
    select_3D: "Display 3D",
    select_3D_long: "Proceed to 3D",
    change_image_contrast: "Change image contrast",
    pan: "Translate Image",
    zoom: "Zoom Image",
    rotate: "Rotate Image",
    pixel_info: "Get Pixel Info",
    magnify: "Magnify",
    reset_settings: "Reset Settings",
    measurement_tools: "Measurement Tools",
    case_notes: "Add Case Notes",
    close: "Close",
    editor: "Editor",
    full: "Full",
    contour: "Contour",
    invisible: "Invisible",
    segmentations: "Segmentations",
    automatic: "AI Segmentations",
    automatic_long: "Automatic AI Segmentations",
    drag_and_drop: "Drag and drop in order to select segmentations",
    available_segmentations: "Available Segmentations",
    selected_segmentations: "Selected Segmentations",
    select_all_segmentations_of: "Select all segmentations of",
    run_segmentations: "Run",
    run_segmentations_long: "Run Segmentations",
    all: "All",
    all_long: "Select all segmentations",
    no_segmentations:
      "Add a segmentation using our AI tool or the manual editor",
    warning_venous_na:
      "AI Automatic Segmentations were optimized to work with venous CT phase. Since only arterial phase has been provided, results may be not accurate.",
    warning_arterial_na:
      "AI Automatic Segmentations are available only for venous CT phase since arterial phase has not been provided",
    warning_arterial_rec:
      "AI Automatic Segmentations are available only for venous CT phase since co-registration between phases is computing.",
    warning_venous_rec:
      "AI Automatic Segmentations are available only for arterial CT phase since co-registration between phases is computing.",
    opacity: "Opacity",
    quality: "Rendering Quality",
    brightness: "Rendering Brightness",
    lut_interval: "LUT Interval",
    registration: "Image registration in progress...",
    registration_error:
      "Image registration has encountered an unexpected problem. One phase will not be available for the current case.",
    registration_error_accept: "I understand",
    registration_completed:
      "The registration process is finished. You can now use both phases.",
    ai_error:
      "AI segmentation process has encountered an unexpected problem on the AI server. Please retry.",
    ai_error_accept: "I understand",
    segmentation_editor: "Segmentation Editor",
    delete_manual_segmentation: "Delete segmentation",
    undo_segmentation: "Undo last action",
    redo_segmentation: "Redo last action",
    restore_segmentation: "Restore Original AI Segmentation",
    save_segmentation: "Save Changes",
    discard_segmentation: "Discard Changes",
    manual: "Manual segmentations",
    manual_long: "Add manual segmentation",
    add_new_segmentation: "Choose a name and a color",
    phase_manual_segmentation: "Adding manual segmentation on",
    reset_camera: "Reset Camera",
    reset_colormap: "Reset Volume ColorMap",
    toggle_green_screen: "Toggle Green Screen Mode",
    help_editor:
      "Draw with left mouse click and cancel with CTRL + left mouse click"
  },
  it: {}
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "it", // set fallback locale
  messages // set locale messages
});

export default i18n;
