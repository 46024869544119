import Vue from "vue";
import Vuex from "vuex";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";
import { concat, each, has } from "lodash";

import quadview from "./modules/quadview";
import segmentations from "./modules/segmentations";
import r3d from "./modules/r3d";

if (process.env.NODE_ENV != "development") {
  LogRocket.init("fgkvsf/virtualclone");
}
const logrocketPlugin = createPlugin(LogRocket);
Vue.use(Vuex);

function initialState() {
  return {
    currentPage: "home",
    intervalIds: [],
    accessToken: null,
    refreshToken: null,
    msgLog: null,
    errorLog: null,
    preview: false,
    viewer: "quadview",
    orientation: null,
    leftMouseHandler: "Wwwc",
    lastLeftMouseHandler: null,
    measurementsToolActive: false,
    toolsInitialized: false,
    manager: null,
    series: {},
    seriesId: null,
    arterialId: null,
    venousId: null,
    registrationPollingId: null,
    registrationStatus: 0, // 0 not done, 1 running, 2 done, 3 error, 4 error accepted
    aiError: false,
    caseId: null,
    caseName: null,
    imageIds: { venous: null, arterial: null },
    activePhase: "",
    segmentationIds: [],
    initialized: false,
    noteId: null,
    notes: "",
    showNotes: false,
    nrrdSeries: false,
    viewports: ["axial"],
    newCase: false,
    greenScreen: false
  };
}

export default new Vuex.Store({
  plugins: process.env.NODE_ENV == "development" ? [] : [logrocketPlugin],
  state: {
    ...initialState()
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        if (key != "accessToken" && key != "refreshToken") {
          state[key] = s[key];
        }
      });
    },
    setUser(state, value) {
      state.user = value;
      // identify user in logrocket
      if (process.env.NODE_ENV != "development") {
        if (value.id) {
          LogRocket.identify(value.id, {
            name: value.firstname + ", " + value.lastname,
            email: value.email.toLowerCase()
          });
        }
      }
    },
    setAccessToken(state, value) {
      state.accessToken = value;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    pushIntervalId(state, value) {
      state.intervalIds.push(value);
    },
    setRegistrationPollingId(state, value) {
      state.registrationPollingId = value;
    },
    setRegistrationStatus(state, value) {
      state.registrationStatus = value;
    },
    clearIntervalIds(state) {
      each(state.intervalIds, function(intervalId) {
        clearInterval(intervalId);
      });
      state.intervalIds = [];
      state.registrationPollingId = null;
      each(state.segmentations.tags.venous, function(tag) {
        state.segmentations[tag].pollingId = null;
      });
      each(state.segmentations.tags.arterial, function(tag) {
        state.segmentations[tag].pollingId = null;
      });
    },
    setMsgLog(state, value) {
      state.msgLog = value;
    },
    setErrorLog(state, value) {
      state.errorLog = value;
    },
    setAiError(state, value) {
      state.aiError = value;
    },
    setPreview(state, value) {
      state.preview = value;
    },
    setLoadingOrientation(state, orientation) {
      state.orientation = orientation;
    },
    setLeftMouseHandler(state, handler) {
      state.leftMouseHandler = handler;
    },
    setLastLeftMouseHandler(state, handler) {
      state.lastLeftMouseHandler = handler;
    },
    setMeasurementsToolActive(state, value) {
      state.measurementsToolActive = value;
    },
    setToolsInitialized(state, value) {
      state.toolsInitialized = value;
    },
    setManager(state, value) {
      state.manager = value;
    },
    addSeries(state, series) {
      if (Object.keys(state.series).length == 0) {
        state.series = {};
      }
      state.series[series.seriesUID] = series;
    },
    setSeries(state, series) {
      state.series = series;
    },
    setSeriesId(state, seriesId) {
      state.seriesId = seriesId;
    },
    setArterialId(state, arterialId) {
      state.arterialId = arterialId;
    },
    setVenousId(state, venousId) {
      state.venousId = venousId;
    },
    setCaseId(state, caseId) {
      state.caseId = caseId;
    },
    setCaseName(state, caseName) {
      state.caseName = caseName;
    },
    setArterialImageId(state, imageId) {
      state.imageIds.arterial = imageId;
    },
    setVenousImageId(state, imageId) {
      state.imageIds.venous = imageId;
    },
    setActivePhase(state, phase) {
      state.activePhase = phase;
    },
    setSegmentationIds(state, segmentationIds) {
      state.segmentationIds = segmentationIds;
    },
    setNoteId(state, noteId) {
      state.noteId = noteId;
    },
    setNotes(state, value) {
      state.notes = value;
    },
    showNotes(state, value) {
      state.showNotes = value;
    },
    setNrrdSeries(state, value) {
      state.nrrdSeries = value;
    },
    setNewCase(state, value) {
      state.newCase = value;
    },
    setInitialized(state, value) {
      state.initialized = value;
    },
    greenScreen(state, value) {
      state.greenScreen = value;
    }
  },
  actions: {
    clearState({ commit }) {
      commit("reset");
      commit("quadview/reset");
      commit("segmentations/reset");
      commit("r3d/reset");
    },
    setUser({ commit }, value) {
      commit("setUser", value);
    },
    setAccessToken({ commit }, value) {
      commit("setAccessToken", value);
    },
    setRefreshToken({ commit }, value) {
      commit("setRefreshToken", value);
    },
    setCurrentPage({ commit }, value) {
      commit("setCurrentPage", value);
    },
    pushIntervalId({ commit }, value) {
      commit("pushIntervalId", value);
    },
    setRegistrationPollingId({ commit }, value) {
      commit("setRegistrationPollingId", value);
    },
    setRegistrationStatus({ commit }, value) {
      commit("setRegistrationStatus", value);
    },
    clearIntervalIds({ commit }) {
      commit("clearIntervalIds");
    },
    setMsgLog({ commit }, value) {
      commit("setMsgLog", value);
    },
    setErrorLog({ commit }, value) {
      commit("setErrorLog", value);
    },
    setAiError({ commit }, value) {
      commit("setAiError", value);
    },
    setPreview({ commit }, value) {
      commit("setPreview", value);
    },
    setLoadingOrientation({ commit }, orientation) {
      commit("setLoadingOrientation", orientation);
    },
    setLeftMouseHandler({ commit }, handler) {
      commit("setLeftMouseHandler", handler);
    },
    setLastLeftMouseHandler({ commit }, handler) {
      commit("setLastLeftMouseHandler", handler);
    },
    setMeasurementsToolActive({ commit }, value) {
      commit("setMeasurementsToolActive", value);
    },
    setToolsInitialized({ commit }, value) {
      commit("setToolsInitialized", value);
    },
    setManager({ commit }, value) {
      commit("setManager", value);
    },
    setSeries({ commit }, series) {
      commit("setSeries", series);
    },
    addSeries({ commit }, series) {
      commit("addSeries", series);
    },
    setSeriesId({ commit }, seriesId) {
      commit("setSeriesId", seriesId);
    },
    setArterialId({ commit }, arterialId) {
      commit("setArterialId", arterialId);
    },
    setVenousId({ commit }, venousId) {
      commit("setVenousId", venousId);
    },
    setCaseId({ commit }, caseId) {
      commit("setCaseId", caseId);
    },
    setCaseName({ commit }, caseName) {
      commit("setCaseName", caseName);
    },
    setArterialImageId({ commit }, imageId) {
      commit("setArterialImageId", imageId);
    },
    setVenousImageId({ commit }, imageId) {
      commit("setVenousImageId", imageId);
    },
    setActivePhase({ commit }, phase) {
      commit("setActivePhase", phase);
    },
    setSegmentationIds({ commit }, segmentationIds) {
      commit("setSegmentationIds", segmentationIds);
    },
    setNoteId({ commit }, noteId) {
      commit("setNoteId", noteId);
    },
    setNrrdSeries({ commit }, imageId) {
      commit("setNrrdSeries", imageId);
    },
    setNewCase({ commit }, value) {
      commit("setNewCase", value);
    },
    setInitialized({ commit }, value) {
      commit("setInitialized", value);
    },
    setNotes({ commit }, value) {
      commit("setNotes", value);
    },
    showNotes({ commit }, value) {
      commit("showNotes", value);
    },
    greenScreen({ commit }, value) {
      commit("greenScreen", value);
    }
  },
  getters: {
    isQuadviewReady: function() {
      if (quadview.state.activeViewport == "all") {
        return quadview.state.axial.ready &&
          quadview.state.sagittal.ready &&
          quadview.state.coronal.ready &&
          quadview.state.r3D.ready
          ? true
          : false;
      } else {
        return quadview.state[quadview.state.activeViewport].ready;
      }
    },
    isSegmentationDataLoading: function() {
      return segmentations.state.loading;
    },
    isSegmentationsUploading: function() {
      return segmentations.state.uploading;
    },
    isSegmentationsRunning: function() {
      let tags = concat(
        segmentations.state.tags.arterial,
        segmentations.state.tags.venous
      );
      let isComputing = false;
      each(tags, function(tag) {
        if (has(segmentations.state, tag)) {
          if (segmentations.state[tag].pollingId) {
            isComputing = true;
          }
        }
      });
      return isComputing;
    },
    isRegistrationRunning: function(state) {
      return state.registrationPollingId ? true : false;
    },
    isEditorActive: function(state) {
      return Object.keys(state.segmentations.active).length > 0 ? true : false;
    },
    greenScreen: function(state) {
      return state.greenScreen;
    }
  },
  modules: {
    quadview: quadview,
    segmentations: segmentations,
    r3d: r3d
  }
});
