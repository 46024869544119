// external libraries
import { each, keys } from "lodash";
import uuid from "uuid";

// internal libraries
import {
  importDicomStudy,
  loadAndRenderSerie,
  importNRRDImage,
  loadNrrdImage,
  renderNrrdImage
} from "@/common/api.images";
import store from "@/store/index";

// ==============================================
// Parse and render a DICOM study for preview ===
// ==============================================
export function previewDICOM(files, callback) {
  importDicomStudy(files, function(seriesIds, seriesStack) {
    if (seriesIds && seriesStack) {
      if (keys(seriesStack).length == 0) {
        store.dispatch("setMsgLog", "");
        store.dispatch(
          "setErrorLog",
          "Error: No valid DICOM files have been parsed."
        );
        callback(true);
      } else {
        // by default load and render the first serie of the study
        store.dispatch("setPreview", true);
        store.dispatch("setSeriesId", seriesIds[0]);
        store.dispatch("setSeries", seriesStack);
        store.dispatch("setMsgLog", "");
        store.dispatch("setErrorLog", "");
        setTimeout(function() {
          loadAndRenderSerie(seriesStack[seriesIds[0]], "axial", function() {
            callback();
          });
        }, 500);
      }
    }
  });
}

// ======================================================
// Parse and render one or two nrrd files for preview ===
// ======================================================
export function previewNRRD(nrrdFiles, callback) {
  let numberOfImages = nrrdFiles.length;
  let counter = 0;
  each(nrrdFiles, function(nrrdFile, index) {
    let reader = new FileReader();
    reader.readAsArrayBuffer(nrrdFile);
    reader.onload = function() {
      let volume = importNRRDImage(reader.result);
      let seriesId = uuid.v4();
      let series = loadNrrdImage(volume, "axial", seriesId);
      store.dispatch("addSeries", series);
      counter++;
      if (index == 0) {
        store.dispatch("setSeriesId", seriesId);
      }
      if (counter == numberOfImages) {
        store.dispatch("setPreview", true);
        store.dispatch("setMsgLog", "");
        store.dispatch("setErrorLog", "");
        let defaultProps = {
          ww: 600.0,
          wc: 40.0,
          defaultWW: 600.0,
          defaultWC: 40.0
        };
        renderNrrdImage("axial", defaultProps);
        callback();
      }
    };
    reader.onerror = function() {
      console.log("error");
      store.dispatch("setMsgLog", "");
      store.dispatch("setErrorLog", "Error: Invalid nrrd file.");
      callback(true);
    };
  });
}
