import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Viewer from "@/views/Viewer.vue";

Vue.use(VueRouter);

// avoid back button on browser
window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      content: Home
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  },
  {
    path: "/viewer",
    name: "viewer",
    components: {
      content: Viewer
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
