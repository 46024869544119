// external libs
import request from "superagent";

// internal libs
import store from "@/store/index";
import { verify_and_refresh } from "@/common/api.users";
import {
  addSegmentationMask,
  forceRender,
  deleteMask
} from "@/common/api.segmentation";
import { importNRRDImage } from "@/larvitar/index";

import { map, groupBy, forEach, cloneDeep } from "lodash";

// =============================================
// Create a new job and finally store job id ===
// =============================================
export function createJob(phase, callback) {
  let imageId = store.state.imageIds[phase];
  let phaseSegmentations = store.state.segmentations.selected_segmentations.filter(
    segmentation => segmentation.phase == phase
  );

  if (phaseSegmentations.length == 0) {
    callback();
    return;
  }

  let segmentationsByPredictor = groupBy(phaseSegmentations, "predictor");

  // remove tags that have been already segmented
  // TODO is this necessary?
  // let dataTags = [];
  // forEach(labels, function(label) {
  //   dataTags.push(label);
  // });

  // for each predictor, extract only the label_tag
  // and store it in the jobs object
  let jobs = {};
  forEach(segmentationsByPredictor, function(segmentations, predictor) {
    let label_tags = map(segmentations, "label_tag");
    jobs[predictor] = label_tags;
  });

  let data = {
    case_id: store.state.caseId,
    image_id: imageId,
    phase: phase,
    jobs
  };

  if (process.env.NODE_ENV == "development") {
    console.log(data);
  }
  store.dispatch("segmentations/setIsUploading", true);

  verify_and_refresh(function() {
    request
      .post("/api/create_job/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function() {
        console.log("JOB CREATED ON AI SERVER", store.state.caseId);
        store.dispatch("segmentations/setIsUploading", false);
        getSegmentationsInfo(store.state.caseId, function(resp) {
          console.log("getSegmentationsInfo", resp.body);
          // dispatch jobId in store for each mask
          // and start polling
          let tag_counter = 0;
          forEach(resp.body, function(seg) {
            console.log("seg", seg);
            let label_tag = seg.fields.label_tag;
            let jobId = seg.fields.jobId;
            if (jobId) {
              store.dispatch("segmentations/setMaskJobId", [label_tag, jobId]);
              store.dispatch("segmentations/setMaskResourceId", [
                label_tag,
                seg.pk
              ]);
              store.dispatch("segmentations/setMaskImageId", [label_tag, null]);
              // start polling new segmentations
              // polling if not already polling
              console.log(
                "JOB CREATED ON AI SERVER WITH ID: ",
                jobId,
                " AND TAG: ",
                label_tag,
                " AND PK: ",
                seg.pk
              );
              if (!store.state.segmentations[label_tag].pollingId) {
                if (process.env.NODE_ENV == "development") {
                  console.log("Start Polling: ", label_tag, seg.pk);
                }
                let interval = 1000 + parseInt(tag_counter) * 1000;
                tag_counter += 1;
                setTimeout(function() {
                  pollingJob(seg.pk);
                }, interval);
              }
            }
          });
          callback();
        });
      })
      .catch(error => {
        store.dispatch("segmentations/setIsUploading", false);
        store.dispatch("setAiError", true);
        console.log("ERROR FROM AI SERVER");
        console.error(error.response.text);
        callback(error.response);
      });
  });
}

// ==================================
// Get Job Status from AI server ====
// ==================================
export function getJobStatus(resourceId, tag, callback) {
  if (resourceId) {
    verify_and_refresh(function() {
      request
        .get("/api/job_status/" + resourceId + "/")
        .set("Authorization", "Bearer " + store.state.accessToken)
        .then(function(resp) {
          callback(resp);
        })
        .catch(error => {
          console.log("ERROR FROM AI SERVER");
          console.log("WHILE CHECKING JOB STATUS");
          if (error.response) {
            console.error(error.response.text);
          }
          let intervalId = store.state.segmentations[tag].pollingId;
          console.log("CLEAR INTERVAL ID: ", intervalId);
          clearInterval(intervalId);
          store.dispatch("segmentations/setMaskPollingId", [tag, null]);
          callback(error.response);
        });
    });
  }
}

// ========================================
// Get job result and store resource id ===
// ========================================
export function getJobResult(resourceId, callback) {
  let tag = store.getters["segmentations/getMaskTag"](resourceId);
  if (resourceId && store.state.segmentations[tag].imageId == null) {
    verify_and_refresh(function() {
      request
        .get("/api/job_result/" + resourceId + "/")
        .set("Authorization", "Bearer " + store.state.accessToken)
        .then(function(resp) {
          callback(resp);
        })
        .catch(error => {
          if (store.state.segmentations[tag].imageId == null) {
            store.dispatch("setAiError", true);
            console.log("ERROR FROM AI SERVER");
            console.log("WHILE GETTING RESULT");
            console.error(error.response);
            callback(error.response);
          } else {
            callback({ statusCode: 201 });
          }
        });
    });
  } else {
    callback({ statusCode: 201 });
  }
}

// ======================================
// Continuously check for job status ====
// ======================================
export function pollingJob(segmentationId) {
  let tag = store.getters["segmentations/getMaskTag"](segmentationId);
  let intervalId = setInterval(() => {
    getJobStatus(segmentationId, tag, function(resp) {
      if (process.env.NODE_ENV == "development") {
        // console.log(resp);
        console.log(segmentationId, resp.body.status);
      }
      if (resp.body.status == "SUCCESS") {
        console.log(
          "JOB SUCCESSFULL ON AI SERVER WITH PK: ",
          segmentationId,
          " AND TAG: ",
          tag
        );
        clearInterval(intervalId);
        store.dispatch("segmentations/setMaskPollingId", [tag, null]);
        getJobResult(segmentationId, function(result) {
          if (process.env.NODE_ENV == "development") {
            console.log(result.statusCode);
          }
          if (result.statusCode == 200) {
            // add imageId into the store and clear jobId
            let phase = store.state.segmentations[tag].phase;
            store.dispatch("segmentations/setMaskJobId", [tag, null]);
            let imageId = store.state.imageIds[phase];
            store.dispatch("segmentations/setMaskImageId", [tag, imageId]);
            // set initialized to True
            store.dispatch("setInitialized", true);
            // render if phase is the same as the active one
            if (phase == store.state.activePhase) {
              getMask(null, segmentationId, function() {
                store.dispatch("setMsgLog", "");
                forceRender();
              });
            } else {
              getMaskSurfacePath(segmentationId);
              // populate segmentationIds array
              let segmentationIds = store.state.segmentationIds;
              segmentationIds[phase].push(segmentationId);
              store.dispatch("setSegmentationIds", segmentationIds);
            }
          }
          if (result.statusCode == 500) {
            store.dispatch("segmentations/setMaskJobId", [tag, null]);
            store.dispatch("segmentations/setMaskResourceId", [tag, null]);
          }
        });
      }
    });
  }, 5000); // 5 seconds
  store.dispatch("segmentations/setMaskPollingId", [tag, intervalId]);
  store.dispatch("pushIntervalId", intervalId);
}

// ==============================
// Get mask from server =========
// ==============================
export function getMaskSurfacePath(resourceId) {
  verify_and_refresh(() => {
    request
      .get("/api/segmentation/" + resourceId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        let image = JSON.parse(res.text);
        let surfacePath = res.body.surface;
        if (surfacePath) {
          store.dispatch("segmentations/setMaskSurfacePath", [
            image.label_tag,
            surfacePath
          ]);
        } else {
          buildSurface(image.label_tag, resourceId);
        }
      });
  });
}

// ==============================
// Download mask from server ====
// ==============================
export function getMask(log, resourceId, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/segmentation/" + resourceId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .on("progress", function(event) {
        if (process.env.NODE_ENV == "development") {
          console.log(event.percent);
        }
        if (log) {
          store.dispatch("setMsgLog", log + event.percent.toFixed(0) + "%");
        }
      })
      .then(res => {
        let image = JSON.parse(res.text);
        let xmlreq = new XMLHttpRequest();
        xmlreq.open("GET", image.resource, true);
        xmlreq.responseType = "arraybuffer";
        xmlreq.onload = function() {
          const volume = importNRRDImage(xmlreq.response);
          let maskProps = store.state.segmentations[image.label_tag];
          let data = new Uint16Array(volume.data);
          addSegmentationMask(maskProps, data, "axial", callback);
          let surfacePath = res.body.surface;
          if (surfacePath) {
            store.dispatch("segmentations/setMaskSurfacePath", [
              image.label_tag,
              surfacePath
            ]);
          } else {
            buildSurface(image.label_tag, resourceId);
          }
        };
        xmlreq.send();
      });
  });
}

// ============================
// Build Surface from mask ====
// ============================
export function buildSurface(label_tag, resourceId) {
  verify_and_refresh(() => {
    request
      .get("/api/build_surface/" + resourceId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        let surfacePath = res.text;
        store.dispatch("segmentations/setMaskSurfacePath", [
          label_tag,
          surfacePath
        ]);
      })
      .catch(err => {
        store.dispatch("setAiError", true);
        console.log("INVALID SEGMENTATION, UNABLE TO BUILD SURFACE");
        console.error(err);
      });
  });
}

// ========================================
// Add a new empty mask in the database ===
// ========================================
export function addNewMask(tag, color, callback) {
  let phase = store.state.activePhase;
  let imageId = store.state.imageIds[phase];
  let data = {
    case_id: store.state.caseId,
    image_id: imageId,
    phase: phase,
    label_tag: tag,
    color: color,
    predictor: "manual"
  };
  verify_and_refresh(() => {
    request
      .post("/api/new_mask/")
      .send(data)
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        if (process.env.NODE_ENV == "development") {
          console.log(res);
        }
        if (callback) {
          callback(res.body.resource_id);
        }
      });
  });
}

// ==================================
// Update mask with modified data ===
// ==================================
export function updateMask(resourceId, mask_data, callback) {
  verify_and_refresh(() => {
    request
      .post("/api/update_mask/")
      .field("resource_id", resourceId)
      .attach(
        "data",
        new File([mask_data.buffer], { type: "application/octet-stream" })
      )
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        if (process.env.NODE_ENV == "development") {
          console.log(res);
        }
        if (callback) {
          callback();
        }
      });
  });
}

// ========================================
// Reset original mask into existin one ===
// ========================================
export function resetOriginalMask(resourceId, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/reset_mask/" + resourceId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        if (process.env.NODE_ENV == "development") {
          console.log(res);
        }
      });
  });
  getOriginalMask(resourceId, callback);
}

// ==========================================
// Download original AI mask from server ====
// ==========================================
export function getOriginalMask(resourceId, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/segmentation/" + resourceId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .on("progress", function(event) {
        if (process.env.NODE_ENV == "development") {
          console.log(event.percent);
        }
      })
      .then(res => {
        let image = JSON.parse(res.text);
        let xmlreq = new XMLHttpRequest();
        xmlreq.open("GET", image.original, true);
        xmlreq.responseType = "arraybuffer";
        xmlreq.onload = function() {
          const volume = importNRRDImage(xmlreq.response);
          let maskProps = store.state.segmentations[image.tag];
          let data = new Uint16Array(volume.data);
          addSegmentationMask(maskProps, data, "axial", callback);
          let surfacePath = res.body.surface;
          store.dispatch("segmentations/setMaskSurfacePath", [
            image.label_tag,
            surfacePath
          ]);
        };
        xmlreq.send();
      });
  });
}

// ============================================
// Download surface from server (NOT USED) ====
// ============================================
export function getSurface(surfacePath, callback) {
  if (surfacePath) {
    verify_and_refresh(() => {
      request
        .get(surfacePath)
        // .responseType("arraybuffer")
        .then(res => {
          callback(res.text);
        });
    });
  }
}

// =========================================================
// Get the list of segmentation objects for current case ===
// =========================================================
export function getSegmentationsInfo(caseId, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/phase_segmentations/" + caseId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(res => {
        callback(res);
      });
  });
}

// ============================
// Import masks from server ===
// ============================
export function importSegmentations(segmentationsIds, cb) {
  let segId = segmentationsIds.pop();
  if (segId) {
    store.dispatch("setInitialized", true);
    // if jobID, polling
    let tag = store.getters["segmentations/getMaskTag"](segId);
    if (!tag) {
      console.log("no tag for:", segId);
    }
    let jobId = store.state.segmentations[tag]
      ? store.state.segmentations[tag].jobId
      : null;
    if (jobId) {
      // start polling
      pollingJob(segId);
      importSegmentations(segmentationsIds, cb);
    } else {
      store.dispatch("segmentations/setIsLoading", true);
      // download and render mask
      getMask(`Downloading mask (id ${segId})...`, segId, () => {
        forceRender();
        importSegmentations(segmentationsIds, cb);
      });
    }
  } else {
    // segmentationsIds is empty
    store.dispatch("segmentations/setIsLoading", false);
    cb();
  }
}

// =============================
// Deleta a mask from server ===
// =============================

export function deleteSegmentation(mask, callback) {
  // remove from server
  verify_and_refresh(function() {
    request
      .delete("/api/segmentation/" + mask.resourceId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function() {
        // remove from store (key is item)
        let segmentationIds = cloneDeep(store.state.segmentationIds);
        segmentationIds[store.state.activePhase] = segmentationIds[
          store.state.activePhase
        ].filter(item => item !== mask.resourceId);
        store.dispatch("setSegmentationIds", segmentationIds);
        store.dispatch("segmentations/removeMask", [
          store.state.activePhase,
          mask.id
        ]);
        deleteMask(mask.labelId);
        if (callback) {
          callback();
        }
      });
  });
}
