<template>
  <div class="h100 w100">
    <v-toolbar flat dense height="55">
      <v-toolbar-title>
        <a href="https://vic-virtualclone.com/" target="_blank">
          <v-img
            src="/media/images/vic_logo.png"
            vic_logo_brand
            width="200"
            max-width="200"
            contain
            class="toolbar-logo"
          >
          </v-img>
        </a>
      </v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>

      <template v-if="currentPage == 'viewer'">
        <DicomToolBar />
      </template>

      <v-spacer></v-spacer>

      <span class="version"> {{ appVersion }} </span>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            id="contactUs"
            class="fr m5"
            icon
            v-on="on"
            href="mailto:vic.web@aimsacademy.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font-awesome-icon icon="question" />
          </v-btn>
        </template>
        <span>{{ $t("contact") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn id="logout" class="fr m5" icon v-on="on" @click="logout">
            <font-awesome-icon icon="sign-out-alt" />
          </v-btn>
        </template>
        <span>{{ $t("logout") }}</span>
      </v-tooltip>

      <v-divider class="mx-4" vertical></v-divider>

      <v-toolbar-title>
        <a href="https://www.dvisionlab.com" target="_blank">
          <v-img
            src="/media/images/dvision_logo_2019_symbol.png"
            width="70"
            max-width="70"
            contain
            class="toolbar-logo-brand"
          >
          </v-img>
        </a>
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { version } from "@/../package.json";
import { clearCornerstoneElements, resetImageAPI } from "@/common/api.images";
import { setCurrentUser } from "@/common/api.users";
import { clearSegmentationState } from "@/common/api.segmentation";

import DicomToolBar from "@/components/toolbars/DicomToolBar";

export default {
  name: "ToolBar",
  data() {
    return {};
  },
  components: { DicomToolBar },
  computed: {
    ...mapState({
      currentPage(state) {
        return state.currentPage;
      }
    }),
    appVersion: function() {
      return "v" + version;
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("setAccessToken", null);
      this.$store.dispatch("setRefreshToken", null);
      setCurrentUser(null);
      if (this.$store.state.currentPage != "home") {
        // clear intervals
        this.$store.dispatch("clearIntervalIds");
        // go to home
        this.$router.push({ name: "home" });
        // clean cornerstone library
        clearCornerstoneElements();
        clearSegmentationState();
        resetImageAPI();
        // reset the store
        this.$store.dispatch("clearState");
      }
    }
  }
};
</script>

<style scoped>
.toolbar-logo {
  margin-right: 1em;
}
.ml05em {
  margin-left: 0.5em;
}
.toolbar-logo-brand {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.text-caps {
  text-transform: uppercase;
}
.m5 {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
