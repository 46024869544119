<template>
  <v-dialog
    v-model="getToken"
    persistent
    width="47%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="headline justify-center" primary-title>
        <a href="https://vic-virtualclone.com/" target="_blank">
          <v-img
            src="/media/images/vic_logo.png"
            vic_logo_brand
            width="200"
            max-width="200"
            contain
            class="toolbar-logo"
          >
          </v-img>
        </a>
      </v-card-title>

      <template v-if="passwordReset">
        <v-card flat>
          <v-card-text>
            <span>
              {{ $t("reset_pw_instructions") }}
            </span>
            <v-form
              ref="reset_form"
              v-model="validResetPassword"
              @submit.prevent
            >
              <v-text-field
                required
                :rules="emailRules"
                prepend-icon="mdi-email"
                v-model="email"
                name="email"
                label="e-mail"
              ></v-text-field>
            </v-form>
            <v-card-actions>
              <v-row>
                <v-col cols="4">
                  <v-btn
                    class="w100 font80"
                    primary
                    block
                    large
                    @click="goBack"
                  >
                    <font-awesome-icon class="mr-3" icon="undo" />
                    {{ $t("undo") }}
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="w100 font80"
                    primary
                    block
                    large
                    @click="resetPassword"
                    :disabled="!validResetPassword"
                  >
                    <font-awesome-icon class="mr-3" icon="sign-in-alt" />
                    {{ $t("reset_pw") }}
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="w100 font80"
                    primary
                    block
                    large
                    href="mailto:vic.web@aimsacademy.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <font-awesome-icon class="mr-3" icon="question" />
                    {{ $t("contact") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-alert v-if="error" normal :icon="false" type="error">
              {{ errorLog }}
            </v-alert>
            <v-alert v-if="success" normal :icon="false" type="success">
              {{ successLog }}
            </v-alert>
          </v-card-text>
        </v-card>
      </template>

      <template v-else>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab @click="resetFields" key="0">
            <font-awesome-icon class="mr-3" icon="user" />
            {{ $t("login") }}
          </v-tab>
          <v-tab @click="resetFields" key="1">
            <font-awesome-icon class="mr-3" icon="user-plus" />
            {{ $t("signup") }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <v-card flat>
              <v-card-text>
                <span> {{ $t("login_email") }} </span>
                <v-form ref="login_form" v-model="validLogin" @submit.prevent>
                  <v-text-field
                    prepend-icon="mdi-email"
                    required
                    :rules="emailRules"
                    v-model="email"
                    name="Email"
                    label="e-mail"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    required
                    :rules="passwordRules"
                    v-model="password"
                    name="Password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-form>
                <v-checkbox v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      By submitting you accept all the
                      <a
                        href="/media/docs/legal_notice.pdf"
                        target="_blank"
                        @click.stop
                        >Terms and Conditions</a
                      >
                      as well as the
                      <a
                        href="/media/docs/privacy.pdf"
                        target="_blank"
                        @click.stop
                        >Privacy Policy</a
                      >
                      of the platform.
                    </div>
                  </template>
                </v-checkbox>
                <v-alert
                  v-if="deviceWarning"
                  normal
                  :icon="true"
                  type="warning"
                >
                  {{ deviceWarning }}
                </v-alert>
                <v-card-actions>
                  <v-row>
                    <v-col cols="sm-4">
                      <v-btn
                        class="w100 font80"
                        primary
                        block
                        large
                        @click="resetForm"
                      >
                        <font-awesome-icon class="mr-3" icon="undo" />
                        {{ $t("reset_pw") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="sm-4">
                      <v-btn
                        class="w100 font80"
                        primary
                        block
                        large
                        @click="login"
                        :disabled="!validLogin || !checkbox"
                      >
                        <font-awesome-icon class="mr-3" icon="sign-in-alt" />
                        {{ $t("login") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        class="w100 font80"
                        primary
                        block
                        large
                        href="mailto:vic.web@aimsacademy.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <font-awesome-icon class="mr-3" icon="question" />
                        {{ $t("contact") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-alert v-if="error" normal :icon="false" type="error">
                  {{ errorLog }}
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="1">
            <v-card flat>
              <v-card-text>
                <span>{{ $t("create_account") }}</span>
                <v-form
                  ref="register_form"
                  v-model="validCreate"
                  @submit.prevent
                >
                  <v-text-field
                    prepend-icon="mdi-account"
                    required
                    :rules="firstnameRules"
                    v-model="firstname"
                    name="firstname"
                    label="First Name"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-account"
                    required
                    :rules="lastnameRules"
                    v-model="lastname"
                    name="lastname"
                    label="Last Name"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-email"
                    required
                    :rules="emailRules"
                    v-model="email"
                    name="email"
                    label="e-mail"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    required
                    :rules="passwordRules"
                    v-model="password"
                    name="Password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    required
                    :rules="passwordRules.concat(passwordConfirmationRule)"
                    v-model="password2"
                    name="Password2"
                    label="Password Confirmation"
                    type="password"
                  ></v-text-field>
                </v-form>
                <v-checkbox v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      By submitting you accept all the
                      <a
                        href="/media/docs/legal_notice.pdf"
                        target="_blank"
                        @click.stop
                        >Terms and Conditions</a
                      >
                      as well as the
                      <a
                        href="/media/docs/privacy.pdf"
                        target="_blank"
                        @click.stop
                        >Privacy Policy</a
                      >
                      of the platform.
                    </div>
                  </template>
                </v-checkbox>
                <v-alert v-if="error" normal :icon="false" type="error">
                  {{ errorLog }}
                </v-alert>
                <v-alert v-if="success" normal :icon="false" type="success">
                  {{ successLog }}
                </v-alert>
                <v-card-actions>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        primary
                        block
                        large
                        @click="register"
                        :disabled="!validCreate || !checkbox"
                      >
                        <font-awesome-icon class="mr-3" icon="sign-in-alt" />
                        {{ $t("register") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        class="w100 font80"
                        primary
                        block
                        large
                        href="mailto:vic.web@aimsacademy.org"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <font-awesome-icon class="mr-3" icon="question" />
                        {{ $t("contact") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  jwt_login,
  createUser,
  resetPasswordWithEmail,
  setCurrentUser
} from "@/common/api.users";

import { isMobileOnly, isTablet } from "mobile-device-detect";

// global variables
const debug = process.env.NODE_ENV == "development" ? true : false;
// enables autologin for manually created super user
// (test@dvisionlab.com passwd DVisionUser0)

// INCLUDE THIS COMPONENT WITH
// <AccountsModal :token="token" />
// WHERE TOKEN IS MAPSTATE ACCESSTOKEN

export default {
  data() {
    return {
      firstname: null,
      lastname: null,
      password: null,
      password2: null,
      email: null,
      error: false,
      success: false,
      errorLog: "",
      successLog: "",
      checkbox: false,
      passwordReset: false,
      tab: 0,
      validResetPassword: false,
      validLogin: false,
      validCreate: false,
      firstnameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 20) || "First Name must be less than 20 characters"
      ],
      lastnameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 20) || "Last Name must be less than 20 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v) ||
          "Min. 8 characters with at least one capital letter and a number."
      ]
    };
  },
  props: ["token"],
  computed: {
    getToken: function() {
      return this.token ? false : true;
    },
    passwordConfirmationRule: function() {
      return () => this.password === this.password2 || "Passwords must match";
    },
    deviceWarning: function() {
      if (isMobileOnly) {
        return "VIC App is not supported on mobile phones. Please use a different device.";
      } else if (isTablet) {
        return "VIC App is not fully compatible with mobile tablets. Some functionalities may be limited.";
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    let self = this;
    if (debug) {
      setTimeout(function() {
        jwt_login("test@dvisionlab.com", "DVisionUser0", function(resp) {
          if (resp.status == 200) {
            self.$store.dispatch("setAccessToken", resp.body.access);
            self.$store.dispatch("setRefreshToken", resp.body.refresh);
            self.error = false;
            self.errorLog = false;
            self.checkbox = false;
            setCurrentUser(resp.body.access);
          } else {
            self.error = true;
            self.errorLog = Object.values(
              resp.response.body
            )[0].toLocaleString();
          }
        });
      }, 500);
    }
  },
  methods: {
    login: function() {
      let self = this;
      self.success = false;
      self.successLog = "";
      self.error = false;
      self.errorLog = "";
      jwt_login(this.email.toLowerCase(), this.password, function(resp) {
        if (resp.status == 200) {
          self.$store.dispatch("setAccessToken", resp.body.access);
          self.$store.dispatch("setRefreshToken", resp.body.refresh);
          self.error = false;
          self.errorLog = false;
          setCurrentUser(resp.body.access);
          self.email = null;
          self.password = null;
          self.checkbox = false;
          self.$refs.login_form.resetValidation();
        } else {
          self.error = true;
          self.errorLog = Object.values(resp.response.body)[0].toLocaleString();
        }
      });
    },
    resetForm: function() {
      this.resetFields();
      this.passwordReset = true;
    },
    goBack: function() {
      this.resetFields();
      this.passwordReset = false;
    },
    resetFields: function() {
      this.success = false;
      this.successLog = "";
      this.error = false;
      this.errorLog = "";
      this.checkbox = false;
    },
    resetPassword: function() {
      let self = this;
      self.success = false;
      self.successLog = "";
      self.error = false;
      self.errorLog = "";
      resetPasswordWithEmail(this.email.toLowerCase(), function(resp) {
        if (resp.status == 204) {
          self.success = true;
          self.successLog =
            "Check your inbox (or spam folder) to reset the password";
          self.email = null;
          self.$refs.reset_form.resetValidation();
        } else {
          self.error = true;
          self.errorLog = Object.values(resp.response.body)[0].toLocaleString();
        }
      });
    },
    register: function() {
      let self = this;
      self.success = false;
      self.successLog = "";
      self.error = false;
      self.errorLog = "";
      createUser(
        this.firstname,
        this.lastname,
        this.password,
        this.email.toLowerCase(),
        function(resp) {
          if (resp.status == 201) {
            self.success = true;
            self.successLog =
              "Check your inbox (or spam folder) to activate the account";
            self.firstname = null;
            self.lastname = null;
            self.password = null;
            self.password2 = null;
            self.email = null;
            self.checkbox = false;
            self.$refs.register_form.resetValidation();
          } else {
            self.error = true;
            self.errorLog = Object.values(
              resp.response.body
            )[0].toLocaleString();
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
.w100 {
  width: 100%;
}
.font80 {
  font-size: 80% !important;
}
</style>
