// external libs
import Vue from "vue";
import { has, findKey, without } from "lodash";
import { setAiConfigs, setAiTasks } from "@/common/api.settings";

// get from db
// for each entry add standard_fields to the entry

// populate available_segmentations and default_segmentations
// w/ {id name tag phase}

// populate tags dict w/ venous: [tags...] arterial: [tags]

const standard_fields = {
  imageId: null, // id of the DICOM image
  resourceId: null, // id of the segmentation mask
  surfacePath: null, // path of the segmentation surface
  jobId: null, // id of the job on AI server
  pollingId: null, // id of the polling process,
  status: "", // status message of the AI Job
  error: "", // error message of the AI Job
  visualization: 0, // 0 full, 1 contour, 2 invisible,
  r3Dvisualization: 0, // 0 invisible 1 visible
  manual: false
};

function initialState() {
  return {
    active: {}, // tag: tagName, label: labelId,
    loading: false, // loading flag
    uploading: false, // uploading flag
    ids: {}, // venous: [], arterial: [],
    tags: {},
    selected_segmentations: [],
    available_segmentations: [],
    default_segmentations: []
  };
}

const state = {
  ...initialState()
};

setAiConfigs();
setAiTasks(standard_fields);

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(state).forEach(key => {
      if (!has(s, key)) {
        delete state[key];
      }
    });
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
    setAiConfigs();
    setAiTasks(standard_fields);
  },
  setTags(state, value) {
    state.tags = value;
  },
  setAvailableSegmentations(state, value) {
    state.available_segmentations = value;
  },
  setSelectedSegmentations(state, value) {
    state.selected_segmentations = value;
  },
  setIsLoading(state, value) {
    state.loading = value;
  },
  setIsUploading(state, value) {
    state.uploading = value;
  },
  setIds(state, value) {
    state.ids = value;
  },
  setActiveMask(state, [tag, label]) {
    if (has(state, tag)) {
      let activeMask = {
        tag: tag,
        label: label
      };
      state.active = activeMask;
    } else {
      state.active = {};
    }
  },
  addMask(state, [phase, mask, predictor]) {
    if (has(state.tags, phase) && !has(state, mask)) {
      let tags = state.tags;
      tags[phase].push(mask);
      Vue.set(state.tags, tags);
      let maskObj = {
        id: mask,
        name: mask,
        predictor: predictor,
        region: "",
        phase: phase,
        imageId: null, // id of the DICOM image
        resourceId: null, // id of the segmentation mask
        surfacePath: null, // path of the segmentation surface
        jobId: null, // id of the job on AI server
        pollingId: null, // id of the polling process
        labelId: null, // id of the label in the segmentation mask
        status: "", // status message of the AI Job
        error: "", // error message of the AI Job
        color: "#16fb35", // hex color for the segmentation mask
        colorUI: "#16fb35", // hex color for the segmentation mask
        opacity: 0.5, // default opacity for the segmentation mask
        thresholds: [0, 200], // min and max default thresholds for the segmentation mask
        thresholdsLimit: [-500, 700], // min and max boundaries thresholds for the segmentation mask
        dimension: 5, // brush default dimension for the segmentation mask (0, 1, 2)
        visualization: 0, // 0 full, 1 contour, 2 invisible,
        r3Dvisualization: 1, // 0 invisible 1 visible
        shortcut: "",
        manual: true
      };
      state[mask] = maskObj;
    }
  },
  removeMask(state, [phase, mask]) {
    if (has(state.tags, phase)) {
      let tags = state.tags;
      tags[phase] = without(tags[phase], mask);
      if (has(state, mask)) {
        delete state[mask];
      }
      Vue.set(state.tags, tags);
    }
  },
  setMaskImageId(state, [mask, imageId]) {
    if (has(state, mask)) {
      state[mask].imageId = imageId;
    }
  },
  setMaskResourceId(state, [mask, resourceId]) {
    if (has(state, mask)) {
      state[mask].resourceId = resourceId;
    }
  },
  setMaskSurfacePath(state, [mask, surfacePath]) {
    if (has(state, mask)) {
      state[mask].surfacePath = surfacePath;
    }
  },
  setMaskJobId(state, [mask, jobId]) {
    if (has(state, mask)) {
      state[mask].jobId = jobId;
    }
  },
  setMaskPollingId(state, [mask, pollingId]) {
    if (has(state, mask)) {
      state[mask].pollingId = pollingId;
    }
  },
  setMaskLabelId(state, [mask, labelId]) {
    if (has(state, mask)) {
      state[mask].labelId = labelId;
    }
  },
  setMaskColor(state, [mask, color]) {
    if (has(state, mask)) {
      state[mask].color = color;
    }
  },
  setMaskColorUI(state, [mask, color]) {
    if (has(state, mask)) {
      state[mask].colorUI = color;
    }
  },
  setMaskStatus(state, [mask, statusMsg]) {
    if (has(state, mask)) {
      state[mask].status = statusMsg;
    }
  },
  setMaskError(state, [mask, errorMsg]) {
    if (has(state, mask)) {
      state[mask].error = errorMsg;
    }
  },
  setMaskOpacity(state, [mask, value]) {
    if (has(state, mask)) {
      state[mask].opacity = value;
    }
  },
  setMaskVisualization(state, [mask, value]) {
    if (has(state, mask)) {
      state[mask].visualization = value;
    }
  },
  setMaskR3DVisualization(state, [mask, value]) {
    if (has(state, mask)) {
      state[mask].r3Dvisualization = value;
    }
  },
  setMaskMinTreshold(state, [mask, value]) {
    if (has(state, mask)) {
      state[mask].threshold[0] = value;
    }
  },
  setMaskMaxTreshold(state, [mask, value]) {
    if (has(state, mask)) {
      state[mask].threshold[1] = value;
    }
  },
  setMaskBrushDimension(state, [mask, value]) {
    if (has(state, mask)) {
      state[mask].dimension = value;
    }
  }
};

const actions = {
  setAvailableSegmentations({ commit }, value) {
    commit("setAvailableSegmentations", value);
  },
  setSelectedSegmentations({ commit }, value) {
    commit("setSelectedSegmentations", value);
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setIsUploading({ commit }, value) {
    commit("setIsUploading", value);
  },
  setIds({ commit }, value) {
    commit("setIds", value);
  },
  setActiveMask({ commit }, [tag, label]) {
    commit("setActiveMask", [tag, label]);
  },
  addMask({ commit }, [phase, mask]) {
    commit("addMask", [phase, mask]);
  },
  removeMask({ commit }, [phase, mask]) {
    commit("removeMask", [phase, mask]);
  },
  setMaskImageId({ commit }, [mask, imageId]) {
    commit("setMaskImageId", [mask, imageId]);
  },
  setMaskPollingId({ commit }, [mask, pollingId]) {
    commit("setMaskPollingId", [mask, pollingId]);
  },
  setMaskResourceId({ commit }, [mask, resourceId]) {
    commit("setMaskResourceId", [mask, resourceId]);
  },
  setMaskSurfacePath({ commit }, [mask, surfacePath]) {
    commit("setMaskSurfacePath", [mask, surfacePath]);
  },
  setMaskJobId({ commit }, [mask, jobId]) {
    commit("setMaskJobId", [mask, jobId]);
  },
  setMaskLabelId({ commit }, [mask, labelId]) {
    commit("setMaskLabelId", [mask, labelId]);
  },
  setMaskColor({ commit }, [mask, color]) {
    commit("setMaskColor", [mask, color]);
  },
  setMaskColorUI({ commit }, [mask, color]) {
    commit("setMaskColorUI", [mask, color]);
  },
  setMaskStatus({ commit }, [mask, statusMsg]) {
    commit("setMaskStatus", [mask, statusMsg]);
  },
  setMaskError({ commit }, [mask, errorMsg]) {
    commit("setMaskError", [mask, errorMsg]);
  },
  setMaskOpacity({ commit }, [mask, opacity]) {
    commit("setMaskOpacity", [mask, opacity]);
  },
  setMaskVisualization({ commit }, [mask, value]) {
    commit("setMaskVisualization", [mask, value]);
  },
  setMaskR3DVisualization({ commit }, [mask, value]) {
    commit("setMaskR3DVisualization", [mask, value]);
  },
  setMaskMinTreshold({ commit }, [mask, value]) {
    commit("setMaskMinTreshold", [mask, value]);
  },
  setMaskMaxTreshold({ commit }, [mask, value]) {
    commit("setMaskMaxTreshold", [mask, value]);
  },
  setMaskBrushDimension({ commit }, [mask, value]) {
    commit("setMaskBrushDimension", [mask, value]);
  }
};

const getters = {
  getMaskId: state => tag => {
    return state[tag].resourceId;
  },
  getMaskTag: state => id => {
    return findKey(state, ["resourceId", id]);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
