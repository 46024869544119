<template>
  <div class="np">
    <template v-if="availableTags.length > 0">
      <template v-for="tag in tags">
        <v-container
          class="bordered"
          v-bind:key="tag.id"
          v-show="tag.jobId || tag.resourceId"
        >
          <v-row class="row-dense">
            <v-col cols="5">
              <template v-if="tag.jobId != null">
                <font-awesome-icon class="v-center" icon="spinner" spin />
              </template>
              <template v-else>
                <v-btn-toggle
                  mandatory
                  dense
                  borderless
                  v-model="tag.visualization"
                  :color="tag.colorUI"
                  class="mt20"
                  :disabled="tag.jobId != null || isEditorActive"
                >
                  <!-- maschera piena -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        id="showFullMask"
                        icon
                        v-on="on"
                        fab
                        small
                        @click="fullMask(tag.id)"
                      >
                        <font-awesome-icon icon="circle" />
                      </v-btn>
                    </template>
                    <span>{{ $t("full") }}</span>
                  </v-tooltip>
                  <!-- maschera contorno -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        id="showContourMask"
                        icon
                        v-on="on"
                        fab
                        small
                        @click="contourMask(tag.id)"
                      >
                        <font-awesome-icon :icon="['far', 'circle']" />
                      </v-btn>
                    </template>
                    <span>{{ $t("contour") }}</span>
                  </v-tooltip>
                  <!-- maschera nascosta -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        id="hideMask"
                        icon
                        v-on="on"
                        fab
                        small
                        @click="invisibleMask(tag.id)"
                      >
                        <font-awesome-icon :icon="['far', 'eye-slash']" />
                      </v-btn>
                    </template>
                    <span>{{ $t("invisible") }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </template>
            </v-col>
            <v-col cols="5">
              <!-- opacità -->
              <v-slider
                class="mt5"
                min="0"
                max="1"
                step="0.1"
                :color="tag.colorUI"
                :track-color="tag.colorUI"
                :thumb-size="20"
                thumb-label="always"
                v-model="tag.opacity"
                @start="setActiveTag(tag)"
                @end="setOpacity"
                :disabled="
                  tag.jobId != null || tag.visualization != 0 || isEditorActive
                "
              ></v-slider>
            </v-col>
            <v-col cols="2" class="pl-0 pr-0">
              <!-- apri editor -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    id="openEditor"
                    icon
                    v-on="on"
                    :disabled="tag.jobId != null || isEditorActive"
                    @click="openEditor(tag)"
                  >
                    <font-awesome-icon icon="pencil-alt" />
                  </v-btn>
                </template>
                <span>{{ $t("editor") }}</span>
              </v-tooltip>
              <!-- cestino -->
              <template v-if="tag.manual">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      id="deleteManualSegmentation"
                      icon
                      v-on="on"
                      :disabled="tag.jobId != null || isEditorActive"
                      @click="deleteManualSegmentation(tag)"
                    >
                      <font-awesome-icon icon="trash" />
                    </v-btn>
                  </template>
                  <span>{{ $t("delete_manual_segmentation") }}</span>
                </v-tooltip>
              </template>
            </v-col>
          </v-row>
          <span> {{ tag.name }} </span>
        </v-container>
      </template>
    </template>
    <template v-else>
      <p class="text-center">
        {{ $t("no_segmentations") }}
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { each } from "lodash";
import {
  setMaskProps,
  setActiveLabelmap,
  enableBrushTool
} from "@/common/api.segmentation";

import { deleteSegmentation } from "@/common/api.jobs";

export default {
  name: "Segmentations",
  data() {
    return {
      activeTag: null
    };
  },
  props: ["phase"],
  computed: {
    ...mapGetters(["isEditorActive"]),
    ...mapState({
      tags(state) {
        let segmentationTags = state.segmentations.tags[this.phase];
        let segmentations = [];
        each(segmentationTags, function(tag) {
          segmentations.push(state.segmentations[tag]);
        });
        return segmentations;
      },
      opacity(state) {
        return this.activeTag && state.segmentations[this.activeTag]
          ? state.segmentations[this.activeTag].opacity
          : 0.5;
      }
    }),
    availableTags: function() {
      let availableTags = [];
      each(this.tags, function(tag) {
        if (tag.jobId || tag.resourceId) {
          availableTags.push(tag);
        }
      });
      return availableTags;
    }
  },
  watch: {
    opacity: function(v) {
      this.$store.dispatch("segmentations/setMaskOpacity", [this.activeTag, v]);
      let maskProps = this.$store.state.segmentations[this.activeTag];
      setMaskProps(maskProps);
    }
  },
  methods: {
    setOpacity: function(v) {
      this.$store.dispatch("segmentations/setMaskOpacity", [this.activeTag, v]);
      let maskProps = this.$store.state.segmentations[this.activeTag];
      setMaskProps(maskProps);
    },
    fullMask: function(id) {
      this.$store.dispatch("segmentations/setMaskVisualization", [id, 0]);
      let maskProps = this.$store.state.segmentations[id];
      setMaskProps(maskProps);
    },
    contourMask: function(id) {
      this.$store.dispatch("segmentations/setMaskVisualization", [id, 1]);
      let maskProps = this.$store.state.segmentations[id];
      setMaskProps(maskProps);
    },
    invisibleMask: function(id) {
      this.$store.dispatch("segmentations/setMaskVisualization", [id, 2]);
      let maskProps = this.$store.state.segmentations[id];
      setMaskProps(maskProps);
    },
    setActiveTag: function(item) {
      this.activeTag = item.id;
    },
    openEditor: function(item) {
      setActiveLabelmap(item.labelId, "axial");
      let currentLeftMouseHandler = this.$store.state.leftMouseHandler;
      this.$store.dispatch("setLastLeftMouseHandler", currentLeftMouseHandler);
      this.$store.dispatch("setLeftMouseHandler", "Brush");
      enableBrushTool(item.dimension, item.thresholds);
      this.$store.dispatch("segmentations/setActiveMask", [
        item.id,
        item.labelId
      ]);
    },
    deleteManualSegmentation: function(item) {
      confirm(
        "Are you sure you want to delete this segmentation? \nOnce the segmentation has been deleted it will be impossible to restore it."
      ) && this.deleteSegmentationMethod(item);
    },
    deleteSegmentationMethod: function(item) {
      let self = this;
      deleteSegmentation(item, function() {
        setTimeout(function() {
          self.$forceUpdate();
        }, 100);
      });
    }
  }
};
</script>

<style scoped>
.mt5 {
  margin-top: 5px;
}
.bordered {
  border: 1px ridge;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
}
.row-dense {
  height: 60px;
}
.title {
  text-align: center;
}
.v-center {
  vertical-align: -webkit-baseline-middle;
}
</style>
