<template>
  <div class="h100">
    <AccountsModal :token="token" />
    <Dashboard />
  </div>
</template>

<script>
import { mapState } from "vuex";

import AccountsModal from "@/components/modals/AccountsModal";
import Dashboard from "@/components/Dashboard";

export default {
  name: "Home",
  data() {
    return {};
  },
  mounted: function() {
    this.$store.dispatch("setCurrentPage", "home");
  },
  components: { AccountsModal, Dashboard },
  computed: {
    ...mapState({
      token(state) {
        return state.accessToken;
      }
    })
  }
};
</script>
