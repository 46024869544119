<template>
  <v-snackbar
    v-model="snackbar"
    rounded="pill"
    elevation="24"
    color="primary"
    timeout="-1"
  >
    {{ $t("registration_completed") }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" fab text v-bind="attrs" @click="snackbar = false">
        {{ $t("ok") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RecSnackbar",
  data() {
    return { snackbar: false };
  },
  computed: {
    ...mapState({
      registrationStatus(state) {
        return state.registrationStatus;
      }
    })
  },
  watch: {
    registrationStatus: function(status) {
      if (status == 2) {
        this.snackbar = true;
      }
    }
  }
};
</script>
