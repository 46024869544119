<template>
  <div class="mb5">
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          id="openAISegmentation"
          color="primary"
          class="text-btn"
          block
          v-on="{ ...tooltip }"
          @click="modal = !modal"
          :disabled="
            isSegmentationsUploading || isSegmentationsRunning || isEditorActive
          "
        >
          <font-awesome-icon
            icon="cog"
            pull="left"
            size="2x"
            :spin="isSegmentationsUploading || isSegmentationsRunning"
          />
          {{ $t("automatic") }}
        </v-btn>
      </template>
      <span>{{ $t("automatic_long") }}</span>
    </v-tooltip>
    <v-dialog v-model="modal" persistent width="60%">
      <v-card>
        <v-card-title class="headline justify-center" primary-title>
          {{ $t("drag_and_drop") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid class="h100">
            <v-row class="h300">
              <v-col cols="6" class="br1">
                <p class="text-center">{{ $t("available_segmentations") }}</p>
                <draggable
                  class="list-group h100"
                  tag="ul"
                  v-model="available_segmentations"
                  v-bind="dragOptions"
                  :move="onMove"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <v-list-item-group
                      color="primary"
                      v-for="item in available_segmentations"
                      :key="item.id"
                      @click="item.fixed = !item.fixed"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          {{ item.name }} - {{ item.phase }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list-item-group>
                  </transition-group>
                </draggable>
              </v-col>

              <v-col cols="6">
                <p class="text-center">{{ $t("selected_segmentations") }}</p>
                <draggable
                  element="span"
                  v-model="selected_segmentations"
                  v-bind="dragOptions"
                  :move="onMove"
                >
                  <transition-group name="no" class="list-group" tag="ul">
                    <v-list-item-group
                      color="primary"
                      v-for="item in selected_segmentations"
                      :key="item.id"
                      @click="item.fixed = !item.fixed"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          {{ item.name }} - {{ item.phase }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list-item-group>
                  </transition-group>
                </draggable>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <template v-if="segmentationMessage">
            <template v-if="segmentationMessage == 1">
              <p>{{ $t("warning_venous_na") }}</p>
            </template>
            <template v-if="segmentationMessage == 2">
              <p>{{ $t("warning_venous_rec") }}</p>
            </template>
            <template v-if="segmentationMessage == 3">
              <p>{{ $t("warning_arterial_rec") }}</p>
            </template>
            <template v-if="segmentationMessage == 4">
              <p>{{ $t("warning_arterial_na") }}</p>
            </template>
          </template>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-col cols="8">
            <template v-for="region in available_regions">
              <v-tooltip bottom :key="region">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    :id="region"
                    color="primary"
                    class="mr5 mb5"
                    large
                    v-on="{ ...tooltip }"
                    @click="selectRegion(region)"
                  >
                    <font-awesome-icon
                      size="2x"
                      icon="tasks"
                      pull="left"
                    ></font-awesome-icon>
                    {{ region }}
                  </v-btn>
                </template>
                <span
                  >{{ $t("select_all_segmentations_of") }} {{ region }}</span
                >
              </v-tooltip>
            </template>
          </v-col>

          <v-col cols="4" style="text-align: right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  id="selectAllAISegmentations"
                  color="primary"
                  class="mr5 mb5"
                  large
                  v-on="{ ...tooltip }"
                  @click="defaultOrder"
                >
                  <font-awesome-icon
                    size="2x"
                    icon="check-double"
                    pull="left"
                  ></font-awesome-icon>
                  {{ $t("all") }}
                </v-btn>
              </template>
              <span>{{ $t("all_long") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="red"
                  class="mr5 mb5"
                  large
                  v-on="{ ...tooltip }"
                  @click="undo"
                >
                  <font-awesome-icon
                    size="2x"
                    icon="undo"
                    pull="left"
                  ></font-awesome-icon>
                  {{ $t("undo") }}
                </v-btn>
              </template>
              <span>{{ $t("undo") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  id="runAISegmentations"
                  color="green"
                  class="mr5 mb5"
                  large
                  v-on="{ ...tooltip }"
                  @click="launch"
                >
                  <font-awesome-icon
                    size="2x"
                    icon="rocket"
                    pull="left"
                  ></font-awesome-icon>
                  {{ $t("run_segmentations") }}
                </v-btn>
              </template>
              <span>{{ $t("run_segmentations_long") }}</span>
            </v-tooltip>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { concat, filter, cloneDeep, differenceBy } from "lodash";
import { createJob } from "@/common/api.jobs";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Jobs",
  components: { draggable },
  data() {
    return {
      modal: false,
      item: 1,
      isDragging: false,
      delayedDragging: false,
      editable: true
    };
  },
  computed: {
    ...mapGetters([
      "isQuadviewReady",
      "isSegmentationDataLoading",
      "isSegmentationsUploading",
      "isSegmentationsRunning",
      "isEditorActive",
      "isRegistrationRunning"
    ]),
    ...mapState({
      arterialId(state) {
        return state.arterialId;
      },
      venousId(state) {
        return state.venousId;
      }
    }),
    segmentationMessage: function() {
      // 0: both phases are available
      // 1: venous phase is not available
      // 2: arterial phase is available and venous is under registration
      // 3: venous phase is available and arterial is under registration
      // 4: arterial phase is not available
      if (this.arterialId && !this.venousId) {
        if (this.isRegistrationRunning) {
          return 2;
        } else {
          return 1;
        }
      } else if (!this.arterialId && this.venousId) {
        if (this.isRegistrationRunning) {
          return 3;
        } else {
          return 4;
        }
      } else {
        return 0;
      }
    },
    available_segmentations: {
      get() {
        let segmentations = cloneDeep(
          this.$store.state.segmentations.default_segmentations
        );
        segmentations = differenceBy(
          segmentations,
          this.$store.state.segmentations.selected_segmentations,
          "id"
        );
        if (!this.arterialId && this.venousId) {
          // registration is on going and arterial is not available -> filter by venous phase
          // arterial is not available -> filter by venous phase
          return filter(segmentations, {
            phase: "venous"
          });
        } else if (
          this.arterialId &&
          !this.venousId &&
          this.isRegistrationRunning
        ) {
          // registration is on going and venous is not available -> filter by arterial phase
          return filter(segmentations, {
            phase: "arterial"
          });
        } else {
          // both phases are available -> all
          return segmentations;
        }
      },
      set(value) {
        this.$store.commit("segmentations/setAvailableSegmentations", value);
      }
    },
    selected_segmentations: {
      get() {
        return this.$store.state.segmentations.selected_segmentations;
      },
      set(value) {
        this.$store.commit("segmentations/setSelectedSegmentations", value);
      }
    },
    available_regions: function() {
      let segmentations = cloneDeep(
        this.$store.state.segmentations.default_segmentations
      );

      if (!this.arterialId && this.venousId) {
        // registration is on going and arterial is not available -> filter by venous phase
        // arterial is not available -> filter by venous phase
        segmentations = filter(segmentations, {
          phase: "venous"
        });
      }
      if (this.arterialId && !this.venousId && this.isRegistrationRunning) {
        // registration is on going and venous is not available -> filter by arterial phase
        segmentations = filter(segmentations, {
          phase: "arterial"
        });
      }

      const uniqueValues = segmentations
        .filter(obj => obj["region"] !== "") // Remove empty string values
        .map(obj => obj["region"]); // Extract the property values
      const uniqueSet = new Set(uniqueValues);
      const uniqueArray = [...uniqueSet]; // Convert the Set back to an array
      return uniqueArray;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    selectRegion(regionId) {
      let available_segmentations = cloneDeep(
        this.$store.state.segmentations.available_segmentations
      );
      let selected_segmentations = cloneDeep(
        this.$store.state.segmentations.selected_segmentations
      );

      // filter available segmentations by region
      let region_segmentations = filter(available_segmentations, {
        region: regionId
      });

      if (!this.arterialId && this.venousId) {
        // registration is on going and arterial is not available -> filter by venous phase
        // arterial is not available -> filter by venous phase
        region_segmentations = filter(region_segmentations, {
          phase: "venous"
        });
      }
      if (this.arterialId && !this.venousId && this.isRegistrationRunning) {
        // registration is on going and venous is not available -> filter by arterial phase
        region_segmentations = filter(region_segmentations, {
          phase: "arterial"
        });
      }

      // remove region segmentations from available segmentations
      available_segmentations = differenceBy(
        available_segmentations,
        region_segmentations,
        "id"
      );

      // add region segmentations to selected segmentations
      selected_segmentations = concat(
        this.$store.state.segmentations.selected_segmentations,
        region_segmentations
      );

      this.$store.dispatch(
        "segmentations/setAvailableSegmentations",
        available_segmentations
      );

      this.$store.dispatch(
        "segmentations/setSelectedSegmentations",
        selected_segmentations
      );
    },
    launch() {
      let self = this;
      createJob("arterial", function() {});
      createJob("venous", function() {
        let available_segmentations = concat(
          self.$store.state.segmentations.available_segmentations,
          self.$store.state.segmentations.selected_segmentations
        );
        self.$store.dispatch(
          "segmentations/setAvailableSegmentations",
          available_segmentations
        );
        self.$store.dispatch("segmentations/setSelectedSegmentations", []);
      });

      this.modal = false;
    },
    undo() {
      this.modal = false;
      let segmentations = cloneDeep(
        this.$store.state.segmentations.default_segmentations
      );
      this.$store.dispatch(
        "segmentations/setAvailableSegmentations",
        segmentations
      );
      this.$store.dispatch("segmentations/setSelectedSegmentations", []);
      this.$store.dispatch("setInitialized", true);
    },
    defaultOrder() {
      this.$store.dispatch("segmentations/setAvailableSegmentations", []);

      let segmentations = cloneDeep(
        this.$store.state.segmentations.default_segmentations
      );

      if (!this.arterialId && this.venousId) {
        // registration is on going and arterial is not available -> filter by venous phase
        // arterial is not available -> filter by venous phase
        segmentations = filter(segmentations, {
          phase: "venous"
        });
      }
      if (this.arterialId && !this.venousId && this.isRegistrationRunning) {
        // registration is on going and venous is not available -> filter by arterial phase
        segmentations = filter(segmentations, {
          phase: "arterial"
        });
      }
      this.$store.dispatch(
        "segmentations/setSelectedSegmentations",
        segmentations
      );
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    isQuadviewReady(ready) {
      if (ready && this.$store.state.initialized == false) {
        this.modal = true;
      }
    }
  }
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.br1 {
  border-right: 1px ridge;
}
.text-center {
  text-align: center;
}
.h400 {
  height: 400px;
}
.mb5 {
  margin-bottom: 5px;
}
.mr5 {
  margin-right: 5px;
}
.text-btn {
  font-size: 0.8vw !important;
}
</style>
