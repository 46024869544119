<template>
  <div class="h100">
    <div id="r3D-container" class="canvas3DContainer">
      <div :id="name" class="canvas3D">
        <div class="metadata-right">
          <p>
            <template v-if="caseName">
              Case: {{ caseName }}
              <br />
            </template>
          </p>
        </div>
        <v-speed-dial
          v-model="fab"
          bottom
          left
          direction="top"
          open-on-hover
          transition="slide-y-reverse-transition"
          class="floating-button"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="primary" dark fab>
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                v-on="on"
                color="primary"
                @click="centerCamera"
              >
                <v-icon>mdi-arrow-collapse-all</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("reset_camera") }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                v-on="on"
                color="primary"
                @click="resetColorMap"
              >
                <v-icon>mdi-chart-bell-curve</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("reset_colormap") }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                v-on="on"
                color="primary"
                @click="toggleGreenScreen"
              >
                <v-icon>mdi-camera-metering-center</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("toggle_green_screen") }}</span>
          </v-tooltip>
        </v-speed-dial>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { setCameraOnImage, resetWidget, destroy3d } from "@/common/api.vtk";
import {
  resetThreeJsCamera,
  toggleGreenMode,
  destroyThreeJsScene
} from "@/common/api.threejs";

export default {
  data() {
    return {
      fab: false
    };
  },
  props: ["name", "activeViewport", "viewer"],
  computed: {
    ...mapState({
      quadviewReady(state) {
        return state[this.viewer].ready;
      },
      caseName(state) {
        return state.caseName;
      }
    })
  },
  beforeDestroy: function() {
    if (this.$store.state.r3d.activePhase == "none") {
      destroyThreeJsScene();
    } else {
      destroy3d();
    }
  },
  watch: {},
  methods: {
    centerCamera() {
      if (this.$store.state.r3d.activePhase != "none") {
        setCameraOnImage(this.$store.state.r3d.activePhase);
      } else {
        resetThreeJsCamera();
      }
    },
    resetColorMap() {
      let activePhase = this.$store.state.r3d.activePhase;
      if (activePhase == "none") {
        return;
      }
      let originalInterval = this.$store.state.r3d[activePhase]
        .originalInterval;
      this.$store.dispatch("r3d/setMinMaxInterval", [
        activePhase,
        originalInterval[0],
        originalInterval[1]
      ]);
      resetWidget(activePhase);
    },
    toggleGreenScreen() {
      let greenScreen = this.$store.state.greenScreen;
      this.$store.dispatch("greenScreen", !greenScreen);
      if (this.$store.state.r3d.activePhase == "none") {
        toggleGreenMode(!greenScreen);
      }
    }
  }
};
</script>

<style scoped>
.canvas3DContainer {
  height: 100%;
  width: 100%;
}

.canvas3D {
  height: 100%;
  width: 100%;
}

.floating-button {
  position: absolute;
}

.metadata-right {
  font-size: 1vmax;
  text-align: right;
  color: white;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  z-index: 5;
  -webkit-font-smoothing: auto;
  right: 25%;
  top: 2%;
}
</style>
