<template>
  <v-container class="pa-0">
    <!-- phases toggle button group -->
    <v-row class="pa-0">
      <v-col cols="12" class="dense text-center">
        <v-btn-toggle v-model="activePhase" tile color="primary" group>
          <v-btn value="arterial" v-if="arterialId">
            {{ $t("arterial") }}
          </v-btn>
          <v-btn value="venous" v-if="venousId">
            {{ $t("venous") }}
          </v-btn>
          <v-btn value="none">
            {{ $t("none") }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- General opacity slider -->
    <div class="mb-2">{{ $t("opacity") }}</div>
    <v-card elevation="0" outlined class="pt-4 pb-0 px-1">
      <v-row class="ma-1">
        <v-col cols="4" class="pa-0 ma-0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="selected == 'general' ? 'primary' : 'white'"
                dense
                v-bind="attrs"
                v-on="on"
              >
                General
              </v-chip>
            </template>
            <span>Shortcut: &#9664; / &#9654; </span>
          </v-tooltip>
        </v-col>
        <!-- empty col, just for spacing -->
        <v-col cols="2" class="pa-0 ma-0"> </v-col>
        <v-col cols="6" class="pa-0 ma-0">
          <v-slider
            v-if="isNone"
            min="0.0"
            max="1.0"
            step="0.1"
            v-model="generalOpacity"
            hide-details
            thumb-label
            :color="invalid ? 'grey lighten-2' : 'black'"
            :track-color="invalid ? 'grey lighten-2' : ''"
            :track-fill-color="invalid ? 'grey lighten-2' : 'black'"
            @input="changeActiveModelsOpacity($event)"
            @click="forceBlur"
          >
          </v-slider>
        </v-col>
      </v-row>

      <!-- Single organ opacity sliders -->
      <template v-for="tag in tags">
        <v-row :key="tag.id" class="pa-0 mx-0 my-3">
          <v-col cols="4" class="pa-0 ma-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  :color="selected == tag.id ? 'primary' : 'white'"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ tag.name }}
                </v-chip>
              </template>
              <template v-if="tag.shortcut">
                <span
                  >Shortcut: {{ tag.shortcut.toUpperCase() }} + &#9664; /
                  &#9654;
                </span>
              </template>
              <template v-else>
                <span>No Shortcut </span>
              </template>
            </v-tooltip>
          </v-col>
          <v-col cols="2" class="pa-0 ma-0">
            <v-switch
              dense
              :color="tag.colorUI"
              @change="toggleModel(tag.id, tag.r3Dvisualization)"
              @click="forceBlur"
              v-model="tag.r3Dvisualization"
              class="mt-0 ml-2"
              hide-details
            >
            </v-switch>
          </v-col>
          <v-col cols="6" class="pa-0 ma-0">
            <v-slider
              v-if="isNone"
              min="0.0"
              max="1.0"
              step="0.1"
              v-model="tag.opacity"
              hide-details
              thumb-label
              :color="tag.colorUI"
              @input="changeModelOpacity(tag.id, $event)"
              :disabled="!tag.r3Dvisualization"
              @click="forceBlur"
              @mousedown="selected = tag.id"
              @mouseup="selected = 'general'"
            >
            </v-slider>
          </v-col>
        </v-row>
      </template>
    </v-card>

    <v-row class="pa-0">
      <!-- VR quality slider -->
      <v-col cols="12" class="pt-4 pb-1">
        <div class="mb-2">{{ $t("quality") }}</div>
        <v-slider
          min="0.0"
          max="1.0"
          step="0.1"
          :thumb-size="40"
          thumb-label
          prepend-icon="mdi-rotate-3d"
          v-model="quality"
          hide-details
          :disabled="isNone"
        ></v-slider>
      </v-col>

      <!-- VR brightness slider -->
      <v-col cols="12" class="pt-4 pb-1">
        <div class="mb-2">{{ $t("brightness") }}</div>
        <v-slider
          min="0.0"
          max="1.0"
          step="0.1"
          :thumb-size="40"
          thumb-label
          prepend-icon="mdi-lightbulb-on"
          v-model="brightness"
          hide-details
        ></v-slider>
      </v-col>

      <!-- VR LUT interval slider  -->
      <v-col cols="12" class="pt-4 pb-1">
        <div class="mb-2">{{ $t("lut_interval") }}</div>
        <v-range-slider
          dense
          :min="min"
          :max="max"
          step="10"
          prepend-icon="mdi-chart-histogram"
          :thumb-size="40"
          v-model="interval"
          thumb-label
          hide-details
          :disabled="isNone"
        ></v-range-slider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { each, union, find, debounce } from "lodash";

import {
  setVRQuality,
  setVRBrightness,
  toggleSurface,
  setLutInterval,
  setOpacity
} from "@/common/api.r3d";

import { changeActivePhase } from "@/common/api.r3d";

export default {
  name: "r3DPanel",
  data() {
    return {
      quality: 0.5,
      brightness: 0.5,
      generalOpacity: 0.5,
      invalid: true,
      selected: "general"
    };
  },
  mounted: function() {
    if (!this.keyListener) {
      document.onkeydown = debounce(this.onKeyDown, 100);
      document.onkeyup = debounce(this.onKeyUp, 100);
    }
  },
  // this is not triggered, why ?
  beforeUnmount: function() {
    document.onkeydown = null;
    document.onkeyup = null;
  },
  computed: {
    ...mapState({
      tags(state) {
        let arterialSegmentations = state.segmentations.tags["arterial"];
        let venousSegmentations = state.segmentations.tags["venous"];
        let segmentationTags = union(
          arterialSegmentations,
          venousSegmentations
        );
        let segmentations = [];
        each(segmentationTags, function(tag) {
          if (state.segmentations[tag].resourceId) {
            segmentations.push(state.segmentations[tag]);
          }
        });
        return segmentations;
      },
      min(state) {
        let activePhase = state.r3d.activePhase;
        let min =
          activePhase && activePhase !== "none"
            ? state.r3d[activePhase].imageRange[0]
            : 0;
        return min;
      },
      max(state) {
        let activePhase = state.r3d.activePhase;
        let max =
          activePhase && activePhase !== "none"
            ? state.r3d[activePhase].imageRange[1]
            : 1;
        return max;
      },
      arterialId(state) {
        return state.arterialId;
      },
      venousId(state) {
        return state.venousId;
      },
      isNone(state) {
        return state.r3d.activePhase == "none";
      }
    }),
    interval: {
      get() {
        let activePhase = this.$store.state.r3d.activePhase;
        let int =
          activePhase && activePhase !== "none"
            ? this.$store.state.r3d[activePhase].interval
            : [0, 1];
        return int;
      },
      set(value) {
        if (
          this.$store.state.r3d.activePhase &&
          this.$store.state.r3d.activePhase !== "none"
        ) {
          this.$store.commit("r3d/setMinMaxInterval", [
            this.$store.state.r3d.activePhase,
            value[0],
            value[1]
          ]);
        }
      }
    },
    activePhase: {
      get() {
        return this.$store.state.r3d.activePhase;
      },
      set(value) {
        this.$store.commit("r3d/setActivePhase", value);
        changeActivePhase(value);
      }
    }
  },
  methods: {
    forceBlur: function() {
      document.activeElement.blur();
    },
    toggleModel: function(model, isVisible) {
      toggleSurface(model, isVisible);
    },
    changeModelOpacity: function(tag, value) {
      this.invalid = true;
      setOpacity(tag, value);
    },
    changeActiveModelsOpacity: function(value) {
      this.invalid = false;
      this.tags.forEach(tag => {
        if (this.$store.state.segmentations[tag.id].r3Dvisualization) {
          setOpacity(tag.id, value);
        }
      });
    },
    onKeyDown: function(evt) {
      let selectedTag = find(this.tags, ["shortcut", evt.key]);

      if (!this.isNone) {
        return;
      }

      if (this.selected !== "general") {
        let tag = find(this.tags, ["id", this.selected]);
        if (evt.key == "ArrowRight") {
          let currentOpacity = this.$store.state.segmentations[tag.id].opacity;
          currentOpacity += 0.1;
          this.changeModelOpacity(this.selected, currentOpacity);
        }
        if (evt.key == "ArrowLeft") {
          let currentOpacity = this.$store.state.segmentations[tag.id].opacity;
          currentOpacity -= 0.1;
          this.changeModelOpacity(this.selected, currentOpacity);
        }

        // just a try with mouse events, but vtk.js does not propagate events
        // document.addEventListener("mousewheel", mouseEvt => {
        //   let currentOpacity = this.$store.state.segmentations[tag.id].opacity;
        //   currentOpacity = currentOpacity + 0.001 * Math.sign(mouseEvt.deltaY);
        //   this.changeModelOpacity(this.selected, currentOpacity);
        // });
      } else if (selectedTag) {
        this.selected = selectedTag.id;
      } else {
        if (evt.key == "ArrowRight") {
          this.generalOpacity += 0.1;
          this.changeActiveModelsOpacity(this.generalOpacity);
        }
        if (evt.key == "ArrowLeft") {
          this.generalOpacity -= 0.1;
          this.changeActiveModelsOpacity(this.generalOpacity);
        }
      }
    },
    onKeyUp: function(evt) {
      if (evt.key == "ArrowRight" || evt.key == "ArrowLeft") {
        return;
      }

      this.selected = "general";
    }
  },
  watch: {
    quality: function(v) {
      setVRQuality(v);
    },
    brightness: function(v) {
      let v_ = v + 0.5;
      setVRBrightness(v_); // range 0.5 - 1.5
    },
    interval: function(v) {
      if (
        this.$store.state.r3d.activePhase &&
        this.$store.state.r3d.activePhase != "none"
      ) {
        setLutInterval(this.$store.state.r3d.activePhase, v);
      }
    }
  }
};
</script>

<style scoped>
.dense {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
