<template>
  <v-dialog
    v-model="active"
    width="47%"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card flat>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-alert normal type="error">
          {{ $t("ai_error") }}
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("ai_error_accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      active: false
    };
  },
  computed: {
    ...mapState({
      aiError(state) {
        return state.aiError;
      }
    })
  },
  watch: {
    aiError: function(value) {
      if (value) {
        this.active = true;
      }
    }
  },
  methods: {
    closeDialog: function() {
      this.$store.dispatch("setAiError", false);
      this.active = false;
    }
  }
};
</script>

<style scoped></style>
