function initialState() {
  return {
    activePhase: "none",
    arterial: {
      originalInterval: [0, 0],
      imageRange: [0, 0],
      interval: [0, 0]
    },
    venous: {
      originalInterval: [0, 0],
      imageRange: [0, 0],
      interval: [0, 0]
    }
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setActivePhase(state, value) {
    state.activePhase = value;
  },
  setOriginalInterval(state, [phase, min, max]) {
    state[phase].originalInterval = [min, max];
  },
  setImageRange(state, [phase, min, max]) {
    state[phase].imageRange = [min, max];
  },
  setMinMaxInterval(state, [phase, min, max]) {
    state[phase].interval = [min, max];
  }
};

const actions = {
  setActivePhase({ commit }, value) {
    commit("setActivePhase", value);
  },
  setOriginalInterval({ commit }, [phase, min, max]) {
    commit("setOriginalInterval", [phase, min, max]);
  },
  setImageRange({ commit }, [phase, min, max]) {
    commit("setImageRange", [phase, min, max]);
  },
  setMinMaxInterval({ commit }, [phase, min, max]) {
    commit("setMinMaxInterval", [phase, min, max]);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
