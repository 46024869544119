<template>
  <div>
    <v-navigation-drawer
      v-model="sheet"
      floating
      absolute
      hide-overlay
      fixed
      stateless
    >
      <div class="text-center h100">
        <v-sheet class="text-center h100">
          <v-btn class="mt-6" icon text color="primary" @click="save">
            <font-awesome-icon icon="save" pull="left" size="lg" />
            {{ $t("close") }}
          </v-btn>
          <TextAreaNotes />
        </v-sheet>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { saveCaseNotes } from "@/common/api.cases";
import TextAreaNotes from "@/components/TextAreaNotes";

export default {
  name: "Notes",
  data() {
    return {};
  },
  components: { TextAreaNotes },
  computed: {
    sheet: {
      get() {
        return this.$store.state.showNotes;
      },
      set(value) {
        this.$store.commit("showNotes", value);
      }
    }
  },
  methods: {
    save: function() {
      this.$store.dispatch("showNotes", false);
      saveCaseNotes(function(resp) {
        console.log(resp);
      });
    }
  }
};
</script>

<style scoped></style>
