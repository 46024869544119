<template>
  <div class="h100">
    <v-container fluid class="pa-0 fill-height bg-color-black">
      <v-row class="h100">
        <v-col cols="9" class="h100" :style="{ background: bkgColor }">
          <template v-if="this.$store.state.quadview.activeViewport == 'axial'">
            <canvas2D name="axial" viewer="quadview" activeViewport="axial" />
          </template>
          <template v-else>
            <canvas3D name="r3D" viewer="quadview" activeViewport="r3D" />
          </template>
        </v-col>
        <v-col cols="3" class="h100 bg-color-white pa-0">
          <SidePanel class="pa-2 mr-3" />
        </v-col>
      </v-row>
    </v-container>
    <v-overlay class="center-text" :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p class="log">{{ msgLog }}</p>
    </v-overlay>
    <Notes />
    <RecErrorModal />
    <AiErrorModal />
    <RecSnackbar />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Canvas2D from "@/components/Canvas2D";
import Canvas3D from "@/components/Canvas3D";
import SidePanel from "@/components/SidePanel.vue";
import Notes from "@/components/Notes";
import RecErrorModal from "@/components/modals/RecErrorModal";
import AiErrorModal from "@/components/modals/AiErrorModal";
import RecSnackbar from "@/components/RecSnackbar";

import {
  addDefaultTools,
  importAnnotationData,
  startListeningCSTools
} from "@/common/api.images";
import { setToolActive } from "@/larvitar/index";
import { initSegmentationModule } from "@/common/api.segmentation";

import "@/common/api.jobs.js";

export default {
  name: "Viewer",
  data() {
    return {
      tools: false,
      rec_error: false,
      bkgColor: "rgb(0, 0, 0)"
    };
  },
  mounted: function() {
    this.$store.dispatch("setCurrentPage", "viewer");
  },
  components: {
    Canvas2D,
    Canvas3D,
    SidePanel,
    Notes,
    RecErrorModal,
    AiErrorModal,
    RecSnackbar
  },
  computed: {
    ...mapGetters([
      "isQuadviewReady",
      "isSegmentationDataLoading",
      "greenScreen"
    ]),
    ...mapState({
      msgLog(state) {
        return state.msgLog;
      },
      toolsInitialized(state) {
        return state.toolsInitialized;
      }
    }),
    isLoading: function() {
      if (
        this.isQuadviewReady === true &&
        this.isSegmentationDataLoading === false
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    isQuadviewReady: function(ready) {
      if (ready && !this.toolsInitialized) {
        let currentActiveTool = this.$store.state["leftMouseHandler"];
        addDefaultTools("axial");
        let activePhase = this.$store.state.activePhase;
        let activeImageId = this.$store.state.imageIds[activePhase];
        importAnnotationData(activeImageId, function() {
          setToolActive(currentActiveTool);
        });
        startListeningCSTools("axial");
        initSegmentationModule();
        this.$store.dispatch("setToolsInitialized", true);
      }
    },
    greenScreen: function(bool) {
      this.bkgColor = bool ? "rgb(4, 254, 4)" : "rgb(0, 0, 0)";
    }
  }
};
</script>
