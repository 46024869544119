<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="50%"
    :fullscreen="preview"
    class="darkerBackground"
  >
    <template v-slot:activator="{ on: modal }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip, ...modal }">
            <font-awesome-icon size="2x" icon="folder-plus"></font-awesome-icon>
          </v-btn>
        </template>
        <span>{{ $t("new_case") }}</span>
      </v-tooltip>
    </template>
    <template v-if="preview">
      <ImagePreview />
    </template>
    <template v-else>
      <v-card>
        <v-card-title primary-title>
          {{ $t("load_files") }}
        </v-card-title>

        <v-card-text>
          <v-file-input
            prepend-icon="mdi-folder"
            v-model="files"
            :disabled="saving"
            show-size
            counter
            multiple
            webkitdirectory
            :label="$t('load_dicom')"
            @change="onFilesChange"
          ></v-file-input>
        </v-card-text>

        <v-card-text>
          <v-file-input
            prepend-icon="mdi-image"
            v-model="nrrd"
            :disabled="saving"
            show-size
            counter
            multiple
            accept=".nrrd"
            :label="$t('load_nrrd')"
            @change="onFileChange"
          ></v-file-input>
        </v-card-text>

        <v-alert v-if="error" normal :icon="false" type="error">
          {{ errorLog }}
        </v-alert>

        <p class="center-text">{{ msgLog }}</p>

        <v-progress-linear
          :active="saving"
          indeterminate
          striped
          color="primary"
        >
        </v-progress-linear>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="saving" @click="close"
            >{{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="saving || filesNotReady || error"
            @click="load"
            >{{ $t("load") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { resetImageAPI } from "@/common/api.images";
import { previewDICOM, previewNRRD } from "@/common/api.preview";
import ImagePreview from "@/components/ImagePreview";

export default {
  name: "ImagesModal",
  components: { ImagePreview },
  data() {
    return {
      dialog: false,
      files: [],
      nrrd: [],
      fileType: null,
      error: false,
      saving: false
    };
  },
  computed: {
    ...mapState({
      preview(state) {
        return state.preview;
      },
      seriesId(state) {
        return state.seriesId;
      },
      msgLog(state) {
        return state.msgLog;
      },
      errorLog(state) {
        return state.errorLog;
      },
      newCase(state) {
        return state.newCase;
      }
    }),
    filesNotReady: function() {
      return this.files.length == 0 && this.nrrd.length == 0 ? true : false;
    }
  },
  methods: {
    getModalColor: function() {
      return this.preview ? "darkerBackground" : "";
    },
    onFilesChange(files) {
      this.files = files;
      this.nrrd = [];
      this.fileType = "DICOM";
      this.error = false;
    },
    onFileChange(files) {
      this.files = [];
      this.nrrd = files;
      this.fileType = "NRRD";
      this.error = false;
    },
    load() {
      resetImageAPI();
      this.$store.dispatch("setPreview", false);
      this.$store.dispatch("setSeriesId", null);
      this.$store.dispatch("setSeries", []);
      let self = this;
      this.saving = true;
      if (this.fileType == "DICOM") {
        previewDICOM(this.files, function(err) {
          if (err) {
            self.saving = false;
            self.error = true;
          } else {
            self.$store.dispatch("setNrrdSeries", false);
            self.saving = false;
            self.error = false;
          }
        });
      } else {
        previewNRRD(this.nrrd, function(err) {
          if (err) {
            self.saving = false;
            self.error = true;
          } else {
            self.$store.dispatch("setNrrdSeries", true);
            self.saving = false;
            self.error = false;
          }
        });
      }
      this.files = [];
      this.nrrd = [];
      this.fileType = null;
      this.error = false;
    },
    close() {
      this.dialog = false;
      this.files = [];
      this.nrrd = [];
      this.fileType = null;
      this.error = false;
    }
  },
  watch: {
    newCase: function(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.close();
      }
    }
  }
};
</script>

<style scoped>
.darkerBackground {
  background-color: black !important;
}
.theme--light.v-application {
  background: black !important;
}
</style>
