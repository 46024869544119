<template>
  <div class="h100">
    <template v-if="!isEditorActive">
      <div>
        <Jobs />
      </div>

      <div>
        <NewSegmentation />
      </div>

      <v-expansion-panels class="pt-2" v-model="panel">
        <v-expansion-panel
          key="arterial"
          :disabled="!arterialId || isEditorActive"
        >
          <v-expansion-panel-header @click="changePhase('arterial')">
            {{ $t("arterial_phase") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mh510">
            <PhaseSegmentations phase="arterial" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel key="venous" :disabled="!venousId || isEditorActive">
          <v-expansion-panel-header
            @click="changePhase('venous')"
            activeClass="primary"
          >
            {{ $t("venous_phase") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mh510">
            <PhaseSegmentations phase="venous" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <div>
      <Editor />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Jobs from "@/components/panels/Jobs.vue";
import Editor from "@/components/panels/Editor.vue";
import PhaseSegmentations from "@/components/panels/PhaseSegmentations.vue";
import NewSegmentation from "@/components/panels/NewSegmentation.vue";

import { clearImageCache } from "@/larvitar/index";

import { renderNrrdImage, disableImage } from "@/common/api.images";
import { importSegmentations } from "@/common/api.jobs";

export default {
  name: "Segmentations",
  data() {
    return {
      panel: null,
      oldPanel: null
    };
  },
  components: { Jobs, PhaseSegmentations, Editor, NewSegmentation },
  computed: {
    ...mapGetters(["isEditorActive"]),
    ...mapState({
      activePhase(state) {
        return state.activePhase;
      },
      arterialId(state) {
        return state.arterialId;
      },
      venousId(state) {
        return state.venousId;
      }
    })
  },
  mounted() {
    this.panel = this.activePhase == "arterial" ? 0 : 1;
  },
  watch: {
    activePhase: function(newV) {
      if (newV) {
        this.panel = newV == "arterial" ? 0 : 1;
      }
    },
    isEditorActive: function(active) {
      if (active) {
        this.oldPanel = this.panel;
        this.panel = null;
      } else {
        this.panel = this.oldPanel;
        this.oldPanel = null;
      }
    }
  },
  methods: {
    changePhase: function(phase) {
      let serieUID =
        phase == "arterial"
          ? this.$store.state.arterialId
          : this.$store.state.venousId;

      if (this.$store.state.seriesId == serieUID) {
        return;
      }
      let self = this;
      let sliceNumber = this.$store.state.quadview.axial.sliceId;
      let ww = this.$store.state.quadview.axial.viewport.voi.windowWidth;
      let wc = this.$store.state.quadview.axial.viewport.voi.windowCenter;
      let scale = this.$store.state.quadview.axial.viewport.scale;
      let tr_x = this.$store.state.quadview.axial.viewport.translation.x;
      let tr_y = this.$store.state.quadview.axial.viewport.translation.y;
      // render selected serie
      clearImageCache();
      this.$store.dispatch("setSeriesId", serieUID);
      if (serieUID == this.$store.state.arterialId) {
        this.$store.dispatch("setActivePhase", "arterial");
      } else {
        this.$store.dispatch("setActivePhase", "venous");
      }
      this.$store.dispatch("setToolsInitialized", false);
      disableImage("axial");
      let defaultProps = {
        sliceNumber: sliceNumber,
        ww: ww,
        wc: wc,
        scale: scale,
        tr_x: tr_x,
        tr_y: tr_y,
        defaultWW: 600.0,
        defaultWC: 40.0
      };
      let segmentationIds = self.$store.state.segmentationIds[
        self.$store.state.activePhase
      ].slice();
      renderNrrdImage("axial", defaultProps, () => {
        importSegmentations(segmentationIds, () => {
          self.$store.dispatch("setMsgLog", "");
          self.panel = phase == "arterial" ? 0 : 1;
        });
      });
    }
  }
};
</script>

<style scoped>
.mt30 {
  margin-top: 30px;
}
.mh510 {
  max-height: 510px;
  overflow-y: auto;
}
</style>
