var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"np"},[(_vm.availableTags.length > 0)?[_vm._l((_vm.tags),function(tag){return [_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(tag.jobId || tag.resourceId),expression:"tag.jobId || tag.resourceId"}],key:tag.id,staticClass:"bordered"},[_c('v-row',{staticClass:"row-dense"},[_c('v-col',{attrs:{"cols":"5"}},[(tag.jobId != null)?[_c('font-awesome-icon',{staticClass:"v-center",attrs:{"icon":"spinner","spin":""}})]:[_c('v-btn-toggle',{staticClass:"mt20",attrs:{"mandatory":"","dense":"","borderless":"","color":tag.colorUI,"disabled":tag.jobId != null || _vm.isEditorActive},model:{value:(tag.visualization),callback:function ($$v) {_vm.$set(tag, "visualization", $$v)},expression:"tag.visualization"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"showFullMask","icon":"","fab":"","small":""},on:{"click":function($event){return _vm.fullMask(tag.id)}}},on),[_c('font-awesome-icon',{attrs:{"icon":"circle"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("full")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"showContourMask","icon":"","fab":"","small":""},on:{"click":function($event){return _vm.contourMask(tag.id)}}},on),[_c('font-awesome-icon',{attrs:{"icon":['far', 'circle']}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("contour")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"hideMask","icon":"","fab":"","small":""},on:{"click":function($event){return _vm.invisibleMask(tag.id)}}},on),[_c('font-awesome-icon',{attrs:{"icon":['far', 'eye-slash']}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("invisible")))])])],1)]],2),_c('v-col',{attrs:{"cols":"5"}},[_c('v-slider',{staticClass:"mt5",attrs:{"min":"0","max":"1","step":"0.1","color":tag.colorUI,"track-color":tag.colorUI,"thumb-size":20,"thumb-label":"always","disabled":tag.jobId != null || tag.visualization != 0 || _vm.isEditorActive},on:{"start":function($event){return _vm.setActiveTag(tag)},"end":_vm.setOpacity},model:{value:(tag.opacity),callback:function ($$v) {_vm.$set(tag, "opacity", $$v)},expression:"tag.opacity"}})],1),_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"openEditor","icon":"","disabled":tag.jobId != null || _vm.isEditorActive},on:{"click":function($event){return _vm.openEditor(tag)}}},on),[_c('font-awesome-icon',{attrs:{"icon":"pencil-alt"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("editor")))])]),(tag.manual)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"deleteManualSegmentation","icon":"","disabled":tag.jobId != null || _vm.isEditorActive},on:{"click":function($event){return _vm.deleteManualSegmentation(tag)}}},on),[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete_manual_segmentation")))])])]:_vm._e()],2)],1),_c('span',[_vm._v(" "+_vm._s(tag.name)+" ")])],1)]})]:[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("no_segmentations"))+" ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }