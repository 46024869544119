import request from "superagent";
import store from "@/store/index";
import { verify_and_refresh } from "@/common/api.users";
import { concat, each } from "lodash";

import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";

var scene;
var controls;
var requestId;
var renderer;
var rendered = false;
const cameraOffset = 400;

export function buildAndLoadThreeJsScene(divId) {
  // setup scene
  setupScene(divId);
  // load stl
  let tags = [];
  tags = concat(store.state.segmentations.tags.venous, tags);
  tags = concat(store.state.segmentations.tags.arterial, tags);
  loadSurfaces(tags, () => {
    setTimeout(function() {
      store.dispatch("quadview/setLoadingStatus", ["r3D", true]);
    }, 200);
  });
  // render stl
}

// ==========================================
// Init and setup scene 3D ==================
// ==========================================
function setupScene(divId) {
  const canvasWidth = document.getElementById(divId).offsetWidth;
  const canvasHeight = document.getElementById(divId).offsetHeight;

  // new scene
  scene = new THREE.Scene();

  // camera
  const camera = new THREE.PerspectiveCamera(
    45,
    canvasWidth / canvasHeight,
    0.1,
    1000
  );
  camera.name = "camera";
  camera.up = new THREE.Vector3(0, 0, 1);
  camera.updateProjectionMatrix();
  scene.add(camera);

  // lights
  const ambientLight = new THREE.AmbientLight("#ffffff", 1.5);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight("#ffffff", 2);
  directionalLight.castShadow = true;
  directionalLight.shadow.camera.near = 1;
  directionalLight.shadow.camera.far = 30;
  directionalLight.shadow.mapSize.set(1024, 1024);
  directionalLight.shadow.normalBias = 0.05;
  directionalLight.position.set(
    camera.position.x,
    camera.position.y,
    camera.position.z
  );
  directionalLight.name = "directionalLight";
  camera.add(directionalLight);

  directionalLight.target = new THREE.Object3D();
  directionalLight.target.position.set(0, 2, 0);
  scene.add(directionalLight.target);

  // renderer
  renderer = new THREE.WebGLRenderer({
    antialias: true,
    powerPreference: "high-performance"
  });
  renderer.setSize(canvasWidth, canvasHeight);
  renderer.setClearColor(0x000000, 1);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  // renderer.sortObjects = false;
  document.getElementById(divId).appendChild(renderer.domElement);

  // controls
  controls = new TrackballControls(camera, renderer.domElement);
  controls.rotateSpeed = 5.0;
  controls.zoomSpeed = 2.0;
  controls.panSpeed = 1.5;
  controls.noZoom = false;
  controls.noPan = false;
  controls.staticMoving = false;
  controls.dynamicDampingFactor = 0.3;
  controls.cylindricalRotation = true;
  controls.keys = [null, null, 17]; // [rotate[no button], zoom[no button], pan[shift]

  window.addEventListener("resize", () => {
    // Update sizes
    const width = document.getElementById("r3D").offsetWidth;
    const height = document.getElementById("r3D").offsetHeight;

    // Update camera
    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(width, height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    controls.handleResize();
  });

  const tick = () => {
    // Update controls
    controls.update();
    // Render
    renderer.render(scene, camera);
    // Call tick again on the next frame
    requestId = window.requestAnimationFrame(tick);
  };
  tick();
}

// ==========================================
// Init and setup scene 3D ==================
// ==========================================
export function destroyThreeJsScene() {
  if (requestId) {
    window.cancelAnimationFrame(requestId);
    requestId = null;
  }
  scene = null;
  controls = null;
  rendered = false;
  if (renderer) {
    let div = document.getElementById("r3D");
    if (div) {
      div.removeChild(renderer.domElement);
    }
    renderer = null;
  }
}

function loadSurfaces(tags, cb) {
  let nextTag = tags.pop();
  if (nextTag) {
    if (store.state.segmentations[nextTag].surfacePath) {
      let surface = store.state.segmentations[nextTag].surfacePath;
      verify_and_refresh(function() {
        request
          .get(surface)
          .responseType("arraybuffer")
          .set("Authorization", "Bearer " + store.state.accessToken)
          .then(function(resp) {
            // if not on current phase, set visibility to 0
            const visible = nextTag == "pancreas_a" ? 0 : 1;
            store.dispatch("segmentations/setMaskR3DVisualization", [
              nextTag,
              visible
            ]);
            const props = {
              tag: nextTag,
              visible: visible,
              opacity: store.state.segmentations[nextTag].opacity,
              color: store.state.segmentations[nextTag].color
            };
            const data = ensureString(resp.body);
            let geometry = parseASCII(data);
            loadSurface(geometry, props);
            loadSurfaces(tags, cb);
          });
      });
    } else {
      console.log("No Mask Surface Path for ", nextTag);
      loadSurfaces(tags, cb);
    }
  } else {
    cb();
  }
}

export function setThreeJsLights(value) {
  let light = scene.getObjectByName("directionalLight");
  light.intensity = value * 6;
}

// ============================================
// Toggle mesh surface visibility =============
// ============================================
export function toggleSurfaceThreeJs(tag, toggle) {
  let mesh = scene.getObjectByName(tag);
  mesh.visible = toggle;
}

// ============================================
// Change mesh surface opacity ================
// ============================================
export function setOpacityThreeJs(tag, opacityValue) {
  let mesh = scene.getObjectByName(tag);
  mesh.material.opacity = opacityValue;
  mesh.material.transparent = opacityValue === 1.0 ? false : true;
  mesh.visible = opacityValue == 0.0 ? false : true;
}

// ============================================
// Reset Threejs camera =======================
// ============================================
export function resetThreeJsCamera() {
  if (scene) {
    var camera = scene.getObjectByName("camera");
    var directionalLight = scene.getObjectByName("directionalLight");
    var geometries = [];
    each(scene.children, function(v) {
      if (
        v.name != "camera" &&
        v.name != "ambientLight" &&
        v.name != "directionalLight" &&
        v.name != "" &&
        v.visible === true
      ) {
        geometries.push(v.geometry);
      }
    });
    let boundingBox = getGeometryCentroid(geometries);
    camera.lookAt(boundingBox.center);
    directionalLight.target.position.set(
      boundingBox.center.x,
      boundingBox.center.y,
      boundingBox.center.z
    );
    controls.reset();
    camera.position.x = boundingBox.center.x;
    camera.position.y = boundingBox.center.y - cameraOffset;
    camera.position.z = boundingBox.center.z;
  }
}

// ============================================
// Toggle Green Mode ON/OFF ===================
// ============================================
export function toggleGreenMode(toggle) {
  if (toggle) {
    renderer.setClearColor(0x04fe04, 1); // RGB 4, 254, 4
  } else {
    renderer.setClearColor(0x000000, 1);
  }
}

// ============================================
// Load and render mesh surface ===============
// ============================================
function loadSurface(geometry, props) {
  const material = new THREE.MeshStandardMaterial({
    side: THREE.DoubleSide,
    color: props.color,
    roughness: 0.75,
    metalness: 0.25,
    opacity: props.opacity,
    transparent: props.opacity === 1.0 ? false : true
  });
  const mesh = new THREE.Mesh(geometry, material);
  mesh.castShadow = true;
  mesh.receiveShadow = true;
  mesh.name = props.tag;
  mesh.visible = props.visible === 1 ? true : false;
  switch (mesh.name) {
    case "liver":
      mesh.renderOrder = 3;
      break;
    case "kidney":
      mesh.renderOrder = 3;
      break;
    case "spleen":
      mesh.renderOrder = 3;
      break;
    case "pancreas_a":
      mesh.renderOrder = 2;
      break;
    case "pancreas_v":
      mesh.renderOrder = 2;
      break;
    case "artery":
      mesh.renderOrder = 1;
      break;
    case "svs":
      mesh.renderOrder = 1;
      break;
    case "renalvein":
      mesh.renderOrder = 1;
      break;
    case "hepvessel":
      mesh.renderOrder = 1;
      break;
    case "vein":
      mesh.renderOrder = 0;
      break;

    default:
      mesh.renderOrder = 1;
      break;
  }

  if (!rendered) {
    centerCameraOnObject(mesh);
    rendered = true;
  }
  scene.add(mesh);
}

// ============================================
// Get center of geometry(s) ==================
// ============================================
function getGeometryCentroid(geometries) {
  let minX, minY, minZ, maxX, maxY, maxZ, geometryFromArray, boundingBox;
  let center = new THREE.Vector3();
  let min = new THREE.Vector3();
  let max = new THREE.Vector3();
  if (geometries.length == 1) {
    geometryFromArray = geometries[0];
    geometryFromArray.computeBoundingBox();
    boundingBox = geometryFromArray.boundingBox;
    boundingBox.getCenter(center);
    minX = boundingBox.min.x;
    minY = boundingBox.min.y;
    minZ = boundingBox.min.z;
    maxX = boundingBox.max.x;
    maxY = boundingBox.max.y;
    maxZ = boundingBox.max.z;
    min = new THREE.Vector3(minX, minY, minZ);
    max = new THREE.Vector3(maxX, maxY, maxZ);
  } else {
    for (let i = 0; i < geometries.length; i++) {
      geometryFromArray = geometries[i];
      if (geometryFromArray) {
        geometryFromArray.computeBoundingBox();
        boundingBox = geometryFromArray.boundingBox;
        if (i == 0) {
          minX = boundingBox.min.x;
          minY = boundingBox.min.y;
          minZ = boundingBox.min.z;
          maxX = boundingBox.max.x;
          maxY = boundingBox.max.y;
          maxZ = boundingBox.max.z;
        } else {
          if (boundingBox.min.x < minX) {
            minX = boundingBox.min.x;
          }
          if (boundingBox.min.y < minY) {
            minY = boundingBox.min.y;
          }
          if (boundingBox.min.z < minZ) {
            minZ = boundingBox.min.z;
          }
          if (boundingBox.max.x > maxX) {
            maxX = boundingBox.max.x;
          }
          if (boundingBox.max.x > maxY) {
            maxY = boundingBox.max.y;
          }
          if (boundingBox.max.x > maxX) {
            maxZ = boundingBox.max.z;
          }
        }
        min = new THREE.Vector3(minX, minY, minZ);
        max = new THREE.Vector3(maxX, maxY, maxZ);
        center = new THREE.Vector3();
        center.addVectors(min, max).multiplyScalar(0.5);
      }
    }
  }
  return { center: center, min: min, max: max };
}

// ============================================
// Set centroId to controls ===================
// ============================================
function setCentroid(controls, centroid) {
  if (controls) {
    controls.target.copy(centroid);
    controls.target0.copy(centroid);
  }
  return controls;
}

// ============================================
// Center camera on object ====================
// ============================================
function centerCameraOnObject(threeObj) {
  if (!(threeObj instanceof THREE.Mesh)) {
    threeObj = scene.getObjectByName(threeObj);
  }
  if (threeObj) {
    let boundingBox = getGeometryCentroid([threeObj.geometry]);
    let camera = scene.getObjectByName("camera");
    let directionalLight = scene.getObjectByName("directionalLight");
    camera.lookAt(boundingBox.center);
    directionalLight.target.position.set(
      boundingBox.center.x,
      boundingBox.center.y,
      boundingBox.center.z
    );
    setCentroid(controls, boundingBox.center);
    camera.position.x = boundingBox.center.x;
    camera.position.y = boundingBox.center.y - cameraOffset;
    camera.position.z = boundingBox.center.z;
  }
}

function decodeText(array) {
  if (typeof TextDecoder !== "undefined") {
    return new TextDecoder().decode(array);
  }

  // Avoid the String.fromCharCode.apply(null, array) shortcut, which
  // throws a "maximum call stack size exceeded" error for large arrays.

  let s = "";

  for (let i = 0, il = array.length; i < il; i++) {
    // Implicitly assumes little-endian.
    s += String.fromCharCode(array[i]);
  }

  try {
    // merges multi-byte utf-8 characters.

    return decodeURIComponent(escape(s));
  } catch (e) {
    // see #16358

    return s;
  }
}
function ensureString(buffer) {
  if (typeof buffer !== "string") {
    return decodeText(new Uint8Array(buffer));
  }
  return buffer;
}

function parseASCII(data) {
  const geometry = new THREE.BufferGeometry();
  const patternSolid = /solid([\s\S]*?)endsolid/g;
  const patternFace = /facet([\s\S]*?)endfacet/g;
  let faceCounter = 0;
  const patternFloat = /[\s]+([+-]?(?:\d*)(?:\.\d*)?(?:[eE][+-]?\d+)?)/.source;
  const patternVertex = new RegExp(
    "vertex" + patternFloat + patternFloat + patternFloat,
    "g"
  );
  const patternNormal = new RegExp(
    "normal" + patternFloat + patternFloat + patternFloat,
    "g"
  );
  const vertices = [];
  const normals = [];
  const normal = new THREE.Vector3();
  let result;
  let groupCount = 0;
  let startVertex = 0;
  let endVertex = 0;

  while ((result = patternSolid.exec(data)) !== null) {
    startVertex = endVertex;
    const solid = result[0];

    while ((result = patternFace.exec(solid)) !== null) {
      let vertexCountPerFace = 0;
      let normalCountPerFace = 0;
      const text = result[0];

      while ((result = patternNormal.exec(text)) !== null) {
        normal.x = parseFloat(result[1]);
        normal.y = parseFloat(result[2]);
        normal.z = parseFloat(result[3]);
        normalCountPerFace++;
      }

      while ((result = patternVertex.exec(text)) !== null) {
        vertices.push(
          parseFloat(result[1]),
          parseFloat(result[2]),
          parseFloat(result[3])
        );
        normals.push(normal.x, normal.y, normal.z);
        vertexCountPerFace++;
        endVertex++;
      } // every face have to own ONE valid normal

      if (normalCountPerFace !== 1) {
        console.error(
          "THREE.STLLoader: Something isn't right with the normal of face number " +
            faceCounter
        );
      } // each face have to own THREE valid vertices

      if (vertexCountPerFace !== 3) {
        console.error(
          "THREE.STLLoader: Something isn't right with the vertices of face number " +
            faceCounter
        );
      }

      faceCounter++;
    }

    const start = startVertex;
    const count = endVertex - startVertex;
    geometry.addGroup(start, count, groupCount);
    groupCount++;
  }
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );
  geometry.setAttribute("normal", new THREE.Float32BufferAttribute(normals, 3));
  return geometry;
}
