import "vtk.js/dist/vtk";
import { each } from "lodash";
import { createColorMap } from "./api.colormap";

import vtkFullScreenRenderWindow from "@/common/external/vtkFullScreenRenderWindow";
const vtk = window.vtk;
// const vtkFullScreenRenderWindow = vtk.Rendering.Misc.vtkFullScreenRenderWindow;

// global scope
let global = {
  activePhase: null, // null
  loadedPhases: [],
  loadedSurfaces: [],
  venous: {
    actor: null,
    mapper: null,
    ctfun: null,
    ofun: null,
    gaussians: null,
    stored: { wl: null, ww: null },
    params: {
      l1: 0.53,
      l2: 0.6,
      w1: 0.12,
      w2: 0.1
    }
  },
  arterial: {
    actor: null,
    mapper: null,
    ctfun: null,
    ofun: null,
    gaussians: null,
    stored: { wl: null, ww: null },
    params: {
      l1: 0.3,
      l2: 0.4,
      w1: 0.02,
      w2: 0.1
    }
  }
};

// internal state
let state = {
  bones: 1.0,
  organs: 1.0,
  spleen_smooth: false,
  ambient: 0.3,
  diffuse: 0.7,
  specular: 0.3,
  specularPower: 0.8,
  raysDistance: 1.1,
  blurOnInteraction: false,
  ww: 1,
  wl: 0
};

const opacityNodes = createColorMap();

/**
 * Build vtk image data TODO check both with dicom and nrrd
 * @param {*} rawVolume
 */
export function buildVtkVolume(rawVolume) {
  const dims = rawVolume.header.sizes;
  const numScalars = dims[0] * dims[1] * dims[2];

  if (numScalars < 1 || dims[1] < 2 || dims[1] < 2 || dims[2] < 2) {
    return;
  }

  const volume = vtk.Common.DataModel.vtkImageData.newInstance();
  const origin = [
    // TODO fix this (missing _)
    parseFloat(rawVolume.header["space origin"][0]),
    parseFloat(rawVolume.header["space origin"][1]),
    parseFloat(rawVolume.header["space origin"][2])
  ];
  // TODO fix this (extract biggest ones)
  const spacing = [
    rawVolume.header["space directions"][0][0],
    rawVolume.header["space directions"][1][1],
    rawVolume.header["space directions"][2][2]
  ];

  volume.setDimensions(dims);
  volume.setOrigin(origin);
  volume.setSpacing(spacing);

  const scalars = vtk.Common.Core.vtkDataArray.newInstance({
    name: "Scalars",
    values: rawVolume.data,
    numberOfComponents: 1
  });

  volume.getPointData().setScalars(scalars);

  return volume;
}

/**
 * Setup the scene
 * @param {} divId
 * @param {} cb
 */
export function setupScene(divId, cb) {
  // reset
  global.loadedPhases = [];

  // set transparent background, so background color will be defined by canvas css
  let background = [0, 0, 0].map(e => e / 255);
  const bkg_opacity = 0;
  background = background.concat(bkg_opacity);

  let rootContainer = document.getElementById(divId);
  const containerStyle = {
    margin: "0",
    padding: "0",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    cursor: "pointer"
  };

  const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
    background,
    rootContainer,
    containerStyle
  });

  // resize: this is not perfect but somehow avoid strange reaction to resize
  // the problem is container height value that comes always increased too much
  fullScreenRenderer.setResizeCallback(() => {
    let container = fullScreenRenderer.getContainer();
    let rootContainer = fullScreenRenderer.getRootContainer();
    container.style.maxHeight = `${rootContainer.offsetHeight - 60}px`; // 60px is toolbar height
  });

  const renderer = fullScreenRenderer.getRenderer();
  const renderWindow = fullScreenRenderer.getRenderWindow();

  // Controller widget
  const isBackgroundDark = background[0] + background[1] + background[2] < 1.5;

  const PGwidget = vtk.Interaction.Widgets.vtkPiecewiseGaussianWidget.newInstance(
    {
      numberOfBins: 256,
      size: [400, 200]
    }
  );
  PGwidget.updateStyle({
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    histogramColor: "rgba(100, 100, 100, 0.5)",
    strokeColor: "rgb(0, 0, 0)",
    activeColor: "rgb(255, 255, 255)",
    handleColor: "rgb(50, 150, 50)",
    buttonDisableFillColor: "rgba(255, 255, 255, 0.5)",
    buttonDisableStrokeColor: "rgba(0, 0, 0, 0.5)",
    buttonStrokeColor: "rgba(0, 0, 0, 1)",
    buttonFillColor: "rgba(255, 255, 255, 1)",
    strokeWidth: 2,
    activeStrokeWidth: 3,
    buttonStrokeWidth: 1.5,
    handleWidth: 3,
    iconSize: 20, // Can be 0 if you want to remove buttons (dblClick for (+) / rightClick for (-))
    padding: 10
  });

  const widgetContainer = document.createElement("div");
  rootContainer.appendChild(widgetContainer);

  widgetContainer.style.position = "absolute";
  widgetContainer.style.top = "5%";
  widgetContainer.style.background = "rgba(255, 255, 255, 0.3)";
  widgetContainer.style.float = "right";

  // to hide widget
  PGwidget.setContainer(null);

  const interactor = renderWindow.getInteractor();
  interactor.setDesiredUpdateRate(15.0);

  // store in global scope
  global.renderer = renderer;
  global.renderWindow = renderWindow;
  global.isBackgroundDark = isBackgroundDark;
  global.widget = PGwidget;
  global.widgetContainer = widgetContainer;
  global.interactor = interactor;
  global.fullScreenRenderer = fullScreenRenderer;

  // bind to window for DEV
  window.global = global;

  // since container style of vtkjs is bugged, use this workaround to set
  // the parent container of the canvas as relative
  // TODO when we'll remove widget check this, probably it will not be an array anymore
  document.getElementsByTagName("canvas")[0].offsetParent.style.position =
    "relative";

  if (cb) {
    cb();
  }
}

/**
 * Load and render an image
 * @param {*} volume
 * @param {*} cb
 */
export function loadImage(volume, phase, cb) {
  if (!volume || global.loadedPhases.includes(phase)) {
    cb(false);
    return;
  }

  let volumeData = buildVtkVolume(volume);
  let center = volumeData.getCenter();

  let actor = vtk.Rendering.Core.vtkVolume.newInstance();
  let mapper = vtk.Rendering.Core.vtkVolumeMapper.newInstance();

  mapper.setSampleDistance(1.1);
  mapper.setInputData(volumeData);
  actor.setMapper(mapper);

  // Set apperance properties

  let lutFuncs = getLutFuncs(0, 0);

  actor.getProperty().setRGBTransferFunction(0, lutFuncs.ctfun);
  actor.getProperty().setScalarOpacity(0, lutFuncs.ofun);
  actor.getProperty().setScalarOpacityUnitDistance(0, 30.0);
  actor.getProperty().setInterpolationTypeToLinear();
  actor.getProperty().setUseGradientOpacity(0, true);
  actor.getProperty().setGradientOpacityMinimumValue(0, 2);
  actor.getProperty().setGradientOpacityMinimumOpacity(0, 0.0);
  actor.getProperty().setGradientOpacityMaximumValue(0, 20);
  actor.getProperty().setGradientOpacityMaximumOpacity(0, 2.0); // mod
  actor.getProperty().setShade(true);
  actor.getProperty().setAmbient(state.ambient);
  actor.getProperty().setDiffuse(state.diffuse);
  actor.getProperty().setSpecular(state.specular);
  actor.getProperty().setSpecularPower(state.specularPower);

  // set in global store
  global[phase].actor = actor;
  global[phase].mapper = mapper;
  global[phase].data = volumeData;
  global[phase].ctfun = lutFuncs.ctfun;
  global[phase].ofun = lutFuncs.ofun;

  global.renderer.addVolume(global[phase].actor);

  let rendered = true;

  if (global.loadedPhases.length > 0) {
    // hide other phases
    actor.setVisibility(false);
    rendered = false;
  } else {
    global.activePhase = phase;
    updateWidget(volumeData, phase);
    setWidgetCallbacks(phase);
    setupInteractor(center, phase);
    setCamera(center);
    // hide/show first phase loaded
    actor.setVisibility(false);
    rendered = false;
  }
  global.loadedPhases.push(phase);

  blurOnInteraction(true);

  if (cb) setTimeout(cb, 1000, true, rendered);
}

export function setCameraOnImage(phase) {
  let volumeData = global[phase].data;
  let center = volumeData.getCenter();
  setCamera(center);
  renderScene();
}

function setCamera(center) {
  global.renderer.resetCamera();
  global.renderer.getActiveCamera().zoom(1.5);
  global.renderer.getActiveCamera().elevation(70);
  global.renderer.getActiveCamera().setViewUp(0, 0, 1);
  global.renderer
    .getActiveCamera()
    .setFocalPoint(center[0], center[1], center[2]);
  global.renderer
    .getActiveCamera()
    .setPosition(center[0], center[1] - 2000, center[2]);
  global.renderer.getActiveCamera().setThickness(10000);
}

export function renderScene() {
  global.renderWindow.render();
}

export function setActivePhase(phase) {
  let oldPhase = global.activePhase;
  if (oldPhase) {
    global[oldPhase].actor.setVisibility(false);
  }

  if (global.activePhase) {
    // store active wl, ww before changing activePhase
    global[global.activePhase].stored.wl = state.wl;
    global[global.activePhase].stored.ww = state.ww;
  }

  if (phase == "arterial" || phase == "venous") {
    global[phase].actor.setVisibility(true);
    global.activePhase = phase;

    let volumeData = global[phase].data;
    let center = volumeData.getCenter();
    updateWidget(volumeData, phase);
    setWidgetCallbacks(phase);
    setupInteractor(center, phase);
    blurOnInteraction(true);

    // restore active wl, ww after changing activePhase
    state.wl = global[phase].stored.wl || 0;
    state.ww = global[phase].stored.ww || 1;
  } else {
    // let theOtherPhase = phase == "venous" ? "arterial" : "venous";
    // // if exist, set visibility to false
    // if (global[theOtherPhase].actor) {
    //   global[theOtherPhase].actor.setVisibility(false);
    // }
    global.activePhase = null;
  }

  renderScene();
}

export function setWidgetCallbacks() {
  global.widget.bindMouseListeners();

  global.widget.onAnimation(start => {
    if (start) {
      global.renderWindow.getInteractor().requestAnimation(global.widget);
    } else {
      global.renderWindow.getInteractor().cancelAnimation(global.widget);
    }
  });

  global.widget.onOpacityChange(widget => {
    global.widget = widget;
    global[global.activePhase].gaussians = widget.getGaussians().slice(); // store in global
    global.widget.applyOpacity(global[global.activePhase].ofun);
    if (!global.renderWindow.getInteractor().isAnimating()) {
      global.renderWindow.render();
    }
  });
}

/**
 * Initialize opacity and color transfer export function
 */
export function getLutFuncs(max_hist, min_hist) {
  // create color and opacity transfer functions
  const ctfun = vtk.Rendering.Core.vtkColorTransferFunction.newInstance();

  opacityNodes.forEach(n => {
    ctfun.addRGBPoint(
      min_hist + n.x * (max_hist - min_hist), // must be hist absolute values
      n.color[0],
      n.color[1],
      n.color[2]
    );
  });

  const ofun = vtk.Common.DataModel.vtkPiecewiseFunction.newInstance();

  return {
    ctfun: ctfun,
    ofun: ofun
  };
}

/**
 * Update widget controller after a new volume is added
 * @param {*} volumeData
 */
export function updateWidget(volumeData, phase) {
  // piecewise gaussian
  global.widget.setDataArray(
    volumeData
      .getPointData()
      .getScalars()
      .getData()
  );

  let l1 = global[phase].params.l1;
  let w1 = global[phase].params.w1;
  let l2 = global[phase].params.l2;
  let w2 = global[phase].params.w2;

  // if stored, set
  if (global[phase].gaussians) {
    global.widget.setGaussians(global[phase].gaussians.slice());
  }
  // else, set default
  else if (phase == "arterial") {
    global.widget.setGaussians([]); // remove all
    global.widget.addGaussian(l1, state.organs, w1, -0.02, -0.1); // x, y, ampiezza, sbilanciamento, andamento
    global.widget.addGaussian(l2, state.bones, w2, 0.0, 1.5); // x, y, ampiezza, sbilanciamento, andamento
  } else if (phase == "venous") {
    global.widget.setGaussians([]); // remove all
    global.widget.addGaussian(l1, state.organs, w1, -0.09, 0.59); // x, y, ampiezza, sbilanciamento, andamento
    global.widget.addGaussian(l2, state.bones, w2, 0.0, 0.0); // x, y, ampiezza, sbilanciamento, andamento
  }

  global.widget.applyOpacity(global[phase].ofun);
  global.widget.setColorTransferFunction(global[phase].ctfun);
  global[phase].ctfun.onModified(() => {
    global.widget.render();
    global.renderWindow.render();
  });
}

/**
 * Reset volume controller gaussians to original values
 * @param {String} phase - "arterial" or "venous"
 */
export function resetWidget(phase) {
  let l1 = global[phase].params.l1;
  let w1 = global[phase].params.w1;
  let l2 = global[phase].params.l2;
  let w2 = global[phase].params.w2;

  global.widget.setGaussians([]); // remove all

  if (phase == "arterial") {
    global.widget.addGaussian(l1, state.organs, w1, -0.02, -0.1); // x, y, ampiezza, sbilanciamento, andamento
    global.widget.addGaussian(l2, state.bones, w2, 0.0, 1.5); // x, y, ampiezza, sbilanciamento, andamento
  } else if (phase == "venous") {
    global.widget.addGaussian(l1, state.organs, w1, -0.09, 0.59); // x, y, ampiezza, sbilanciamento, andamento
    global.widget.addGaussian(l2, state.bones, w2, 0.0, 0.0); // x, y, ampiezza, sbilanciamento, andamento
  }

  global.widget.applyOpacity(global[phase].ofun);
}

/**
 * Build vtk polydata from vtk file content - NO MORE USED
 * @param {Array} data - VTK file content (as text)
 */
export function buildSurface(data) {
  // split lines
  let lines = data.split(" \n");
  // get number of points
  let pointsHeader = lines[0].split("\n");
  let numberOfPoints = parseInt(pointsHeader[4].split(" ")[1]);
  // get points content
  let bodyPts = lines.slice(1, Math.ceil(numberOfPoints / 3));
  bodyPts.unshift(pointsHeader[5]);
  // parse to float
  let pointsArray = bodyPts
    .map(ps => ps.split(" "))
    .map(fs => fs.map(e => parseFloat(e)))
    .flat();
  // get number of cells
  let headerCells = lines
    .slice(Math.ceil(numberOfPoints / 3), Math.ceil(numberOfPoints / 3) + 1)
    .pop()
    .split("\n");

  // remove first element if empty line
  if (headerCells[0] == "") {
    headerCells.shift();
  }

  // get rest of content
  let bodyCells = lines.slice(Math.ceil(numberOfPoints / 3) + 1);
  bodyCells.unshift(headerCells[2]);

  let cellsArray = bodyCells.map(ps => ps.split(" ")).flat();
  // get connectivity values TODO use regex
  let idx_start_1 = cellsArray.indexOf("CONNECTIVITY");
  let idx_start_2 = cellsArray.indexOf("\nCONNECTIVITY");
  let idx_end_1 = cellsArray.indexOf("CELL_DATA");
  let idx_end_2 = cellsArray.indexOf("\nCELL_DATA");

  if (idx_start_1 < 0 && idx_start_2 < 0) {
    console.warn("no connectivity", idx_start_1, idx_start_2);
    return null;
  }

  let idx_start = idx_start_1 > 0 ? idx_start_1 : idx_start_2;
  let idx_end = idx_end_1 > 0 ? idx_end_1 : idx_end_2;
  let connArray = cellsArray.slice(idx_start + 1, idx_end);
  connArray[0] = connArray[0].split("\n")[1];
  // parse to float
  connArray = connArray.map(e => parseFloat(e));
  // add number of points per poly
  let polysArray = [];
  for (let i = 0; i < connArray.length; i++) {
    if (i % 3 == 0) {
      polysArray.push(3);
    }
    polysArray.push(connArray[i]);
  }

  // create polydata
  const polydata = vtk.Common.DataModel.vtkPolyData.newInstance();
  polydata.getPoints().setData(Float32Array.from(pointsArray), 3);
  polydata.getPolys().setData(Uint16Array.from(polysArray));

  return polydata;
}

/**
 *
 * @param {*} data
 */
export function loadSurface(polydata, props) {
  // build surface from data
  // let polydata = buildSurface(data, props.tag);

  if (!polydata) {
    return;
  }

  let actor = vtk.Rendering.Core.vtkActor.newInstance();
  // let mapper = vtk.Rendering.Core.vtkPolyDataMapper.newInstance();
  let mapper = vtk.Rendering.Core.vtkMapper.newInstance();
  actor.setMapper(mapper);
  mapper.setInputData(polydata);

  // surface props
  mapper.setScalarVisibility(false);
  actor.getProperty().setOpacity(props.opacity);
  actor.getProperty().setColor(props.color);
  actor.getProperty().setAmbient(state.ambient * 0.8);
  actor.getProperty().setDiffuse(state.diffuse * 0.8);
  actor.getProperty().setSpecular(state.specular * 0.8);
  actor.getProperty().setSpecularPower(state.specularPower * 0.8);
  actor.setVisibility(props.visibility);

  global[props.tag] = {};
  global[props.tag].actor = actor;

  global.loadedSurfaces.push(props.tag);

  // render
  global.renderer.addActor(actor);
  global.renderWindow.render();
}

/**
 * get stl surface from server
 * @param {*} url
 * @param {*} cb
 */
export function getSTL(url, cb) {
  let reader = vtk.IO.Geometry.vtkSTLReader.newInstance();
  reader.setUrl(url, { binary: false }).then(() => {
    cb(reader.getOutputData());
  });
}

/**
 *
 * @param {*} polydata
 */
export function setCameraOnObject(polydata) {
  let bounds = polydata.getBounds();

  let center = [
    (bounds[1] + bounds[0]) / 2,
    (bounds[3] + bounds[2]) / 2,
    (bounds[5] + bounds[4]) / 2
  ];

  global.renderer.getActiveCamera().zoom(1.5);
  global.renderer.getActiveCamera().elevation(70);
  global.renderer.getActiveCamera().setViewUp(0, 0, 1);
  global.renderer
    .getActiveCamera()
    .setFocalPoint(center[0], center[1], center[2]);
  global.renderer
    .getActiveCamera()
    .setPosition(center[0], center[1] - 200, center[2] + 200);

  global.renderWindow.render();
}

export function setVRSampleDistance(value) {
  state.raysDistance = value;
  each(global.loadedPhases, phase => {
    global[phase].mapper.setSampleDistance(value);
    if (value < 0.5) {
      global[phase].mapper.setMaximumSamplesPerRay(4000);
    } else {
      global[phase].mapper.setMaximumSamplesPerRay(1000);
    }
  });
  global.renderWindow.render();
}

export function setLights(value) {
  let valueForVR = value;
  let valueForSurf = value - 0.2; // a little bit less light

  each(global.loadedPhases, phase => {
    global[phase].actor.getProperty().setAmbient(state.ambient * valueForVR);
    global[phase].actor.getProperty().setDiffuse(state.diffuse * valueForVR);
    global[phase].actor.getProperty().setSpecular(state.specular * valueForVR);
    global[phase].actor
      .getProperty()
      .setSpecularPower(state.specularPower * valueForVR);
  });

  each(global.loadedSurfaces, surface => {
    global[surface].actor
      .getProperty()
      .setAmbient(state.ambient * valueForSurf);
    global[surface].actor
      .getProperty()
      .setDiffuse(state.diffuse * valueForSurf);
    global[surface].actor
      .getProperty()
      .setSpecular(state.specular * valueForSurf);
    global[surface].actor
      .getProperty()
      .setSpecularPower(state.specularPower * valueForSurf);
  });

  global.renderWindow.render();
}

export function setupInteractor(center, phase) {
  const rotateManipulator = vtk.Interaction.Manipulators.vtkMouseCameraTrackballRotateManipulator.newInstance(
    { button: 1 }
  );
  const panManipulator = vtk.Interaction.Manipulators.vtkMouseCameraTrackballPanManipulator.newInstance(
    { button: 1, control: true } // on ctrl press
  );
  const zoomManipulator = vtk.Interaction.Manipulators.vtkMouseCameraTrackballZoomManipulator.newInstance(
    { scrollEnabled: true } // on scroll
  );
  const rangeManipulator = vtk.Interaction.Manipulators.vtkMouseRangeManipulator.newInstance(
    { button: 1, shift: true } // on shift press
  );

  function getWL() {
    return state.wl;
  }

  function getWW() {
    return state.ww;
  }

  function setWL(v) {
    state.wl = state.wl + (v - state.wl) / 25;

    let gaussians = global.widget.getGaussians().slice(); // NOTE: slice() to clone!
    gaussians[0].position = state.wl + global[phase].params.l1;
    gaussians[1].position = state.wl + global[phase].params.l2;
    global.widget.setGaussians(gaussians);
  }

  function setWW(v) {
    state.ww = state.ww + (v - state.ww) / 5;

    let gaussians = global.widget.getGaussians().slice(); // NOTE: slice() to clone!
    gaussians[0].width = global[phase].params.w1 * state.ww;
    gaussians[1].width = global[phase].params.w2 * state.ww;
    global.widget.setGaussians(gaussians);
  }

  // TODO adapt to both phases
  rangeManipulator.setVerticalListener(-1, 1, 0.001, getWL, setWL);
  rangeManipulator.setHorizontalListener(0.1, 2.1, 0.001, getWW, setWW);

  const interactorStyle = vtk.Interaction.Style.vtkInteractorStyleManipulator.newInstance();
  interactorStyle.addMouseManipulator(rangeManipulator);
  interactorStyle.addMouseManipulator(rotateManipulator);
  interactorStyle.addMouseManipulator(panManipulator);
  interactorStyle.addMouseManipulator(zoomManipulator);
  interactorStyle.setCenterOfRotation(center);
  global.fullScreenRenderer.getInteractor().setInteractorStyle(interactorStyle);
}

/**
 * Toggle blurring on interaction (Increase performance)
 * @param {*} toggle
 */
export function blurOnInteraction(toggle) {
  let interactor = global.interactor;
  let activePhase = global.activePhase;

  if (toggle) {
    interactor.onLeftButtonPress(() => {
      global[activePhase].mapper.setSampleDistance(state.raysDistance * 5);
    });

    interactor.onLeftButtonRelease(() => {
      global[activePhase].mapper.setSampleDistance(state.raysDistance);
      global.renderWindow.render();
    });
  } else {
    interactor.onLeftButtonPress(() => {
      global[activePhase].mapper.setSampleDistance(state.raysDistance);
    });

    interactor.onLeftButtonRelease(() => {
      global[activePhase].mapper.setSampleDistance(state.raysDistance);
    });
  }
}

/**
 * Set actor visibility
 * @param {*} tag
 * @param {*} toggle
 */
export function toggleSurfaceVisible(tag, toggle) {
  if (global.loadedSurfaces.includes(tag)) {
    let targetActor = global[tag].actor;
    targetActor.setVisibility(toggle);
  }
}

/**
 * Set actor opacity
 * @param {*} tag
 * @param {*} value
 */
export function setSurfaceOpacity(tag, value) {
  if (global.loadedSurfaces.includes(tag)) {
    let targetActor = global[tag].actor;
    targetActor.getProperty().setOpacity(value);
  }
}

/**
 * Update LUT range
 * @param {*} min_hist
 * @param {*} max_hist
 */
export function setLUT(phase, min_hist, max_hist) {
  let ctfun = getLutFuncs(max_hist, min_hist).ctfun;
  if (phase == global.activePhase) {
    global.widget.setColorTransferFunction(ctfun);
    renderScene();
  }
  let actor = global[phase].actor;
  if (actor) {
    actor.getProperty().setRGBTransferFunction(0, ctfun);
    global[phase].ctfun = ctfun;
  }
}

/**
 * Destroy webgl content and release listeners
 */
export function destroy3d() {
  if (global.renderWindow) {
    global.fullScreenRenderer.getContainer().remove();
    global.renderWindow.delete();
  }
}

// DEV FUNCTIONS

window.hideHistogram = function() {
  console.log("hide histogram");
  global.widget.setContainer(null);
};

window.showHistogram = function() {
  console.log("show histogram");
  global.widget.setContainer(global.widgetContainer);
};
