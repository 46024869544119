<template>
  <div>
    <template v-if="isEditorActive">
      <v-card class="mt10" elevation="10">
        <v-card-title class="pb-0 pt-0 pl-2 pr-2 font-title">
          {{ $t("segmentation_editor") }}
          <v-spacer></v-spacer>
          <v-switch inset v-model="brush" @change="toggleBrush"> </v-switch>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <font-awesome-icon
                icon="question"
                size="1x"
                color="#007ec3"
                v-on="{ ...tooltip }"
              />
            </template>
            <span>{{ $t("help_editor") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 pt-1 pl-2 pr-2">
          <v-container fluid class="pt-0 pb-0">
            <v-col cols="12" class="text-center pb-0 pt-0">
              <p class="mask-title">{{ mask.name }}</p>
              <p class="phase-title">{{ $t("phase") }}: {{ activePhase }}</p>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
              <v-slider
                class="mt5"
                hint="mask opacity"
                persistent-hint
                dense
                min="0"
                max="1"
                step="0.1"
                prepend-icon="mdi-opacity"
                :thumb-size="20"
                :color="mask.color"
                :track-color="mask.color"
                v-model="mask.opacity"
                @change="setOpacity"
                thumb-label
              ></v-slider>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="pb-1 pt-1">
              <v-slider
                class="mt5"
                hint="brush dimension"
                persistent-hint
                dense
                min="1"
                max="15"
                step="1"
                prepend-icon="mdi-brush"
                :thumb-size="30"
                :color="mask.color"
                :track-color="mask.color"
                v-model="mask.dimension"
                @change="setDimension"
                thumb-label
              >
                <template v-slot:thumb-label="{ value }">
                  {{ value }} px
                </template>
              </v-slider>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="pb-1 pt-1">
              <v-range-slider
                class="mt5"
                hint="brush thresholds"
                persistent-hint
                dense
                :min="mask.thresholdsLimit[0]"
                :max="mask.thresholdsLimit[1]"
                step="10"
                prepend-icon="mdi-brush"
                :thumb-size="25"
                :color="mask.color"
                :track-color="mask.color"
                :value="mask.thresholds"
                @change="setThresholds"
                thumb-label
              ></v-range-slider>
            </v-col>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-1 pb-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="undoLastBrush"
                text
                color="primary"
                v-on="{ ...tooltip }"
                @click="undo"
              >
                <font-awesome-icon icon="undo" size="2x" />
              </v-btn>
            </template>
            <span>{{ $t("undo_segmentation") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="redoLastBrush"
                text
                color="primary"
                v-on="{ ...tooltip }"
                @click="redo"
              >
                <font-awesome-icon icon="redo" size="2x" />
              </v-btn>
            </template>
            <span>{{ $t("redo_segmentation") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="restoreSegmentation"
                text
                color="primary"
                v-on="{ ...tooltip }"
                :disabled="isCustom"
                @click="restore"
              >
                <font-awesome-icon icon="clone" size="2x" />
              </v-btn>
            </template>
            <span>{{ $t("restore_segmentation") }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions class="pt-1 pb-1">
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="discardSegmentation"
                text
                color="primary"
                v-on="{ ...tooltip }"
                @click="discard"
              >
                <font-awesome-icon icon="times-circle" size="2x" />
              </v-btn>
            </template>
            <span>{{ $t("discard_segmentation") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                id="saveSegmentation"
                text
                color="primary"
                v-on="{ ...tooltip }"
                @click="save"
              >
                <font-awesome-icon icon="save" size="2x" />
              </v-btn>
            </template>
            <span>{{ $t("save_segmentation") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { find } from "lodash";

import {
  setMaskProps,
  enableBrushTool,
  disableBrushTool,
  forceRender,
  setBrushProps,
  getActiveLabelmapBuffer,
  redoLastStroke,
  undoLastStroke
} from "@/common/api.segmentation";
import { getMask, resetOriginalMask, updateMask } from "@/common/api.jobs";

export default {
  name: "Editor",
  components: {},
  data() {
    return {
      brush: true
    };
  },
  computed: {
    ...mapGetters(["isEditorActive"]),
    ...mapState({
      activePhase(state) {
        return state.activePhase;
      },
      mask(state) {
        return state.segmentations[state.segmentations.active.tag];
      },
      opacity(state) {
        return state.segmentations.active.tag
          ? state.segmentations[state.segmentations.active.tag].opacity
          : null;
      },
      dimension(state) {
        return state.segmentations.active.tag
          ? state.segmentations[state.segmentations.active.tag].dimension
          : null;
      },
      isCustom(state) {
        return find(state.segmentations.default_segmentations, [
          "tag",
          state.segmentations.active.tag
        ])
          ? false
          : true;
      }
    })
  },
  watch: {
    opacity: function(v) {
      let tag = this.$store.state.segmentations.active.tag;
      this.$store.dispatch("segmentations/setMaskOpacity", [tag, v]);
      if (this.$store.state.segmentations.active.tag) {
        let maskProps = this.$store.state.segmentations[tag];
        setMaskProps(maskProps);
      }
    },
    dimension: function(v) {
      if (this.$store.state.segmentations.active.tag) {
        let id = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].id;
        this.$store.dispatch("segmentations/setMaskBrushDimension", [id, v]);
        setBrushProps({ radius: v });
      }
    }
  },
  methods: {
    discard: function() {
      let self = this;
      if (this.$store.state.segmentations.active.tag) {
        self.$store.dispatch("segmentations/setIsLoading", true);
        let resourceId = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].resourceId;
        getMask(null, resourceId, function() {
          forceRender();
          self.$store.dispatch("segmentations/setIsLoading", false);
          self.closeEditor();
        });
      }
    },
    save: function() {
      // UPDATE THE MASK
      let self = this;
      if (this.$store.state.segmentations.active.tag) {
        let resourceId = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].resourceId;
        self.$store.dispatch("segmentations/setIsLoading", true);
        let maskBuffer = getActiveLabelmapBuffer();
        let maskArray = new Uint16Array(maskBuffer);
        updateMask(resourceId, maskArray, function() {
          self.$store.dispatch("segmentations/setIsLoading", false);
          self.closeEditor();
        });
      }
    },
    undo: function() {
      undoLastStroke();
    },
    redo: function() {
      redoLastStroke();
    },
    restore: function() {
      let self = this;
      if (this.$store.state.segmentations.active.tag) {
        let resourceId = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].resourceId;
        self.$store.dispatch("segmentations/setIsLoading", true);
        resetOriginalMask(resourceId, function() {
          forceRender();
          self.$store.dispatch("segmentations/setIsLoading", false);
        });
      }
    },
    toggleBrush: function() {
      if (this.brush) {
        let currentLeftMouseHandler = this.$store.state.leftMouseHandler;
        this.$store.dispatch(
          "setLastLeftMouseHandler",
          currentLeftMouseHandler
        );
        this.$store.dispatch("setLeftMouseHandler", "Brush");
        let brushDimension = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].dimension;
        let brushThresholds = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].thresholds;
        enableBrushTool(parseInt(brushDimension), brushThresholds);
      } else {
        disableBrushTool(this.$store.state.lastLeftMouseHandler);
        this.$store.dispatch(
          "setLeftMouseHandler",
          this.$store.state.lastLeftMouseHandler
        );
        this.$store.dispatch("setLastLeftMouseHandler", null);
      }
    },
    closeEditor: function() {
      if (this.brush) {
        disableBrushTool(this.$store.state.lastLeftMouseHandler);
        this.$store.dispatch(
          "setLeftMouseHandler",
          this.$store.state.lastLeftMouseHandler
        );
        this.$store.dispatch("setLastLeftMouseHandler", null);
      }
      this.$store.dispatch("segmentations/setActiveMask", [null, null]);
      this.brush = true;
    },
    setThresholds: function(v) {
      setBrushProps({ thresholds: v });
    },
    setOpacity: function(v) {
      let tag = this.$store.state.segmentations.active.tag;
      this.$store.dispatch("segmentations/setMaskOpacity", [tag, v]);
      if (this.$store.state.segmentations.active.tag) {
        let maskProps = this.$store.state.segmentations[tag];
        setMaskProps(maskProps);
      }
    },
    setDimension: function(v) {
      if (this.$store.state.segmentations.active.tag) {
        let id = this.$store.state.segmentations[
          this.$store.state.segmentations.active.tag
        ].id;
        this.$store.dispatch("segmentations/setMaskBrushDimension", [id, v]);
        setBrushProps({ radius: v });
      }
    }
  }
};
</script>

<style scoped>
.mask-title {
  text-transform: uppercase;
  font-size: 1.5vmax;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
}
.phase-title {
  text-transform: uppercase;
  font-size: 0.85vmax;
  text-align: center;
}
.mt10 {
  margin-top: 10px !important;
}
.font-title {
  font-size: inherit;
}
</style>
