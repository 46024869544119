<template>
  <div>
    <v-app-bar app height="55">
      <ToolBar />
    </v-app-bar>
    <v-app>
      <v-main>
        <router-view name="content" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import ToolBar from "@/components/ToolBar";

// fonts
// import "typeface-source-sans-pro";
import "typeface-roboto";

// multilanguage
import i18n from "@/plugins/i18n";
import { without } from "lodash";

import {
  initializeImageLoader,
  registerNRRDImageLoader,
  initializeCSTools
} from "@/common/api.images.js";

initializeImageLoader();
registerNRRDImageLoader();
initializeCSTools();

export default {
  name: "App",
  data() {
    return {
      languages: ["en"],
      language: "en"
    };
  },
  components: { ToolBar },
  methods: {
    switchLocale() {
      let language = without(this.languages, this.language)[0];
      this.language = language;
      i18n.locale = language;
    }
  }
};
</script>

<style>
html {
  overflow: hidden;
}
body {
  font-family: "roboto";
}
.v-main {
  height: 100vh;
}
.h100 {
  height: 100%;
}
.h95 {
  height: 95%;
}
.h90 {
  height: 90%;
}
.h80 {
  height: 80%;
}
.h70 {
  height: 70%;
}
.h60 {
  height: 60%;
}
.h50 {
  height: 50%;
}
.h40 {
  height: 40%;
}
.h30 {
  height: 30%;
}
.h20 {
  height: 20%;
}
.h10 {
  height: 10%;
}
.h5 {
  height: 5%;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.basis-50 {
  flex-basis: 48%;
}
.color-vic {
  color: #007ec3 !important;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px !important;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.text-white {
  color: white;
}
.bg-color-black {
  background-color: black;
}
.bg-color-white {
  background-color: white;
}
.log {
  text-align: center;
  margin-top: 1em;
  font-size: 1.5vmax;
}
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.v-dialog--fullscreen {
  background: black !important;
}
/* .v-messages {
  display: none !important;
} */
</style>
