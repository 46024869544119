<template>
  <v-container fluid>
    <v-textarea
      clearable
      clear-icon="mdi-delete"
      no-resize
      rows="20"
      v-model="notes"
      :loading="loading"
      class="b1"
    ></v-textarea>
  </v-container>
</template>

<script>
import { getCaseNotes } from "@/common/api.cases";

export default {
  name: "TextAreaNotes",
  data() {
    return {
      loading: true
    };
  },
  computed: {
    notes: {
      get() {
        return this.$store.state.notes;
      },
      set(value) {
        this.$store.commit("setNotes", value);
      }
    }
  },
  mounted() {
    let self = this;
    getCaseNotes(function(resp) {
      let notes = resp.body.text ? resp.body.text : "";
      self.$store.commit("setNotes", notes);
      self.loading = false;
    });
  }
};
</script>

<style scoped>
.b1 {
  border: 1px ridge;
}
</style>
