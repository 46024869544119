import { getLarvitarManager } from "@/larvitar/index";
import store from "@/store/index";
import {
  loadImage,
  loadSurface,
  setupScene,
  renderScene,
  setVRSampleDistance,
  setLights,
  toggleSurfaceVisible,
  setSurfaceOpacity,
  setLUT,
  getSTL,
  setActivePhase,
  destroy3d
} from "./api.vtk";
import { hexToRgb } from "@/common/api.segmentation";
import { concat } from "lodash";
import {
  buildAndLoadThreeJsScene,
  setOpacityThreeJs,
  toggleSurfaceThreeJs,
  setThreeJsLights
} from "@/common/api.threejs";
import { destroyThreeJsScene } from "./api.threejs";

export function buildAndLoadScene(divId) {
  let activePhase = store.state.r3d.activePhase;
  if (activePhase != "none") {
    // create scene
    setupScene(divId, () => {
      let volumes = buildVolumes();
      let tags = [];
      loadImage(volumes.venous, "venous", (exist, active) => {
        if (exist) {
          let max = volumes.venous.header.extra.max;
          let min = volumes.venous.header.extra.min;
          let max_hist = min + ((max - min) / 5) * 2.5;
          let min_hist = min + ((max - min) / 5) * 2.0;
          store.dispatch("r3d/setImageRange", ["venous", min, max]);
          store.dispatch("r3d/setOriginalInterval", [
            "venous",
            min_hist,
            max_hist
          ]);
          store.dispatch("r3d/setMinMaxInterval", [
            "venous",
            min_hist,
            max_hist
          ]);
          if (active) {
            store.dispatch("r3d/setActivePhase", "venous");
          }

          setLUT("venous", min_hist, max_hist);
          // DEV hardcoded values -1791/5659
          // setLUT("venous", -1791, 5659);
          tags = concat(store.state.segmentations.tags.venous, tags);
        }

        loadImage(volumes.arterial, "arterial", (exist, active) => {
          if (exist) {
            let max = volumes.arterial.header.extra.max;
            let min = volumes.arterial.header.extra.min;
            let max_hist = min + ((max - min) / 5) * 1.8;
            let min_hist = min + ((max - min) / 5) * 1.3;
            store.dispatch("r3d/setImageRange", ["arterial", min, max]);
            store.dispatch("r3d/setOriginalInterval", [
              "arterial",
              min_hist,
              max_hist
            ]);
            store.dispatch("r3d/setMinMaxInterval", [
              "arterial",
              min_hist,
              max_hist
            ]);

            setLUT("arterial", min_hist, max_hist);
            // DEV hardcoded values  -224/756
            //setLUT("arterial", -224, 756);

            if (active) {
              store.dispatch("r3d/setActivePhase", "arterial");
            }

            tags = concat(store.state.segmentations.tags.arterial, tags);
          }

          // set vr phase
          setActivePhase(activePhase);
          // hide/show proper pancreas
          let pancreas_visible =
            activePhase == "arterial" ? "pancreas_a" : "pancreas_v";
          let pancreas_invisible =
            activePhase == "arterial" ? "pancreas_v" : "pancreas_a";
          // toggle surfaces
          toggleSurface(pancreas_visible, 1);
          toggleSurface(pancreas_invisible, 0);
          // update UI
          store.dispatch("segmentations/setMaskR3DVisualization", [
            pancreas_visible,
            1
          ]);
          store.dispatch("segmentations/setMaskR3DVisualization", [
            pancreas_invisible,
            0
          ]);

          loadSurfaces(tags, () => {
            renderScene();
            setTimeout(function() {
              store.dispatch("quadview/setLoadingStatus", ["r3D", true]);
            }, 200);
          });
        });
      });
    });
  } else {
    buildAndLoadThreeJsScene(divId);
  }
}

function loadSurfaces(tags, cb) {
  let nextTag = tags.pop();
  if (nextTag) {
    if (store.state.segmentations[nextTag].surfacePath) {
      getSTL(store.state.segmentations[nextTag].surfacePath, function(resp) {
        // if not on current phase, set visibility to 0
        let visible = nextTag == "pancreas_a" ? 0 : 1;
        store.dispatch("segmentations/setMaskR3DVisualization", [
          nextTag,
          visible
        ]);

        loadSurface(resp, {
          tag: nextTag,
          visibility: visible,
          // opacity: store.state.segmentations[nextTag].opacity,
          opacity: 1.0,
          color: hexToRgb(store.state.segmentations[nextTag].color)
            .map(e => e / 255)
            .concat([1])
        });

        loadSurfaces(tags, cb);
      });
    } else {
      console.log("No Mask Surface Path for ", nextTag);
      loadSurfaces(tags, cb);
    }
  } else {
    cb();
  }
}

function buildVolumes() {
  let manager = getLarvitarManager();
  let volumes = {};
  let arterialSeriesId = store.state.arterialId;
  let venousSeriesId = store.state.venousId;

  if (arterialSeriesId) {
    let arterialData = manager[arterialSeriesId]["axial"];
    volumes.arterial = arterialData.volume;
  }
  if (venousSeriesId) {
    let venousData = manager[venousSeriesId]["axial"];
    volumes.venous = venousData.volume;
  }

  return volumes;
}

export function setVRQuality(value) {
  const max = 2;
  const min = 0.2;
  let samplingDistance = max - (max - min) * value;
  setVRSampleDistance(samplingDistance);
}

export function setVRBrightness(value) {
  let activePhase = store.state.r3d.activePhase;
  if (activePhase != "none") {
    setLights(value);
  } else {
    setThreeJsLights(value);
  }
}

export function toggleSurface(tag, toggle) {
  let activePhase = store.state.r3d.activePhase;
  if (activePhase != "none") {
    toggleSurfaceVisible(tag, toggle);
    renderScene();
  } else {
    toggleSurfaceThreeJs(tag, toggle);
  }
  store.dispatch("segmentations/setMaskR3DVisualization", [tag, toggle]);
}

export function setOpacity(tag, value) {
  let activePhase = store.state.r3d.activePhase;
  if (activePhase != "none") {
    setSurfaceOpacity(tag, value);
    renderScene();
  } else {
    setOpacityThreeJs(tag, value);
  }
  store.dispatch("segmentations/setMaskOpacity", [tag, value]);
}

export function setLutInterval(phase, [min, max]) {
  setLUT(phase, min, max);
  renderScene();
}

export function changeActivePhase(phase) {
  store.dispatch("quadview/setLoadingStatus", ["r3D", false]);
  if (phase == "none") {
    destroy3d();
    buildAndLoadThreeJsScene("r3D");
  } else {
    destroyThreeJsScene();
    destroy3d();
    buildAndLoadScene("r3D");
  }
}
