<template>
  <div id="dashboard" class="h100 overflow-auto">
    <v-row no-gutters class="text-center">
      <v-col md="8" offset-md="2" sm="12" class="pb-8">
        <v-card class="mt-40">
          <v-card-title>
            {{ $t("cases_dashboard") }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              append-icon="mdi-feature-search-outline"
            ></v-text-field>
            <v-spacer></v-spacer>
            <ImagesModal />
          </v-card-title>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="cases"
            :items-per-page="10"
            :search="search"
            :single-select="true"
            class="elevation-1"
            sort-by="created_at"
            :custom-sort="customSort"
            @click:row.once="openCase"
          >
            <template v-slot:item.delete="{ item }">
              <font-awesome-icon
                icon="trash"
                @click.stop="deleteCase(item)"
                class="selectable"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import request from "superagent";
import { mapState } from "vuex";

import { verify_and_refresh } from "@/common/api.users";
import { openCaseFromDashboard } from "@/common/api.cases";

import ImagesModal from "@/components/modals/ImagesModal";

export default {
  name: "Dashboard",
  components: { ImagesModal },
  data() {
    return {
      cases: [],
      search: "",
      loading: true,
      headers: [
        { text: "CASE NAME", value: "tag" },
        { text: "CREATION DATE", value: "created_at" },
        { text: "LAST UPDATE", value: "updated_at" },
        { text: "", value: "delete", sortable: false }
      ]
    };
  },
  mounted: function() {
    if (this.token) {
      this.loadData();
    }
  },
  computed: {
    ...mapState({
      token(state) {
        return state.accessToken;
      },
      newCase(state) {
        return state.newCase;
      }
    })
  },
  watch: {
    token(value) {
      if (value) {
        this.loadData();
      } else {
        this.cases = [];
      }
    },
    newCase: function(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      let self = this;
      verify_and_refresh(function() {
        request
          .get("/api/cases/")
          .set("Authorization", "Bearer " + self.$store.state.accessToken)
          .then(function(resp) {
            self.cases = resp.body;
            self.loading = false;
          });
      });
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "created_at" || index[0] == "updated_at") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    openCase(item) {
      this.$store.dispatch("quadview/setLoadingStatus", ["axial", false]);
      openCaseFromDashboard(item);
    },
    deleteCase(item) {
      confirm(
        "Are you sure you want to delete this case? \nOnce the case has been deleted it will be impossible to restore it."
      ) && this.removeCase(item);
    },
    removeCase(item) {
      var self = this;
      verify_and_refresh(function() {
        request
          .delete("/api/case/" + item.id + "/")
          .set("Authorization", "Bearer " + self.$store.state.accessToken)
          .then(function() {
            const index = self.cases.indexOf(item);
            self.cases.splice(index, 1);
          });
      });
    }
  }
};
</script>

<style scoped>
.mt-40 {
  margin-top: 40px;
}
.selectable {
  cursor: pointer;
}
</style>
