var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEditorActive)?[_c('v-card',{staticClass:"mt10",attrs:{"elevation":"10"}},[_c('v-card-title',{staticClass:"pb-0 pt-0 pl-2 pr-2 font-title"},[_vm._v(" "+_vm._s(_vm.$t("segmentation_editor"))+" "),_c('v-spacer'),_c('v-switch',{attrs:{"inset":""},on:{"change":_vm.toggleBrush},model:{value:(_vm.brush),callback:function ($$v) {_vm.brush=$$v},expression:"brush"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('font-awesome-icon',_vm._g({attrs:{"icon":"question","size":"1x","color":"#007ec3"}},Object.assign({}, tooltip)))]}}],null,false,2302924227)},[_c('span',[_vm._v(_vm._s(_vm.$t("help_editor")))])])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pb-0 pt-1 pl-2 pr-2"},[_c('v-container',{staticClass:"pt-0 pb-0",attrs:{"fluid":""}},[_c('v-col',{staticClass:"text-center pb-0 pt-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mask-title"},[_vm._v(_vm._s(_vm.mask.name))]),_c('p',{staticClass:"phase-title"},[_vm._v(_vm._s(_vm.$t("phase"))+": "+_vm._s(_vm.activePhase))])]),_c('v-col',{staticClass:"pb-1 pt-0",attrs:{"cols":"12"}},[_c('v-slider',{staticClass:"mt5",attrs:{"hint":"mask opacity","persistent-hint":"","dense":"","min":"0","max":"1","step":"0.1","prepend-icon":"mdi-opacity","thumb-size":20,"color":_vm.mask.color,"track-color":_vm.mask.color,"thumb-label":""},on:{"change":_vm.setOpacity},model:{value:(_vm.mask.opacity),callback:function ($$v) {_vm.$set(_vm.mask, "opacity", $$v)},expression:"mask.opacity"}})],1),_c('v-divider'),_c('v-col',{staticClass:"pb-1 pt-1",attrs:{"cols":"12"}},[_c('v-slider',{staticClass:"mt5",attrs:{"hint":"brush dimension","persistent-hint":"","dense":"","min":"1","max":"15","step":"1","prepend-icon":"mdi-brush","thumb-size":30,"color":_vm.mask.color,"track-color":_vm.mask.color,"thumb-label":""},on:{"change":_vm.setDimension},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" px ")]}}],null,false,2708000671),model:{value:(_vm.mask.dimension),callback:function ($$v) {_vm.$set(_vm.mask, "dimension", $$v)},expression:"mask.dimension"}})],1),_c('v-divider'),_c('v-col',{staticClass:"pb-1 pt-1",attrs:{"cols":"12"}},[_c('v-range-slider',{staticClass:"mt5",attrs:{"hint":"brush thresholds","persistent-hint":"","dense":"","min":_vm.mask.thresholdsLimit[0],"max":_vm.mask.thresholdsLimit[1],"step":"10","prepend-icon":"mdi-brush","thumb-size":25,"color":_vm.mask.color,"track-color":_vm.mask.color,"value":_vm.mask.thresholds,"thumb-label":""},on:{"change":_vm.setThresholds}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-1 pb-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"undoLastBrush","text":"","color":"primary"},on:{"click":_vm.undo}},Object.assign({}, tooltip)),[_c('font-awesome-icon',{attrs:{"icon":"undo","size":"2x"}})],1)]}}],null,false,2565116769)},[_c('span',[_vm._v(_vm._s(_vm.$t("undo_segmentation")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"redoLastBrush","text":"","color":"primary"},on:{"click":_vm.redo}},Object.assign({}, tooltip)),[_c('font-awesome-icon',{attrs:{"icon":"redo","size":"2x"}})],1)]}}],null,false,3182838285)},[_c('span',[_vm._v(_vm._s(_vm.$t("redo_segmentation")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"restoreSegmentation","text":"","color":"primary","disabled":_vm.isCustom},on:{"click":_vm.restore}},Object.assign({}, tooltip)),[_c('font-awesome-icon',{attrs:{"icon":"clone","size":"2x"}})],1)]}}],null,false,3544971743)},[_c('span',[_vm._v(_vm._s(_vm.$t("restore_segmentation")))])]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-1 pb-1"},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"discardSegmentation","text":"","color":"primary"},on:{"click":_vm.discard}},Object.assign({}, tooltip)),[_c('font-awesome-icon',{attrs:{"icon":"times-circle","size":"2x"}})],1)]}}],null,false,1753175170)},[_c('span',[_vm._v(_vm._s(_vm.$t("discard_segmentation")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"saveSegmentation","text":"","color":"primary"},on:{"click":_vm.save}},Object.assign({}, tooltip)),[_c('font-awesome-icon',{attrs:{"icon":"save","size":"2x"}})],1)]}}],null,false,3825690138)},[_c('span',[_vm._v(_vm._s(_vm.$t("save_segmentation")))])])],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }