<template>
  <div class="h100">
    <v-container fluid class="fill-height bg-color-black">
      <div class="h5 title">{{ $t("preview") }}</div>
      <v-row class="h95">
        <v-col class="h100" md="7" sm="12" xs="12">
          <canvas2D name="axial" viewer="quadview" activeViewport="axial" />
        </v-col>
        <v-col md="5" sm="12" xs="12">
          <div class=" h100">
            <p class="text-white center-text">{{ $t("choose_series_msg") }}</p>
            <v-data-table
              :headers="headers"
              :items="series"
              item-key="id"
              :single-select="true"
              class="elevation-1 vertical-scroll h250"
              hide-default-footer
              disable-pagination
              v-model="selected"
              @click:row="openSeries"
            >
              <template v-slot:item.phase="{ item }">
                <template v-if="item.phase == 0">
                  {{ $t("not_chosen") }}
                </template>
                <template v-if="item.phase == 1">
                  {{ $t("arterial") }}
                </template>
                <template v-if="item.phase == 2">
                  {{ $t("venous") }}
                </template>
              </template>
            </v-data-table>
            <v-spacer></v-spacer>
            <v-card class="mt20">
              <v-card-text>
                <v-simple-table dense class="w100 tal mb30">
                  <template v-slot:default v-if="selected[0]">
                    <tbody>
                      <tr>
                        <td>{{ $t("series_description") }}</td>
                        <td>
                          {{ selected[0].series_description }}
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("rows") }} x {{ $t("cols") }}</td>
                        <td>{{ selected[0].rows }} x {{ selected[0].cols }}</td>
                      </tr>
                      <tr v-if="selected[0].spacing_x != 'NA '">
                        <td>{{ $t("image_spacing") }}</td>
                        <td>
                          {{ selected[0].spacing_x }} mm -
                          {{ selected[0].spacing_y }} mm
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("n_of_images") }}</td>
                        <td>{{ selected[0].number_of_slices }}</td>
                      </tr>
                      <tr v-if="selected[0].slice_thickness != 'NA '">
                        <td>{{ $t("slice_thickness") }}</td>
                        <td>{{ selected[0].slice_thickness }} mm</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="center-text">
                  <template v-if="selected[0]">
                    <v-btn-toggle
                      v-model="selected[0].phase"
                      mandatory
                      dense
                      borderless
                      active-class="primary"
                      class="mt20"
                    >
                      <v-btn @click="checkPhaseValidator">
                        {{ $t("not_chosen") }}
                      </v-btn>
                      <v-btn @click="resetArterial">
                        {{ $t("arterial") }}
                      </v-btn>
                      <v-btn @click="resetVenous">
                        {{ $t("venous") }}
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="mt20">
              <v-card-text class="right-text">
                <v-container>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <v-text-field
                        :label="$t('case_name')"
                        v-model="case_name"
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-alert
                      v-if="warning"
                      normal
                      type="warning"
                      class="text-left"
                    >
                      {{ $t("phase_selection_warning") }}
                    </v-alert>
                    <v-col cols="12" lg="12">
                      <v-btn-toggle>
                        <v-btn
                          class="ma-2"
                          color="primary"
                          @click="go_back"
                          :disabled="saving"
                        >
                          <font-awesome-icon icon="undo" pull="left" />
                          {{ $t("go_back") }}
                        </v-btn>
                        <v-btn
                          class="ma-2"
                          color="primary"
                          @click="save"
                          :disabled="saving || invalid"
                        >
                          <font-awesome-icon icon="save" pull="left" />
                          {{ $t("save") }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay class="center-text" :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p class="log">{{ msgLog }}</p>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { each, every, filter } from "lodash";
import Canvas2D from "@/components/Canvas2D";
import {
  addDefaultTools,
  loadAndRenderSerie,
  resetImageAPI,
  clearCornerstoneElements,
  clearImageCache,
  disableImage
} from "@/common/api.images";
import { setToolActive } from "@/larvitar/index";
import { getCase, saveCase, openCaseFromDashboard } from "@/common/api.cases";

export default {
  data() {
    return {
      tools: false,
      series: [],
      selected: [],
      headers: [
        { text: "Series Description", value: "series_description" },
        { text: "Acquisition Date", value: "acquisition_date" },
        { text: "N. Slices", value: "number_of_slices" },
        { text: "Slice Thickness", value: "slice_thickness" },
        { text: "Chosen Phase", value: "phase", sortable: false }
      ],
      toggle_exclusive: 0,
      saving: false,
      invalid: true,
      case_name: ""
    };
  },
  mounted: function() {
    this.$store.dispatch("setNewCase", false);
    this.loadMetadata();
  },
  components: { Canvas2D },
  computed: {
    ...mapGetters(["isQuadviewReady"]),
    ...mapState({
      seriesId(state) {
        return [state.seriesId];
      },
      imageSeries(state) {
        return state.series;
      },
      msgLog(state) {
        return state.msgLog;
      }
    }),
    isLoading: function() {
      if (this.isQuadviewReady === true) {
        return false;
      } else {
        return true;
      }
    },
    warning: function() {
      let selected_series = filter(this.series, function(o) {
        return o.phase != 0;
      });
      return selected_series.length == 1 ? true : false;
    }
  },
  methods: {
    loadMetadata() {
      let self = this;
      each(this.$store.state.series, function(item) {
        let serie = {};
        let acquisitionNumber = item.acquisitionNumber
          ? "-" + item.acquisitionNumber
          : "";
        serie["series_description"] =
          item.seriesDescription + acquisitionNumber || "";
        serie["acquisition_date"] =
          item.instances[item.imageIds[0]].metadata.seriesDate || "";
        serie["number_of_slices"] = item.imageIds.length;
        serie["id"] = item.seriesUID;
        serie["phase"] = 0;

        if (item.header) {
          serie["slice_thickness"] = item.header.volume.sliceThickness.toFixed(
            3
          );
          serie["rows"] = item.header.volume.rows;
          serie["cols"] = item.header.volume.cols;
          serie["spacing_x"] = item.header.volume.pixelSpacing[0].toFixed(3);
          serie["spacing_y"] = item.header.volume.pixelSpacing[1].toFixed(3);
        } else {
          serie["slice_thickness"] = item.instances[item.imageIds[0]].metadata
            .sliceThickness
            ? item.instances[item.imageIds[0]].metadata.sliceThickness.toFixed(
                3
              )
            : "NA ";
          serie["rows"] = item.instances[item.imageIds[0]].metadata.rows;
          serie["cols"] = item.instances[item.imageIds[0]].metadata.cols;
          serie["spacing_x"] = item.instances[item.imageIds[0]].metadata
            .pixelSpacing
            ? item.instances[item.imageIds[0]].metadata.pixelSpacing[0].toFixed(
                3
              )
            : "NA ";
          serie["spacing_y"] = item.instances[item.imageIds[0]].metadata
            .pixelSpacing
            ? item.instances[item.imageIds[0]].metadata.pixelSpacing[1].toFixed(
                3
              )
            : "NA ";
        }

        self.series.push(serie);
        if (item.seriesUID == self.seriesId) {
          self.selected = [serie];
        }
      });
    },
    openSeries(item) {
      if (item.id != this.$store.state.seriesId && item.number_of_slices > 1) {
        if (Object.keys(this.$store.state.series).length > 1) {
          clearImageCache();
          disableImage("axial");
          this.tools = false;
          this.selected = [item];
          this.$store.dispatch("setSeriesId", item.id);
          loadAndRenderSerie(this.$store.state.series[item.id], "axial");
        }
      }
    },
    checkPhaseValidator() {
      let self = this;
      setTimeout(function() {
        self.invalid = every(self.series, ["phase", 0]);
      }, 200);
    },
    resetArterial() {
      let self = this;
      each(this.series, function(item) {
        if (item.id != self.seriesId) {
          item.phase = item.phase == 1 ? 0 : item.phase;
        }
        self.invalid = false;
      });
      setTimeout(function() {
        if (self.selected[0].number_of_slices < 2) {
          self.selected[0].phase = 0;
          self.checkPhaseValidator();
        }
      }, 0);
    },
    resetVenous() {
      let self = this;
      each(this.series, function(item) {
        if (item.id != self.seriesId) {
          item.phase = item.phase == 2 ? 0 : item.phase;
        }
        self.invalid = false;
      });
      setTimeout(function() {
        if (self.selected[0].number_of_slices < 2) {
          self.selected[0].phase = 0;
          self.checkPhaseValidator();
        }
      }, 0);
    },
    go_back() {
      clearCornerstoneElements();
      resetImageAPI();
      this.$store.dispatch("setPreview", false);
      this.$store.dispatch("setSeriesId", null);
      this.$store.dispatch("setSeries", []);
      this.case_name = "";
    },
    save() {
      this.saving = true;
      this.$store.dispatch("quadview/setLoadingStatus", ["axial", false]);
      let selected_series = filter(this.series, function(o) {
        return o.phase != 0;
      });
      let self = this;
      saveCase(this.case_name, selected_series, function(caseId) {
        clearCornerstoneElements();
        resetImageAPI();
        self.case_name = "";
        self.saving = false;
        self.$store.dispatch("quadview/setLoadingStatus", ["axial", true]);
        self.$store.dispatch("setPreview", false);
        self.$store.dispatch("setSeriesId", null);
        self.$store.dispatch("setSeries", []);
        self.$store.dispatch("setMsgLog", "");
        self.$store.dispatch("setNewCase", true);

        // open new case
        getCase(caseId, function(resp) {
          self.$store.dispatch("quadview/setLoadingStatus", ["axial", false]);
          openCaseFromDashboard(resp.body);
        });
      });
    }
  },
  watch: {
    isQuadviewReady: function(ready) {
      if (ready && !this.tools) {
        addDefaultTools("axial");
        setToolActive("Wwwc");
        this.tools = true;
      }
    },
    imageSeries: function() {
      this.loadMetadata();
    }
  }
};
</script>

<style scoped>
.h95 {
  height: 95%;
}
.h70 {
  height: 70%;
}
.h30 {
  height: 30%;
}
.h5 {
  height: 5%;
}
.title {
  color: white;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
.mt20 {
  margin-top: 20px;
}
.log {
  text-align: center;
  margin-top: 1em;
  font-size: 1.5vmax;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white;
}
.vertical-scroll {
  overflow-y: scroll !important;
}
.h250 {
  max-height: 250px;
}
.text-left {
  text-align: left;
}
</style>
