<template>
  <v-dialog
    v-model="active"
    width="47%"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card flat>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-alert normal type="error">
          {{ $t("registration_error") }}
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("registration_error_accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      active: false
    };
  },
  computed: {
    ...mapGetters(["isQuadviewReady"]),
    ...mapState({
      recStatus(state) {
        return state.registrationStatus;
      }
    })
  },
  watch: {
    isQuadviewReady: function(ready) {
      if (ready && this.$store.state.registrationStatus == 3) {
        this.active = true;
      }
    },
    recStatus: function(newV, oldV) {
      if (newV == 3 && oldV == 1) {
        this.active = true;
      }
    }
  },
  methods: {
    closeDialog: function() {
      this.$store.dispatch("setRegistrationStatus", 4);
      this.active = false;
    }
  }
};
</script>

<style scoped></style>
