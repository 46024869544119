<template>
  <v-card flat :class="getHeight" class="d-flex flex-column overflow-auto">
    <div class="flex-grow-1">
      <template v-if="this.$store.state.quadview.activeViewport == 'axial'">
        <Segmentations />
      </template>
      <template v-else>
        <r3DPanel />
      </template>
    </div>
    <template v-if="!isEditorActive">
      <div class="mt-3 d-flex justify-space-between" style="gap: 10px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="red"
              v-on="{ ...tooltip }"
              @click="exit"
              :disabled="isEditorActive"
              class="flex-grow-1 basis-50 text-btn"
            >
              <font-awesome-icon
                size="2x"
                icon="sign-out-alt"
                pull="left"
              ></font-awesome-icon>
              {{ $t("exit") }}
            </v-btn>
          </template>
          <span>{{ $t("exit_long") }}</span>
        </v-tooltip>

        <template v-if="activeViewport != 'r3D'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                color="green"
                v-on="{ ...tooltip }"
                @click="open3D"
                :disabled="isSegmentationsRunning || isEditorActive"
                class="basis-50 text-btn"
              >
                <font-awesome-icon
                  size="2x"
                  icon="cubes"
                  pull="left"
                ></font-awesome-icon>
                {{ $t("select_3D") }}
              </v-btn>
            </template>
            <span>{{ $t("select_3D_long") }}</span>
          </v-tooltip>
        </template>
      </div>
    </template>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import {
  clearCornerstoneElements,
  resetImageAPI,
  disableImage
} from "@/common/api.images";
import { clearSegmentationState } from "@/common/api.segmentation";
import { buildAndLoadScene } from "@/common/api.r3d";

import { clearImageCache } from "@/larvitar/index";

import Segmentations from "@/components/panels/Segmentations";
import r3DPanel from "@/components/panels/r3DPanel";

export default {
  name: "SidePanel",
  data() {
    return {};
  },
  components: { Segmentations, r3DPanel },
  computed: {
    ...mapGetters(["isEditorActive", "isSegmentationsRunning"]),
    ...mapState({
      activeViewport(state) {
        return state.quadview.activeViewport;
      }
    }),
    getHeight: function() {
      return this.isEditorActive ? "h90" : "h100";
    }
  },
  methods: {
    exit: function() {
      // clear intervals
      this.$store.dispatch("clearIntervalIds");
      // go to home
      this.$router.push({ name: "home" });
      // clean cornerstone library
      clearCornerstoneElements();
      clearSegmentationState();
      resetImageAPI();
      // reset the store
      this.$store.dispatch("clearState");
    },
    open3D: function() {
      this.$store.dispatch("quadview/setLoadingStatus", ["r3D", false]);
      this.$store.dispatch("setSeriesId", null);
      this.$store.dispatch("setActivePhase", "");
      clearImageCache();
      disableImage("axial");
      this.$store.dispatch("quadview/setActiveViewport", "r3D");
      setTimeout(function() {
        buildAndLoadScene("r3D");
      }, 500);
    }
  }
};
</script>

<style scoped>
.bottom-menu {
  position: absolute;
  bottom: 0;
}
.w45 {
  width: 45%;
}
.mr5 {
  margin-right: 5%;
}
.text-btn {
  font-size: 0.8vw !important;
}
</style>
