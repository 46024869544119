function initialState() {
  return {
    axial: {
      ready: false,
      minSliceId: 0,
      maxSliceId: 0,
      sliceId: 0,
      rows: 0,
      cols: 0,
      thickness: 0.0,
      spacing_x: 0.0,
      spacing_y: 0.0,
      minPixelValue: 0,
      maxPixelValue: 0,
      viewport: {
        scale: 0.0,
        translation: {
          x: 0.0,
          y: 0.0
        },
        rotation: 0.0,
        voi: {
          windowCenter: 0.0,
          windowWidth: 0.0
        }
      },
      default: {
        scale: 0.0,
        translation: {
          x: 0.0,
          y: 0.0
        },
        rotation: 0.0,
        voi: {
          windowCenter: 0.0,
          windowWidth: 0.0
        }
      }
    },
    sagittal: {
      ready: false,
      minSliceId: 0,
      maxSliceId: 0,
      sliceId: 0,
      rows: 0,
      cols: 0,
      thickness: 0.0,
      spacing_x: 0.0,
      spacing_y: 0.0,
      minPixelValue: 0,
      maxPixelValue: 0,
      viewport: {
        scale: 1.0,
        translation: {
          x: 0.0,
          y: 0.0
        },
        rotation: 0.0,
        voi: {
          windowCenter: 0.0,
          windowWidth: 0.0
        }
      },
      default: {
        scale: 1.0,
        translation: {
          x: 0.0,
          y: 0.0
        },
        rotation: 0.0,
        voi: {
          windowCenter: 0.0,
          windowWidth: 0.0
        }
      }
    },
    coronal: {
      ready: false,
      minSliceId: 0,
      maxSliceId: 0,
      sliceId: 0,
      rows: 0,
      cols: 0,
      thickness: 0.0,
      spacing_x: 0.0,
      spacing_y: 0.0,
      minPixelValue: 0,
      maxPixelValue: 0,
      viewport: {
        scale: 1.0,
        translation: {
          x: 0.0,
          y: 0.0
        },
        rotation: 0.0,
        voi: {
          windowCenter: 0.0,
          windowWidth: 0.0
        }
      },
      default: {
        scale: 1.0,
        translation: {
          x: 0.0,
          y: 0.0
        },
        rotation: 0.0,
        voi: {
          windowCenter: 0.0,
          windowWidth: 0.0
        }
      }
    },
    r3D: {
      ready: false
    },
    activeViewport: "axial"
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setLoadingStatus(state, [orientation, status]) {
    state[orientation].ready = status;
  },
  setMinSliceNumber(state, [orientation, value]) {
    state[orientation].minSliceId = value;
  },
  setMaxSliceNumber(state, [orientation, value]) {
    state[orientation].maxSliceId = value;
  },
  setCurrentSliceNumber(state, [orientation, value]) {
    state[orientation].sliceId = value;
  },
  setDefaultViewport(state, [orientation, scale, x, y, ww, wc]) {
    state[orientation].default.scale = scale;
    state[orientation].default.translation.x = x;
    state[orientation].default.translation.y = y;
    state[orientation].default.voi.windowWidth = ww;
    state[orientation].default.voi.windowCenter = wc;
  },
  setTranslation(state, [orientation, x, y]) {
    state[orientation].viewport.translation.x = x;
    state[orientation].viewport.translation.y = y;
  },
  setScale(state, [orientation, scale]) {
    state[orientation].viewport.scale = scale;
  },
  setRotation(state, [orientation, rotation]) {
    state[orientation].viewport.rotation = rotation;
  },
  setContrast(state, [orientation, ww, wc]) {
    state[orientation].viewport.voi.windowWidth = ww;
    state[orientation].viewport.voi.windowCenter = wc;
  },
  setDimensions(state, [orientation, rows, cols]) {
    state[orientation].rows = rows;
    state[orientation].cols = cols;
  },
  setSpacing(state, [orientation, spacing_x, spacing_y]) {
    state[orientation].spacing_x = spacing_x;
    state[orientation].spacing_y = spacing_y;
  },
  setThickness(state, [orientation, thickness]) {
    state[orientation].thickness = thickness;
  },
  setMinPixelValue(state, [orientation, value]) {
    state[orientation].minPixelValue = value;
  },
  setMaxPixelValue(state, [orientation, value]) {
    state[orientation].maxPixelValue = value;
  },
  setActiveViewport(state, value) {
    state.activeViewport = value;
  }
};

const actions = {
  setLoadingStatus({ commit }, [orientation, status]) {
    commit("setLoadingStatus", [orientation, status]);
  },
  setMinSliceNumber({ commit }, [orientation, value]) {
    commit("setMinSliceNumber", [orientation, value]);
  },
  setMaxSliceNumber({ commit }, [orientation, value]) {
    commit("setMaxSliceNumber", [orientation, value]);
  },
  setCurrentSliceNumber({ commit }, [orientation, value]) {
    commit("setCurrentSliceNumber", [orientation, value]);
  },
  setDefaultViewport({ commit }, [orientation, scale, x, y, ww, wc]) {
    commit("setDefaultViewport", [orientation, scale, x, y, ww, wc]);
  },
  setTranslation({ commit }, [orientation, x, y]) {
    commit("setTranslation", [orientation, x, y]);
  },
  setScale({ commit }, [orientation, scale]) {
    commit("setScale", [orientation, scale]);
  },
  setRotation({ commit }, [orientation, rotation]) {
    commit("setRotation", [orientation, rotation]);
  },
  setContrast({ commit }, [orientation, ww, wc]) {
    commit("setContrast", [orientation, ww, wc]);
  },
  setDimensions({ commit }, [orientation, rows, cols]) {
    commit("setDimensions", [orientation, rows, cols]);
  },
  setSpacing({ commit }, [orientation, spacing_x, spacing_y]) {
    commit("setSpacing", [orientation, spacing_x, spacing_y]);
  },
  setThickness({ commit }, [orientation, thickness]) {
    commit("setThickness", [orientation, thickness]);
  },
  setMinPixelValue({ commit }, [orientation, minPixelValue]) {
    commit("setMinPixelValue", [orientation, minPixelValue]);
  },
  setMaxPixelValue({ commit }, [orientation, maxPixelValue]) {
    commit("setMaxPixelValue", [orientation, maxPixelValue]);
  },
  setActiveViewport({ commit }, value) {
    commit("setActiveViewport", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
