// external libs
import request from "superagent";
import uuid from "uuid";
import { forEach, groupBy, map, each, find, has } from "lodash";

// internal libs
import store from "@/store/index";
import router from "@/router/index";
import { verify_and_refresh } from "@/common/api.users";
import {
  saveImageInDB,
  importImage,
  renderNrrdImage,
  registerImages
} from "@/common/api.images";
import {
  importSegmentations,
  getSegmentationsInfo,
  getMaskSurfacePath
} from "@/common/api.jobs";

// =====================
// Get the case data ===
// =====================
export function getCase(caseId, callback) {
  verify_and_refresh(function() {
    request
      .get("/api/case/" + caseId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp) {
        callback(resp);
      });
  });
}

// ===================================
// Get the text note from its case ===
// ===================================
export function getCaseNotes(callback) {
  verify_and_refresh(function() {
    request
      .get("/api/note/" + store.state.noteId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp) {
        callback(resp);
      });
  });
}

// ====================================
// Update the text note of its case ===
// ====================================
export function saveCaseNotes(callback) {
  verify_and_refresh(function() {
    let data = {
      text: store.state.notes
    };
    request
      .put("/api/note/" + store.state.noteId + "/")
      .send(data)
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp) {
        callback(resp);
      });
  });
}

// ===========================================
// Add a new case into db with its images ====
// ===========================================
export function saveCase(case_name, series, callback) {
  // store arterial and/or venous phase
  if (case_name == "") {
    case_name = uuid.v4().split("-")[0];
  }

  createCase(case_name, function(resp) {
    if (resp.status == 200) {
      let caseId = resp.body.id;

      // save the first serie
      let seriesId = series[0].id;
      let phase = series[0].phase == 1 ? "arterial" : "venous";
      saveImageInDB(caseId, phase, seriesId, store.state.series, function(
        resp
      ) {
        if (series.length > 1) {
          seriesId = series[1].id;
          phase = series[1].phase == 1 ? "arterial" : "venous";
          saveImageInDB(caseId, phase, seriesId, store.state.series, function(
            response
          ) {
            let fixedId =
              resp.body.header.imageIds.length <=
              response.body.header.imageIds.length
                ? resp.body.id
                : response.body.id;
            let movingId =
              resp.body.header.imageIds.length <=
              response.body.header.imageIds.length
                ? response.body.id
                : resp.body.id;
            registerImages(caseId, fixedId, movingId, function() {
              callback(caseId);
            });
          });
        } else {
          callback(caseId);
        }
      });
    }
  });
}

// ===========================
// Add a new case into db ====
// ===========================
export function createCase(case_name, callback) {
  console.time("...createCase");
  let data = {
    tag: case_name
  };
  verify_and_refresh(function() {
    request
      .post("/api/cases/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function(resp) {
        callback(resp);
      })
      .catch(error => {
        callback(error.response);
      });
  });
}

// =========================================================
// Get the list of image objects for current case ===
// =========================================================
export function getImagesInfo(caseId, callback) {
  verify_and_refresh(() => {
    request
      .get("/api/phase_images/" + caseId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(resp => {
        callback(resp);
      });
  });
}

// ==============================
// Open a case from dashboard ===
// ==============================
export function openCaseFromDashboard(item) {
  // routing to page
  router.push({ name: "viewer" });

  store.dispatch("setCaseId", item.id);
  store.dispatch("setNoteId", item.note);
  store.dispatch("setCaseName", item.tag);

  // ASYNC FUNCTION
  let segmentationIds = { venous: [], arterial: [] };
  getSegmentationsInfo(item.id, function(resp) {
    // dispatch jobId in store for each mask
    forEach(resp.body, function(seg) {
      let tag = seg.fields.label_tag;
      let phase = seg.fields.phase;
      let jobId = seg.fields.jobId;
      let color = seg.fields.color;
      if (process.env.NODE_ENV == "development") {
        console.log(tag, jobId, seg.pk);
      }
      // add mask in store if not AI mask
      if (!has(store.state.segmentations, tag)) {
        store.dispatch("segmentations/addMask", [phase, tag]);
        let labelId =
          store.state.segmentations.tags.venous.length +
          store.state.segmentations.tags.arterial.length -
          1;
        store.dispatch("segmentations/setMaskLabelId", [tag, labelId]);
        if (color) {
          store.dispatch("segmentations/setMaskColor", [tag, color]);
          store.dispatch("segmentations/setMaskColorUI", [tag, color]);
        }
      }
      store.dispatch("segmentations/setMaskJobId", [tag, jobId]);
      store.dispatch("segmentations/setMaskResourceId", [tag, seg.pk]);
    });

    let segmentations = groupBy(resp.body, function(obj) {
      return obj.fields.phase;
    });

    forEach(segmentations, function(seg, phase) {
      segmentationIds[phase] = map(seg, "pk");
    });
    store.dispatch("setSegmentationIds", segmentationIds);
  });

  let defaultProps = {
    ww: 600.0,
    wc: 40.0,
    defaultWW: 600.0,
    defaultWC: 40.0
  };

  store.dispatch("setRegistrationStatus", item.registration_status);

  if (item.images.length == 1) {
    // case a: only one image or registration error
    let log = "Downloading Image 1 of " + item.images.length + " from server: ";
    importImage(item.images[0], log, function(seriesId, phase, imageId) {
      if (phase == "arterial") {
        store.dispatch("setArterialId", seriesId);
        store.dispatch("setArterialImageId", imageId);
      } else {
        store.dispatch("setVenousId", seriesId);
        store.dispatch("setVenousImageId", imageId);
      }
      store.dispatch("setSeriesId", seriesId);
      let activePhase =
        seriesId == store.state.arterialId ? "arterial" : "venous";
      store.dispatch("setActivePhase", activePhase);
      store.dispatch("setMsgLog", "Rendering image...");
      renderNrrdImage("axial", defaultProps, () => {
        let phaseSegmentationIds = segmentationIds[activePhase].slice();
        importSegmentations(phaseSegmentationIds, () => {
          store.dispatch("setMsgLog", "");
        });
      });
    });
  } else {
    // check registration status code
    if (item.registration_status == 2) {
      // image has been registered
      getImagesInfo(item.id, function(resp) {
        let images = groupBy(resp.body, function(obj) {
          return obj.fields.phase;
        });
        let fixed_imageId, registered_imageId;
        each(images, function(phase_images) {
          if (phase_images.length == 1) {
            fixed_imageId = phase_images[0].pk;
          } else {
            registered_imageId = phase_images[0].fields.registered
              ? phase_images[0].pk
              : phase_images[1].pk;
          }
        });
        let log = "Downloading Image 1 of 2 from server: ";
        importImage(fixed_imageId, log, function(seriesId, phase, imageId) {
          if (phase == "arterial") {
            store.dispatch("setArterialId", seriesId);
            store.dispatch("setArterialImageId", imageId);
          } else {
            store.dispatch("setVenousId", seriesId);
            store.dispatch("setVenousImageId", imageId);
          }
          let log = "Downloading Image 2 of 2 from server: ";
          importImage(registered_imageId, log, function(
            rec_seriesId,
            rec_phase,
            rec_imageId
          ) {
            if (rec_phase == "arterial") {
              store.dispatch("setArterialId", rec_seriesId);
              store.dispatch("setArterialImageId", rec_imageId);
            } else {
              store.dispatch("setVenousId", rec_seriesId);
              store.dispatch("setVenousImageId", rec_imageId);
            }
            store.dispatch("setSeriesId", seriesId);
            let activePhase =
              seriesId == store.state.arterialId ? "arterial" : "venous";
            let nonActivePhase =
              seriesId == store.state.arterialId ? "venous" : "arterial";
            store.dispatch("setActivePhase", activePhase);
            store.dispatch("setMsgLog", "Rendering image...");
            renderNrrdImage("axial", defaultProps, () => {
              let inactiveSegmentationIds = segmentationIds[
                nonActivePhase
              ].slice();
              each(inactiveSegmentationIds, function(resourceId) {
                getMaskSurfacePath(resourceId);
              });
              let phaseSegmentationIds = segmentationIds[activePhase].slice();
              importSegmentations(phaseSegmentationIds, () => {
                store.dispatch("setMsgLog", "");
              });
            });
          });
        });
      });
    } else if (item.registration_status == 1) {
      // registration is  pending
      // render only the first one and start polling
      let fixed_imageId = item.image_rec_id;

      let log = "Downloading Image 1 of 1 from server: ";
      importImage(fixed_imageId, log, function(seriesId, phase, imageId) {
        if (phase == "arterial") {
          store.dispatch("setArterialId", seriesId);
          store.dispatch("setArterialImageId", imageId);
        } else {
          store.dispatch("setVenousId", seriesId);
          store.dispatch("setVenousImageId", imageId);
        }
        store.dispatch("setSeriesId", seriesId);
        let activePhase =
          seriesId == store.state.arterialId ? "arterial" : "venous";
        store.dispatch("setActivePhase", activePhase);
        store.dispatch("setMsgLog", "Rendering image...");
        renderNrrdImage("axial", defaultProps, () => {
          let phaseSegmentationIds = segmentationIds[activePhase].slice();
          importSegmentations(phaseSegmentationIds, () => {
            store.dispatch("setMsgLog", "");
            getCaseRegistrationStatus(item.id);
          });
        });
      });
    } else {
      // image has not been registered, error code
      // import standard image and do not allow segmentations here
      let log = "Downloading Image 1 of 1 from server: ";
      let fixed_imageId = item.image_rec_id;
      importImage(fixed_imageId, log, function(seriesId, phase, imageId) {
        if (phase == "arterial") {
          store.dispatch("setArterialId", seriesId);
          store.dispatch("setArterialImageId", imageId);
        } else {
          store.dispatch("setVenousId", seriesId);
          store.dispatch("setVenousImageId", imageId);
        }
        store.dispatch("setSeriesId", seriesId);
        let activePhase =
          seriesId == store.state.arterialId ? "arterial" : "venous";
        store.dispatch("setActivePhase", activePhase);
        store.dispatch("setMsgLog", "Rendering image...");
        renderNrrdImage("axial", defaultProps, () => {
          let phaseSegmentationIds = segmentationIds[activePhase].slice();
          importSegmentations(phaseSegmentationIds, () => {
            store.dispatch("setMsgLog", "");
          });
        });
      });
    }
  }
}

// ==============================
// Open a case from dashboard ===
// ==============================
export function getCaseRegistrationStatus(caseId) {
  let casePollingInterval = setInterval(function() {
    verify_and_refresh(function() {
      request
        .get("/api/case/" + caseId + "/")
        .set("Authorization", "Bearer " + store.state.accessToken)
        .then(function(resp) {
          let status = resp.body.registration_status;
          store.dispatch("setRegistrationStatus", status);
          if (process.env.NODE_ENV == "development") {
            console.log("Registration status: ", status);
          }
          if (status == 2) {
            clearInterval(casePollingInterval);
            store.dispatch("setRegistrationPollingId", null);
            // get images info
            getImagesInfo(caseId, function(resp) {
              let registered_image = find(resp.body, function(obj) {
                // imageId is the one with registered == true
                return obj.fields.registered == true;
              });
              if (registered_image) {
                let imageId = registered_image.pk;
                // import nrrd image
                importImage(imageId, null, function(seriesId, phase, imageId) {
                  if (phase == "arterial") {
                    store.dispatch("setArterialId", seriesId);
                    store.dispatch("setArterialImageId", imageId);
                  } else {
                    store.dispatch("setVenousId", seriesId);
                    store.dispatch("setVenousImageId", imageId);
                  }
                });
              }
            });
          }
          if (status == 3) {
            if (process.env.NODE_ENV == "development") {
              console.log("Registration Error");
            }
            clearInterval(casePollingInterval);
            store.dispatch("setRegistrationPollingId", null);
          }
        });
    });
  }, 10000); // 20 seconds
  store.dispatch("setRegistrationPollingId", casePollingInterval);
  store.dispatch("pushIntervalId", casePollingInterval);
}
